<template>
  <div>
    <div v-if="user_group.indexOf('产品组') == -1">
      <cw403></cw403>
    </div>
    <!-- 判断是否有页面限制，如果有，则必须包含该页 -->
    <div v-else-if="page_list.length > 0 && page_list.indexOf('漏斗分析') == -1">
      <cw403></cw403>
    </div>
    <div v-else>
      <div class="crumbs">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item style="font-size: 16px">
            漏斗分析
          </el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="container">
        <div class="options-bar">
          <span style="color:gray;margin-right:30px;">筛选维度</span>
          <date-picker
            style="margin-right: 10px"
            v-model="date"
          >
          </date-picker>
          <ElSelectProjectSingle style="margin-right: 10px; width: 180px" ref="childProject" @ProjectChange='ProjectChange($event)'></ElSelectProjectSingle>
            <el-select v-model="Platform" @change="PlatformChange($event)" filterable placeholder="平台" style="margin-right: 10px;width: 180px">
                <el-option
                v-for="item in PlatformList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
                </el-option>
            </el-select>
          <ElSelectCountry style="margin-right: 10px; width: 180px" ref="childCountry" @CountryChange='CountryChange($event)'></ElSelectCountry>

          <el-button
            type="primary"
            plain
            icon="el-icon-search"
            @click="handleSearch"
            style="margin-bottom: 10px; margin-top: 10px; margin-right: 10px"
            :disabled="kaiguan"
            >搜索</el-button
          >
          <el-button v-if="fanhui" plain type="primary" icon="el-icon-search" @click="fanhuiss" style="margin-left: 0px">返回</el-button>
        </div>
        <div class="options-bar" style="margin-left: 95px">
          <ElSelectMedia style="margin-right: 10px; width: 240px" ref="childMedia" @MediachangeValue='MediaChange($event)'></ElSelectMedia>
            <el-select v-model="appVersion" multiple @change="appVersionChange($event)" filterable placeholder="版本" style="margin-right: 10px;width: 180px">
                <el-option
                v-for="item in appVersionList"
                :key="item"
                :label="item"
                :value="item">
                </el-option>
            </el-select>
        </div>
        <div class="options-bar">
          <span style="16px;color:gray;margin-right:30px;float:left;">快速添加</span>
            <el-input
            v-model="actQuery"
            placeholder="快速添加步骤"
            clearable
            style="width:240px;margin-right:10px;"
            ></el-input>
          <el-button
            type="primary"
            plain
            icon="el-icon-plus"
            @click="actQueryAppend()"
            style="margin-bottom: 10px; margin-top: 10px; margin-right: 10px"
            :disabled="kaiguan"
            >添加事件</el-button>
            <el-button
            type="primary"
            plain
            icon="el-icon-delete"
            @click="actQueryClear()"
            style="margin-bottom: 10px; margin-top: 10px; margin-right: 10px"
            :disabled="kaiguan"
            >清空事件</el-button>
            <span style="color:#606266;">添加形式如: act_login_first,act_floor_1_enter,act_floor_2_enter</span>
        </div>
        <div class="options-bar" style="display:block">
            <span style="color:gray;margin-right:30px;">漏斗步骤</span>
            <el-tag
            :key="tag+index"
            v-for="(tag,index) in eventTags"
            style="width:auto;background:#fff"
            closable
            :disable-transitions="false"
            @close="handleClose(tag,index)">
            {{tag}}
            </el-tag>

            <el-input
            class="input-new-tag"
            v-if="inputVisible"
            v-model="inputValue"
            ref="saveTagInput"
            size="small"
            @keyup.enter.native="handleInputConfirm"
            @blur="handleInputConfirm"
            >
            </el-input>

            <el-select style="margin-left: 10px;" v-if="selectEventVisible" v-model="selectEvent" @change="selectEventChange($event)" filterable placeholder="事件">
              <el-option
                v-for="item in selectEventList"
                :key="item"
                :label="item"
                :value="item">
              </el-option>
            </el-select>

            <el-button v-else class="button-new-tag" size="small" @click="showSelectEvent">添加步骤指标</el-button>
        </div>
        <div class="options-bar">
          <span style="16px;color:gray;margin-right:30px;float:left;">快速检索</span>
          <ElSearchInput ref="searchInput" @SearchInput='SearchInput($event)'></ElSearchInput>
          <el-button
            @click="download"
            style="margin-left: auto"
            icon="el-icon-s-release"
            type="info"
            plain
          >导出csv</el-button>
        </div>
        <el-table
          v-loading="pictLoading"
          element-loading-text="数据正在加载中"
          :data="tableSearchList.slice((pageInfo.current-1)*pageInfo.size,pageInfo.current*pageInfo.size)"
          height="550"
          border
          class="table"
          ref="multipleTable"
          style="white-space: pre-line"
          header-cell-class-name="table-header"
          :header-cell-style="{ color: '#696969' }"
        >

          <ex-table-column
            :min-width="120"
            :autoFit="true"
            :fitHeader="true"
            sortable
            v-for="item in tableColumns1"
            :key="item"
            :prop="item"
            fixed
            :label="item"
          />

          <ex-table-column
            :autoFit="true"
            :fitHeader="true"
            sortable
            v-for="item in tableColumns2"
            :key="item"
            :prop="item"
            :label="item"
          />
        </el-table>
        <pagination
          @size-change="handleSizeChange"
          :current-page.sync="pageInfo.current"
          :page-sizes="pageSizes" 
          :page-size="pageInfo.size" layout="total, sizes, prev, pager, next, jumper" 
          :total="pageInfo.total"
        ></pagination>
      </div>

    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import echarts from 'echarts';
import { fetchData } from '../../api/index';
import json2csv from 'json2csv';
import cw403 from './403.vue';
import ElSelectPlatform from '../SelectComponent/select_platform.vue';
import ElSelectCountry from '../SelectComponent/select_country.vue';
// import ElSelectProject from '../SelectComponent/select_project.vue';
import ElSelectProjectSingle from '../SelectComponent/select_project_single.vue';
import ElSearchInput from '../SelectComponent/searchInput.vue';
import ElSelectMedia from '../SelectComponent/select_media.vue';
export default {
  name: 'product-table-FunnelAnalysis',
  components: {
    cw403,
    ElSelectPlatform,
    ElSelectProjectSingle,
    ElSearchInput,
    ElSelectCountry,
    ElSelectMedia
  },
  data() {
    return {
        dynamicTags: ['标签一', '标签二', '标签三'],
        inputVisible: false,
        selectEventVisible: false,
        inputValue: '',
      selectEvent:'',
      eventTags:[],
      actQuery:'',
      selectEventList:[],  
      kaiguan: false,
      DateType:'event_time',
      DateTypeList: [
        { label: '事件时间', value: 'event_time' },
        { label: '安装时间', value: 'install_time' }
      ],
      UserIdQuery:'',
      PlatformList : [{
          value: 'android',
          label: 'Android'
        }, {
          value: 'ios',
          label: 'iOS'
        }],
      Platform:'',
      appVersionList : [],
      appVersion:[],
      user_group: localStorage.getItem('user-group'),
      page_list: localStorage.getItem('page_list'),
      checkList: ['日期'],
      tableColumns: [],
      tableColumns1: [],
      tableColumns2: [],
      //折线图参数
      time: [],
      tableSearchList: [],
      fanhui: false,
      pictLoading: false,
      userLoading: false,
      sjLoading: false,
      pageInfo: {
        size: 50,
        current: 1,
        total: 0,
      },
      // 默认显示第几页
      currentPage:1,
      // 总条数，根据接口获取数据长度(注意：这里不能为空)
      totalCount:1,
      // 个数选择器（可修改）
      pageSizes:[50,100,200,300,400],
      // 默认每页显示的条数（可修改）
      PageSize:50,
      date: [],
      type: false,
      tableData: [],
      lastquery: '',
      multipleSelection: [],
      delList: [],
      editVisible: false,
      pageTotal: 0,
      form: {},
      idx: -1,
      id: -1,
    };
  },
  created() {
    // 初始请求，请求项目
    this.getProject();
    this.PageCount();
    // this.getAdsource();
    // this.getCountry();
  },
  methods: {
    ProjectChange(e){
    //   this.getMedia();
      this.getCountry();
      this.getEvent();
      this.getAppVersion();
    },
    PlatformChange(e){
      // 改变值，请求国家选择框的值
    //   this.getCountry();
    //   this.getMedia();
      this.getMedia();
      this.getCountry();
    },
    MediaChange(e){
      // 改变值，请求国家选择框的值
    //   this.getCampaign();
    },
    appVersionChange(e){
    //   this.getCampaign();
    },
    CountryChange(e){
      // 当改变值的时候，请求下个选项框的值，如果此选择框没有联动的下个选项框就为空就可以
      // console.log('接受');
      // console.log(e);
      this.getMedia();
    },
    MediaChange(e){
      // console.log('接受');
      // console.log(e);
    },
    CampaignChange(e){
      // 当改变值的时候，请求下个选项框的值，如果此选择框没有联动的下个选项框就为空就可以
      // console.log('接受');
      // console.log(e);
    },
    AdFormatChange(e){
      // 
    },
    // handleClose(tag) {
    //     this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
    // },
    handleClose(tag,index) {
        // console.log(tag)
        // console.log(index)
        // this.eventTags.splice(this.eventTags.indexOf(tag), 1);
        this.eventTags.splice(index, 1);
    },
    actQueryClear(){
       this.eventTags = []
    },
    actQueryAppend(){
      // console.log(this.actQuery)
      let actNameArr = this.actQuery.split(',')
      for(let i = 0; i < actNameArr.length;i++){
          this.eventTags.push(actNameArr[i]);
      }
        // if (eventValue) {
        //     this.eventTags.push(eventValue);
        // }
    },
    showInput() {
        this.inputVisible = true;
        this.$nextTick(_ => {
            this.$refs.saveTagInput.$refs.input.focus();
        });
    },

    showSelectEvent() {
        this.selectEventVisible = true;
    },
    selectEventChange(e){
        let eventValue = e;
        // console.log(selectEvent);
        // console.log(e);
        if (eventValue) {
            this.eventTags.push(eventValue);
        }
        this.selectEventVisible = false;
        this.eventValue = '';
        this.selectEvent = '';
    },

    handleInputConfirm() {
        let inputValue = this.inputValue;
        if (inputValue) {
            this.dynamicTags.push(inputValue);
        }
        this.inputVisible = false;
        this.inputValue = '';
    },
    //checkbox-group回调
    demo(val) {
      this.checkList = val;
    },
    //返回按钮
    fanhuiss() {
      this.fanhui = false;
      this.tableData = [];
    },  
    // 触发搜索按钮
    handleSearch() {
      if ([this.$refs.childProject.select_project] == '') {
        this.$notify({ title: '提示', message: '请填写项目', type: 'warning' });
      }
      if (
        [this.$refs.childProject.select_project] != ''
      ) {
        this.fanhui = true;
        this.pictLoading = true;
        //post请求获取搜索内容
        this.$axios
          .post(
            'FunnelAnalysis/info/',
            {
              date_range: this.date,
              project: [this.$refs.childProject.select_project],
              country: this.$refs.childCountry.select_country,
              platform: [this.Platform],
              app_version: this.appVersion,
              media_source: this.$refs.childMedia.select_value,
              act_list:this.eventTags,
            },
            {
              headers: {
                Authorization: 'JWT ' + localStorage.getItem('jwt-token')
              }
            }
          )
          .then((res) => {
              
            this.tableData = res.data.list;
            this.pageInfo.total = res.data.list.length;
            // this.totalCount = res.data.list.length;
            this.totalCount = res.data.total;
            this.tableColumns = [];
            for (var pro in res.data.list[0]) {
              this.tableColumns.push(pro);
            }
            this.tableColumns1 = this.tableColumns.splice(0, res.data.fix_columns);
            this.tableColumns2 = this.tableColumns;
            this.$nextTick(() => {
              this.$refs.multipleTable.doLayout();
              // el-table加ref="multipleTable"
            });
            this.pictLoading = false;
            // 搜索需要经过子组件里的检索函数
            this.$refs.searchInput.searchTable();
          })
          .catch((e) => {});
      }
    },
    handleSizeChange(val) {
      this.pageInfo.current = 1;
      this.pageInfo.size = val;
    },
    // 检索
    SearchInput(tableList){
      this.tableSearchList = tableList;
      this.pageInfo.total = tableList.length;
    },
    //导出csv文件
    download() {
      //datas：数据，fields：字段名
      try {
        const result = json2csv.parse(this.tableData);
        // 判断浏览器类型
        if (
          (navigator.userAgent.indexOf('compatible') > -1 && navigator.userAgent.indexOf('MSIE') > -1) ||
          navigator.userAgent.indexOf('Edge') > -1
        ) {
          //IE10或Edge浏览器
          var BOM = '\uFEFF';
          var csvData = new Blob([BOM + result], { type: 'text/csv' });
          navigator.msSaveBlob(csvData, `test.csv`);
        } else {
          //非IE浏览器
          var csvContent = 'data:text/csv;charset=utf-8,\uFEFF' + result;
          //使用a标签的download属性实现下载功能
          var link = document.createElement('a');
          link.href = encodeURI(csvContent);
          link.download = `test.csv`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      } catch (err) {
        alert(err);
      }
    },
    //获取游戏名
    getProject() {
      this.$http
        .get('FunnelAnalysis/project_data/', {
          headers: {
            Authorization: 'JWT ' + localStorage.getItem('jwt-token')
          }
        })
        .then(function (res) {
          if (res.data.code == 200) {
            this.$refs.childProject.project_list = res.data.project_data;
            this.UserProjectList = res.data.project_data;
            // this.list1.unshift('all');
          }
        })
        .catch(function (err) {
          console.log(err);
        });
    },
    // 获取国家
    getCountry() {
      this.$axios
        .post(
          'FunnelAnalysis/country_data/',
          {
            date_range: this.date,
            project: [this.$refs.childProject.select_project],
            platform: [this.Platform],
          },
          {
            headers: {
              Authorization: 'JWT ' + localStorage.getItem('jwt-token')
            }
          }
        )
        .then((res) => {
          this.$refs.childCountry.country_list = res.data.country_data;
        })
        .catch((e) => {});
    },
    // // 获取版本
    getAppVersion() {
      this.$axios
        .post(
          'FunnelAnalysis/app_version_data/',
          {
            date_range: this.date,
            project: [this.$refs.childProject.select_project],
            platform: [this.Platform],
          },
          {
            headers: {
              Authorization: 'JWT ' + localStorage.getItem('jwt-token')
            }
          }
        )
        .then((res) => {
            this.appVersionList = res.data.app_version_data;
        })
        .catch((e) => {});
    },
    // 获取媒体渠道
    getMedia(){
        this.$axios
          .post(
            'FunnelAnalysis/media_data/',
            {
              project: [this.$refs.childProject.select_project],
              platform: [this.Platform],
              country: this.$refs.childCountry.select_country,
            },
            {
              headers: {
                Authorization: 'JWT ' + localStorage.getItem('jwt-token')
              }
            }
          )
          .then((res) => {
            this.$refs.childMedia.value_list = res.data.media_data;
          })
        .catch((e) => {});
    },
    // 获取事件
    getEvent() {
      this.$axios
        .post(
          'FunnelAnalysis/event_data/',
          {
            date_range: this.date,
            project: [this.$refs.childProject.select_project],
          },
          {
            headers: {
              Authorization: 'JWT ' + localStorage.getItem('jwt-token')
            }
          }
        )
        .then((res) => {
          this.selectEventList = res.data.event_data;
        })
        .catch((e) => {});
    },
    // 访问
    PageCount() {
      this.$http
        .post('index/count/', 
        {
          page_name:"漏斗分析"
        },
        {
          headers: {
            Authorization: 'JWT ' + localStorage.getItem('jwt-token')
          }
        })
        .then(function (res) {
          if (res.data.code == 200) {
            // this.$refs.childProject.project_list = res.data.project_data;
            // this.list1.unshift('all');
          }
        })
        .catch(function (err) {
          console.log('Auth Error');
        if (localStorage.getItem("jwt-token") != null){
          localStorage.clear();
          window.location.href="/#/login";
        } 
        });
    },
    // 获取之前的日期的函数
    getBeforeDate(n) {
          let _n = n;
          let _d = new Date();
          let _year = _d.getFullYear();
          let _mon = _d.getMonth() + 1;
          let _day = _d.getDate();
          if(_day <= _n) {
              if(_mon > 1) {
                  _mon = _mon - 1;
              } else {
                  _year = _year - 1;
                  _mon = 12;
              }
          }
          _d.setDate(_d.getDate() - n);
          _year = _d.getFullYear();
          _mon = _d.getMonth() + 1;
          _day = _d.getDate();
          var date_sub = _year + "-" + (_mon < 10 ? ('0' + _mon) : _mon) + "-" + (_day < 10 ? ('0' + _day) : _day);
          return date_sub;
      },
  },

  mounted() {
    let timeStamp = new Date();
    let year = new Date(timeStamp).getFullYear();
    let month = new Date(timeStamp).getMonth() + 1 < 10 ? '0' + (new Date(timeStamp).getMonth() + 1) : new Date(timeStamp).getMonth() + 1;
    let month1 = new Date(timeStamp).getMonth() < 10 ? '0' + new Date(timeStamp).getMonth() : new Date(timeStamp).getMonth();
    let date = new Date(timeStamp).getDate() < 10 ? '0' + new Date(timeStamp).getDate() : new Date(timeStamp).getDate();
    // 初始日期间隔
    let date_sub = this.getBeforeDate(1);
    this.date = [date_sub, year + '-' + month + '-' + date];
  },

};
</script>
<style>
.el-tag .el-icon-close {
  float: right;
  margin-top: 4px;
}
.el-tag {
  background: white;
  width: 100%;
}

.el-table .cell,
.el-table th > .cell {
  white-space: pre-line;
  width: fit-content;
}

.el-select__tags {
  flex-wrap: nowrap;
  overflow: hidden;
}
.el-tag.el-tag--info .el-tag__close {
  display: none;
}
.el-tag.el-tag--info {
  border: none;
  background: white;
}
.el-select__tags-text {
  margin-left: 0px;
}
.el-date-editor--daterange.el-input__inner {
  width: 240px;
}
.el-picker-panel {
  margin-left: 240px;
}
.demo-table-expand {
font-size: 0;
}
.demo-table-expand label {
width: 90px;
color: #99a9bf;
}
.demo-table-expand .el-form-item {
margin-right: 0;
margin-bottom: 0;
width: 50%;
}
.el-tag + .el-tag {
margin-left: 10px;
margin-bottom: 5px;
margin-top: 5px;
}
.button-new-tag {
margin-left: 10px;
height: 32px;
line-height: 30px;
padding-top: 0;
padding-bottom: 0;
}
.input-new-tag {
width: 90px;
margin-left: 10px;
vertical-align: bottom;
}
</style>
<style scoped>
.table {
  width: 100%;
  font-size: 14px;
}
</style>