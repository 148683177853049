<template>
        <el-select
            v-model="select_adSource"
            @change="AdsourceChangeChild"
            multiple
            placeholder="广告平台/adSource"
            collapse-tags
            style="margin-right: 10px; width: 180px"
          >
            <div style="float: left; overflow: hidden; height: 200px; overflow-y: auto; width: 200px">
              <el-input
                style="width: 90%; margin-left: 5%; margin-bottom: 5px"
                v-model="inputAdsource"
                @keyup.enter.native="enterAdsource"
                clearable
              ></el-input>
              <button @click="selectAllAdsource" style="height: 24px; width: 70px; margin-left: 13px; margin-bottom: 10px">全选</button>
              <button style="height: 24px; width: 70px; margin-left: 20px" @click="clearAllAdsource">清空</button>
              <el-option v-for="item in ad_source_list" :key="item" :label="item" :value="item"> </el-option>
              <el-option v-if="ad_source_list == ''" label="无相关内容" value="无相关内容" :disabled="true"></el-option>
            </div>
            <div
              style="
                float: left;
                overflow: hidden;
                height: 200px;
                overflow-y: auto;
                padding: 10px;
                width: 160px;
                border-left: 1px #dee1e7 solid;
              "
            >
              <div style="height: 30px" v-for="item in select_adSource" :key="item">
                <el-tag closable @close="handleCloseAdsource(item)">{{ item }}</el-tag>
              </div>
            </div>
          </el-select>
</template>

<script>
export default {
    watch:{
        inputAdsource() {
            var _ad_source_list = [];
            this.ad_source_list.filter((item) => {
                if (item.toUpperCase().includes(this.inputAdsource.toUpperCase())) {
                _ad_source_list.push(item);
                }
            });
            this.ad_source_list = _ad_source_list;
            if (this.inputAdsource == '') {
                this.$parent.getAdsource();
            }
        },
    },
  methods: {
    AdsourceChangeChild(){
          this.$emit('AdsourceChange',this.select_adSource);
    },
    enterAdsource() {
      if (this.select_adSource.includes(this.ad_source_list[0])) {
        console.log('已选中');
      } else this.select_adSource.push(this.ad_source_list[0]);
      // this.getJH();
    },
    selectAllAdsource() {
      this.select_adSource = this.ad_source_list;
      this.AdsourceChangeChild();
    },
    clearAllAdsource(){
      this.select_adSource = [];
      this.checked_ad_source = false;
      this.AdsourceChangeChild();
    },

    handleCloseAdsource(tag) {
      this.select_adSource.splice(this.select_adSource.indexOf(tag), 1);
      this.AdsourceChangeChild();
    },
  },
 data() {
    return {
      inputAdsource:'',
      ad_source_list: [],
      checked_ad_source: false,
      select_adSource:[]
      
    };
  },
}
</script>
