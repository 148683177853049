<template>
  <div>
    <div v-if="user_group.indexOf('测试组') == -1 && user_group.indexOf('产品组') == -1">
      <cw403></cw403>
    </div>
    <div v-if="user_group.indexOf('测试组') > -1 || user_group.indexOf('产品组') > -1">
      <div class="crumbs">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item style="font-size: 16px">
            变现基本数据
            <el-button type="text" style="font-size: 16px" @click="dialogVisible = true">(页面说明)</el-button>
          </el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="container">
        <div class="options-bar">
          <span style="color:gray;margin-right:30px;">筛选维度</span>
          <date-picker
            style="margin-right: 10px"
            v-model="date"
          >
          </date-picker>
          <el-select v-model="project" default-first-option=true @change="project_change" filterable placeholder="项目" collapse-tags style="margin-right: 10px; width: 160px">
            <el-option v-for="item in project_list" :key="item" :label="item" :value="item"></el-option>
          </el-select>
          <el-select v-model="platform" @change="platform_change" placeholder="平台" collapse-tags style="margin-right: 10px; width: 100px">
            <el-option v-for="item in platform_list" :key="item.vaue" :label="item.label" :value="item.value"> </el-option>
          </el-select>
          <el-select v-model="country" filterable clearable default-first-option=true placeholder="国家" collapse-tags style="margin-right: 10px; width: 100px">
            <el-option v-for="item in country_list" :key="item" :label="item" :value="item"> </el-option>
          </el-select>
          <el-select v-model="point" multiple filterable clearable default-first-option=true placeholder="广告点" collapse-tags style="margin-right: 10px; width: 180px">
            <el-option v-for="item in point_list" :key="item" :label="item" :value="item"> </el-option>
          </el-select>
          <el-button type="primary" plain icon="el-icon-search" @click="handleSearch" :loading="loadingT"
            >搜索</el-button
          >
          <el-button v-if="fanhui" type="primary" plain icon="el-icon-search" @click="fanhuiss">清空筛选</el-button>
        </div>

        <p style="margin-bottom: 0px; margin-top: 10px; font-size: 16px; color: gray">渗透率</p>
        <div id="shentou" style="width: 100%; height: 400px; background: #fff"></div>
        <p style="margin-bottom: 0px; margin-top: 10px; font-size: 16px; color: gray">广告失败率</p>
        <div id="adfail" style="width: 100%; height: 400px; background: #fff"></div>
        <p style="margin-bottom: 0px; margin-top: 10px; font-size: 16px; color: gray">分小时数据</p>
        <div id="hour_data" style="width: 100%; height: 400px; background: #fff"></div>
        <p style="margin-bottom: 0px; margin-top: 10px; font-size: 16px; color: gray">人均触发</p>
        <div id="ad_show" style="width: 100%; height: 400px; background: #fff"></div>


      </div>

      <el-dialog title="变现基础数据模块" :visible.sync="dialogVisible" width="30%">
        <div style="margin-top: -20px">
          <h2>内容介绍</h2>
          <ul style="margin-left: 40px; margin-top: 10px">
            <li>展示基本的渗透/人均展示/分时展示数据</li>
          </ul>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { fetchData } from '../../api/index';
import json2csv from 'json2csv';
import echarts from 'echarts';
import cw403 from './403.vue';
import ElSearchInput from '../SelectComponent/searchInput.vue';
export default {
  name: 'iaa-basic',
  components: {
    cw403,
    ElSearchInput
  },
  data() {
    return {
      inputXM: '',
      user_group: localStorage.getItem('user-group'),
      loadingT: false,
      tableSearchList: [],
      tableColumns: [],
      tableColumns1: [],
      tableColumns2: [],
      fanhui: false,
      checked1: false,
      checked2: false,
      checked3: false,
      pictLoading: false,
      pageInfo: {
        size: 50,
        current: 1,
        total: 0,
      },
      date: [],

      // 三个视图
      table_shentou : [],
      table_adfail : [],
      table_hour : [],
      table_adshow : [],

      dialogVisible: false,
      type: false,
      project:[],
      project_list:[],
      platform:[],
      platform_list:[
        { label: 'IOS', value: 'ios' },
        { label: 'Android', value: 'android' }
      ],
      country:[],
      country_list:[],
      point:[],
      point_list:[],
      tableData: [],
      multipleSelection: [],
      delList: [],
      editVisible: false,
      pageTotal: 0,
      form: {},
      idx: -1,
      id: -1
    };
  },
  created() {
    /* this.getData(); */
    //this.getTable();
    this.get_project();
    this.PageCount();
  },
  methods: {
    //获取项目
    get_project() {
      this.$http
        .get('iaa/project_data/', {
          headers: {
            Authorization: 'JWT ' + localStorage.getItem('jwt-token')
          }
        })
        .then(function (res) {
          if (res.data.code == 200) {
            this.project_list = res.data.project_data;
          }
        })
        .catch(function (err) {
          console.log(err);
        });
    },
    // 项目变更
    project_change(){
      this.getCountry();
      this.getPoint();
    },
    // 平台变更
    platform_change(){
      this.getCountry();
      this.getPoint();
    },
    // 查询国家
    getCountry(){
      this.$axios
        .post(
          'iaa/country/',
          {
            project: this.project,
            platform: this.platform
          },
          {
            headers: {
              Authorization: 'JWT ' + localStorage.getItem('jwt-token')
            }
          }
        )
        .then((res) => {
          if (res.data.code == 200) {
            this.country_list = res.data.country_data;
          }
        })
        .catch((e) => {});
    },
    // 查询广告点
    getPoint(){
      this.$axios
        .post(
          'iaa/point/',
          {
            project: this.project,
            platform: this.platform
          },
          {
            headers: {
              Authorization: 'JWT ' + localStorage.getItem('jwt-token')
            }
          }
        )
        .then((res) => {
          if (res.data.code == 200) {
            this.point_list = res.data.point_data;
          }
        })
        .catch((e) => {});
    },
    //清空筛选
    fanhuiss() {
      this.pictLoading = false;
      this.project = [];
      this.platform = [];
      this.country = [];
      this.tableData = [];
      this.fanhui = false;
      this.loadingT = false;
    },
    // 触发搜索按钮
    handleSearch() {
      this.loadingT = true;
      this.pictLoading = true;
      this.fanhui = true;
      //post请求获取搜索内容
      this.$axios
        .post(
          'iaa/info/',
          {
            project: this.project,
            platform: this.platform,
            date_range: this.date,
            country: this.country,
            point: this.point
          },
          {
            headers: {
              Authorization: 'JWT ' + localStorage.getItem('jwt-token')
            }
          }
        )
        .then((res) => {
          if (res.data.code == 200) {
            this.pictLoading = false;
            this.loadingT = false;
            this.fanhui = false;
            // shentou
            this.table_shentou.setOption(
                {
              xAxis: {
                  type: 'category',
                  data: res.data.list.shentou.xAxis
              },
              tooltip: {
                trigger: 'axis', //坐标轴触发，主要在柱状图，折线图等会使用类目轴的图表中使用
                axisPointer: {// 坐标轴指示器，坐标轴触发有效
                  type: 'line' // 默认为直线，可选为：'line' | 'shadow'
                }
              },
              yAxis: [{
                  type: 'value',
                },{
                  type: 'value',
                  axisLabel: {
                    show: true,
                    interval: 'auto',
                    formatter: '{value}%'
                  },
              }],
              series: res.data.list.shentou.series
            },true);
            // adfail
            this.table_adfail.setOption(
                {
              xAxis: {
                  type: 'category',
                  data: res.data.list.ad_fail.xAxis
              },
              tooltip: {
                trigger: 'axis', //坐标轴触发，主要在柱状图，折线图等会使用类目轴的图表中使用
                axisPointer: {// 坐标轴指示器，坐标轴触发有效
                  type: 'line' // 默认为直线，可选为：'line' | 'shadow'
                }
              },
              yAxis: [{
                  type: 'value',
                  axisLabel: {
                    show: true,
                    interval: 'auto',
                    formatter: '{value}%'
                  },
              }],
              series: res.data.list.ad_fail.series
            },true);
            // hour
            this.table_hour.setOption(
              {
                xAxis: {
                  type: 'category',
                  data: res.data.list.hour.xAxis,
                  axisLabel: {
                    interval: res.data.list.hour.interval
                  }
                },
                tooltip: {
                  trigger: 'axis', //坐标轴触发，主要在柱状图，折线图等会使用类目轴的图表中使用
                  axisPointer: {// 坐标轴指示器，坐标轴触发有效
                    type: 'line' // 默认为直线，可选为：'line' | 'shadow'
                  }
                },
                yAxis: {
                    type: 'value'
                },
                series: res.data.list.hour.series
              },true);
            // adshow
            this.table_adshow.setOption(
              {
                xAxis: {
                  type: 'category',
                  data: res.data.list.ad_show.xAxis
                },
                tooltip: {
                  trigger: 'axis', //坐标轴触发，主要在柱状图，折线图等会使用类目轴的图表中使用
                  axisPointer: {// 坐标轴指示器，坐标轴触发有效
                    type: 'line' // 默认为直线，可选为：'line' | 'shadow'
                  }
                },
                yAxis: {
                    type: 'value',
                    axisLabel: {
                    show: true,
                    interval: 'auto',
                    formatter: '{value}'
                    },
                },
                series: res.data.list.ad_show.series

              },true);
          }
        })
        .catch((e) => {});
    },
    handleSizeChange(val) {
      this.pageInfo.current = 1;
    },
    // 检索
    SearchInput(tableList){
      this.tableSearchList = tableList;
      this.pageInfo.total = tableList.length;
    },
    //导出csv文件
    download() {
      //datas：数据，fields：字段名
      try {
        var c = this.tableData;
        c.filter((item) => {
          delete item._XID;
        });
        const result = json2csv.parse(c);
        // 判断浏览器类型
        if (
          (navigator.userAgent.indexOf('compatible') > -1 && navigator.userAgent.indexOf('MSIE') > -1) ||
          navigator.userAgent.indexOf('Edge') > -1
        ) {
          //IE10或Edge浏览器
          var BOM = '\uFEFF';
          var csvData = new Blob([BOM + result], { type: 'text/csv' });
          navigator.msSaveBlob(csvData, `test.csv`);
        } else {
          //非IE浏览器
          var csvContent = 'data:text/csv;charset=utf-8,\uFEFF' + result;
          //使用a标签的download属性实现下载功能
          var link = document.createElement('a');
          link.href = encodeURI(csvContent);
          link.download = `data.csv`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      } catch (err) {
        alert(err);
      }
    },
    // 访问
    PageCount() {
      this.$http
        .post('index/count/', 
        {
          page_name:"变现基本数据"
        },
        {
          headers: {
            Authorization: 'JWT ' + localStorage.getItem('jwt-token')
          }
        })
        .then(function (res) {
          if (res.data.code == 200) {
            // this.$refs.childProject.project_list = res.data.project_data;
            // this.list1.unshift('all');
          }
          else if (response.status === 401) {
            console.log('DO WHAT YOU WANT2222');
            if (localStorage.getItem("jwt-token") != null){
              localStorage.clear();
              window.location.href="/#/login";
            } 
          }
        })
        .catch(function (err) {
          console.log('Auth Error');
        if (localStorage.getItem("jwt-token") != null){
          localStorage.clear();
          window.location.href="/#/login";
        } 
        });
    },
    // 获取之前的日期的函数
    getBeforeDate(n) {
          let _n = n;
          let _d = new Date();
          let _year = _d.getFullYear();
          let _mon = _d.getMonth() + 1;
          let _day = _d.getDate();
          if(_day <= _n) {
              if(_mon > 1) {
                  _mon = _mon - 1;
              } else {
                  _year = _year - 1;
                  _mon = 12;
              }
          }
          _d.setDate(_d.getDate() - n);
          _year = _d.getFullYear();
          _mon = _d.getMonth() + 1;
          _day = _d.getDate();
          var date_sub = _year + "-" + (_mon < 10 ? ('0' + _mon) : _mon) + "-" + (_day < 10 ? ('0' + _day) : _day);
          return date_sub;
      },
  },
  mounted() {
    let timeStamp = new Date();
    let year = new Date(timeStamp).getFullYear();
    let month = new Date(timeStamp).getMonth() + 1 < 10 ? '0' + (new Date(timeStamp).getMonth() + 1) : new Date(timeStamp).getMonth() + 1;
    let month1 = new Date(timeStamp).getMonth() < 10 ? '0' + new Date(timeStamp).getMonth() : new Date(timeStamp).getMonth();
    let date = new Date(timeStamp).getDate() < 10 ? '0' + new Date(timeStamp).getDate() : new Date(timeStamp).getDate();
    let date_sub = this.getBeforeDate(30);
    this.date = [date_sub, year + '-' + month + '-' + date];
    this.table_shentou = echarts.init(document.getElementById('shentou'));
    this.table_adfail = echarts.init(document.getElementById('adfail'));
    this.table_hour = echarts.init(document.getElementById('hour_data'));
    this.table_adshow = echarts.init(document.getElementById('ad_show'));
  },
  watch: {
  }
};
</script>
<style>
.el-tag .el-icon-close {
  float: right;
  margin-top: 4px;
}
.el-tag {
  background: white;
  width: 100%;
}
.elx-header--column.col--ellipsis > .elx-cell .elx-cell--title {
  overflow: initial;
  text-overflow: ellipsis;
  white-space: pre-line;
}
.elx-table {
  font-size: 12px;
}
.elx-table .elx-header--column {
  position: relative;
  line-height: 1;
  text-align: left;
}
.el-select__tags {
  flex-wrap: nowrap;
  overflow: hidden;
}
.el-tag.el-tag--info .el-tag__close {
  display: none;
}
.el-tag.el-tag--info {
  border: none;
  background: white;
}
.el-select__tags-text {
  margin-left: 0px;
}
.el-date-editor--daterange.el-input__inner {
  width: 240px;
}
.el-picker-panel {
  margin-left: 240px;
}
</style>
<style scoped>
.table {
  width: 100%;
  font-size: 14px;
  margin-top: 20px;
}
</style>
