<template>
  <div>
    <div v-if="user_group.indexOf('测试组') == -1 && user_group.indexOf('产品组') == -1">
      <cw403></cw403>
    </div>
    <div v-if="user_group.indexOf('测试组') > -1 || user_group.indexOf('产品组') > -1">
      <div class="crumbs">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item style="font-size: 16px">
            群组分析模块
            <el-button type="text" style="font-size: 16px" @click="dialogVisible = true">(页面说明)</el-button>
            <font style="margin-left: 20px; color: #409eff">展示顺序:&nbsp;&nbsp;&nbsp;{{ checkList }}</font>
          </el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="container">
        <div class="options-bar">
          <font style="16px;color:gray;margin-right:30px;">分组维度</font>
          <el-checkbox-group @change="demo" v-model="checkList" style="display: inline">
            <el-checkbox label="date" disabled></el-checkbox>
            <el-checkbox label="app_id"></el-checkbox>
            <el-checkbox label="country"></el-checkbox>
            <el-checkbox label="media"></el-checkbox>
            <el-checkbox label="campaign_name"></el-checkbox>
            <el-checkbox label="version"></el-checkbox>
          </el-checkbox-group>
        </div>
        <div class="options-bar">
          <span style="color:gray;margin-right:30px;">筛选维度</span>
          <date-picker
            style="margin-right: 10px"
            v-model="date"
          >
          </date-picker>
          <!-- project select -->
          <el-select
            v-model="QQ.gameName"
            @change="selectXM"
            multiple
            placeholder="项目"
            collapse-tags
            style="margin-right: 10px; width: 180px"
          >
            <div style="float: left; overflow: hidden; height: 200px; overflow-y: auto; width: 200px">
              <el-input
                style="width: 90%; margin-left: 5%; margin-bottom: 5px"
                v-model="inputXM"
                @keyup.enter.native="enterXM"
                clearable
              ></el-input>
              <button @click="selectAll1" style="height: 24px; width: 70px; margin-left: 13px; margin-bottom: 10px">全选</button>
              <button style="height: 24px; width: 70px; margin-left: 20px" @click="qinchu1">清空</button>
              <el-option v-for="item in list1" :key="item" :label="item" :value="item"></el-option>
              <el-option v-if="list1 == ''" label="无相关内容" value="无相关内容" :disabled="true"></el-option>
            </div>
            <div
              style="
                float: left;
                overflow: hidden;
                height: 200px;
                overflow-y: auto;
                padding: 10px;
                width: 150px;
                border-left: 1px #dee1e7 solid;
              "
            >
              <div style="height: 30px" v-for="item in QQ.gameName" :key="item">
                <el-tag closable @close="handleClose(item)">{{ item }}</el-tag>
              </div>
            </div>
          </el-select>
          <!-- platform select -->
          <el-select
            v-model="QQ.phone"
            @change="selectPT"
            multiple
            placeholder="平台"
            collapse-tags
            style="margin-right: 10px; width: 180px"
          >
            <el-option v-for="item in list2" :key="item.value" :label="item.label" :value="item.value"> </el-option>
          </el-select>
          <!-- country select -->
          <el-select
            v-model="QQ.country"
            @change="selectGZ"
            multiple
            placeholder="所有国家/地区"
            collapse-tags
            style="margin-right: 10px; width: 180px"
          >
            <div style="float: left; overflow: hidden; height: 200px; overflow-y: auto; width: 200px">
              <el-input
                style="width: 90%; margin-left: 5%; margin-bottom: 5px"
                v-model="inputGZ"
                @keyup.enter.native="enterGZ"
                clearable
              ></el-input>
              <button @click="selectAll3" style="height: 24px; width: 70px; margin-left: 13px; margin-bottom: 10px">全选</button>
              <button style="height: 24px; width: 70px; margin-left: 20px" @click="qinchu3">清空</button>
              <el-option v-for="item in list3" :key="item" :label="item" :value="item"> </el-option>
              <el-option v-if="list3 == ''" label="无相关内容" value="无相关内容" :disabled="true"></el-option>
            </div>
            <div
              style="
                float: left;
                overflow: hidden;
                height: 200px;
                overflow-y: auto;
                padding: 10px;
                width: 160px;
                border-left: 1px #dee1e7 solid;
              "
            >
              <div style="height: 30px" v-for="item in QQ.country" :key="item">
                <el-tag closable @close="handleCloseGZ(item)">{{ item }}</el-tag>
              </div>
            </div>
          </el-select>
        </div>
        <!-- event selecte -->
        <div class="options-bar" style="margin-left: 95px">
          <el-select v-model="QQ2.sj" 
            placeholder="事件名称" 
            filterable
            remote
            clearable
            :remote-method="sjRemoteMethod"
            :loading="sjLoading"
            style="margin-right: 10px; width: 240px">
              <el-option v-for="item in sjTempList" :key="item" :label="item" :value="item"></el-option>
              <el-option v-if="sjTempList == ''" label="无相关内容" value="无相关内容" :disabled="true"></el-option>
          </el-select>
          <el-select v-model="QQ2.mt" multiple placeholder="所有媒体渠道" collapse-tags style="margin-right: 10px; width: 180px">
            <div style="float: left; overflow: hidden; height: 200px; overflow-y: auto; width: 200px">
              <el-input
                style="width: 90%; margin-left: 5%; margin-bottom: 5px"
                v-model="inputMT"
                @keyup.enter.native="enterMT"
                clearable
              ></el-input>
              <button @click="selectAll5" style="height: 24px; width: 70px; margin-left: 13px; margin-bottom: 10px">全选</button>
              <button style="height: 24px; width: 70px; margin-left: 30px" @click="qinchu5">清空</button>
              <el-option v-for="item in list5" :key="item" :label="item" :value="item"> </el-option>
              <el-option v-if="list5 == ''" label="无相关内容" value="无相关内容" :disabled="true"></el-option>
            </div>
            <div
              style="
                float: left;
                overflow: hidden;
                height: 200px;
                overflow-y: auto;
                padding: 10px;
                width: 160px;
                border-left: 1px #dee1e7 solid;
              "
            >
              <div style="height: 30px" v-for="item in QQ2.mt" :key="item">
                <el-tag closable @close="handleCloseMT(item)">{{ item }}</el-tag>
              </div>
            </div>
          </el-select>
          <el-select v-model="QQ2.jh" multiple placeholder="计划名称" collapse-tags style="margin-right: 10px; width: 180px">
            <div style="float: left; overflow: hidden; height: 200px; overflow-y: auto; width: 300px">
              <el-input
                style="width: 90%; margin-left: 5%; margin-bottom: 5px"
                v-model="inputJH"
                @keyup.enter.native="enterJH"
                clearable
              ></el-input>
              <button @click="selectAll7" style="height: 24px; width: 70px; margin-left: 50px; margin-bottom: 10px">全选</button>
              <button style="height: 24px; width: 70px; margin-left: 60px" @click="qinchu7">清空</button>
              <el-option v-for="item in list7" :key="item" :label="item" :value="item"> </el-option>
              <el-option v-if="list7 == ''" label="无相关内容" value="无相关内容" :disabled="true"></el-option>
            </div>
            <div
              style="
                float: left;
                overflow: hidden;
                height: 200px;
                overflow-y: auto;
                padding: 10px;
                width: 300px;
                border-left: 1px #dee1e7 solid;
              "
            >
              <div style="height: 30px" v-for="item in QQ2.jh" :key="item">
                <el-tag closable @close="handleCloseJH(item)">{{ item }}</el-tag>
              </div>
            </div>
          </el-select>
          <el-select v-model="QQ2.version" multiple placeholder="应用版本" collapse-tags style="margin-right: 10px; width: 180px">
            <el-option v-for="item in version_data" :key="item" :label="item" :value="item"> </el-option>
          </el-select>
        </div>
        <div class="options-bar">
          <!-- dimension select -->
          <span style="font-size: 14px; color: gray">统计维度：</span>
          <el-select v-model="QQ3.sta_dimension" placeholder="" collapse-tags style="margin-right: 10px; width: 170px">
            <el-option v-for="item in list31" :key="item.value" :label="item.label" :value="item.value"> </el-option>
          </el-select>
          <span style="font-size: 14px; color: gray">按天统计：</span>
          <el-popover placement="top-start" width="200" trigger="hover" content="选择否时，将会使用累计统计">
            <el-select slot="reference" v-model="QQ3.is_on_day" placeholder="" collapse-tags style="margin-right: 10px; width: 110px">
              <el-option v-for="item in list32" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </el-popover>
          <span style="font-size: 14px; color: gray">对比：</span>
          <el-select slot="reference" v-model="QQ3.is_compared" placeholder="" collapse-tags style="margin-right: 35px; width: 110px">
            <el-option v-for="item in list33" :key="item.value" :label="item.label" :value="item.value"> </el-option>
          </el-select>
          <!-- search button -->
          <el-button
            type="primary"
            plain
            icon="el-icon-search"
            @click="handleSearch"
            style="margin-bottom: 10px; margin-top: 10px; margin-right: 10px"
            :disabled="kaiguan"
            >搜索</el-button
          >
          <el-button v-if="fanhui" plain type="primary" icon="el-icon-search" @click="fanhuiss" style="margin-left: 0px">返回</el-button>
        </div>
        <div class="options-bar">
          <span style="16px;color:gray;margin-right:30px;float:left;">快速检索</span>
          <ElSearchInput ref="searchInput" @SearchInput='SearchInput($event)'></ElSearchInput>
          <el-button
            @click="download"
            style="margin-left: auto"
            icon="el-icon-s-release"
            type="info"
            plain
          >导出csv</el-button>
        </div>
        <el-table
          v-loading="pictLoading"
          element-loading-text="数据正在加载中"
          :data="tableSearchList"
          height="550"
          border
          class="table"
          ref="multipleTable"
          header-cell-class-name="table-header"
          :header-cell-style="{ color: '#696969' }"
        >
          <ex-table-column
            :min-width="120"
            :autoFit="true"
            :fitHeader="true"
            sortable
            v-for="item in tableColumns1"
            :key="item"
            :prop="item"
            fixed
            :label="item"
          />

          <ex-table-column
            :autoFit="true"
            :fitHeader="true"
            sortable
            v-for="item in tableColumns2"
            :key="item"
            :prop="item"
            :label="item"
          />
        </el-table>
        <pagination
          @size-change="handleSizeChange"
          :current-page.sync="pageInfo.current"
          :page-size.sync="pageInfo.size"
          :total="pageInfo.total"
        ></pagination>
      </div>

      <el-dialog title="市场消耗模块" :visible.sync="dialogVisible" width="30%">
        <div style="margin-top: -20px">
          <h2>分组维度/筛选条件</h2>
          <ul style="margin-left: 40px; margin-top: 10px">
            <li>项目</li>
            <li>渠道</li>
            <li>媒体渠道</li>
            <li>日期范围</li>
            <li>国家</li>
            <li>账号</li>
            <li>Campaign名称</li>
          </ul>
          <h2 style="margin-top: 20px">数据指标</h2>
          <ul style="margin-left: 40px; margin-top: 10px">
            <li>消耗</li>
            <li>安装</li>
            <li>CPI</li>
            <li>CPM</li>
            <li>展示数</li>
            <li>点击</li>
            <li>点击率</li>
            <li>展示</li>
          </ul>
          <h2 style="margin-top: 20px">说明</h2>
          <ul style="margin-left: 40px; margin-top: 10px">
            <li>搜索条件对大小写不敏感，us US Us 均可搜索到US地区的数据</li>
            <li>快速检索：输入字段进行检索，支持多字段检索（以空格分隔输入，例：2020-10-01 火柴人神射手 ios）</li>
          </ul>
        </div>

        <!-- <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
            </span> -->
      </el-dialog>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import echarts from 'echarts';
import { fetchData } from '../../api/index';
import json2csv from 'json2csv';
import cw403 from './403.vue';
import ElSearchInput from '../SelectComponent/searchInput.vue';
export default {
  name: 'market-table1',
  components: {
    cw403,
    ElSearchInput
  },
  data() {
    return {
      kaiguan: false,
      inputXM: '',
      inputGZ: '',
      inputMT: '',
      inputTF: '',
      inputJH: '',
      list31: [
        { label: '总次数', value: 'count' },
        { label: '人均次数', value: 'per_user_count' },
        { label: '唯一用户数', value: 'unique_user' }
      ],
      list32: [
        { label: '是', value: 1 },
        { label: '否', value: 0 }
      ],
      list33: [
        { label: '是', value: 1 },
        { label: '否', value: 0 }
      ],
      listr: ['tt', 'vv', 'cc', 'Crystal', 'LM', 'DD'],
      renyuan: [],
      tableSearchList: [],
      user_group: localStorage.getItem('user-group'),
      checkList: ['date'],
      tableColumns: [],
      tableColumns1: [],
      tableColumns2: [],
      //折线图参数
      time: [],
      active1: [],
      active2: [],
      addmoney1: [],
      addmoney2: [],
      fanhui: false,
      checked1: false,
      checked2: false,
      checked3: false,
      checked5: false,
      checked6: false,
      checked7: false,
      checked9: false,
      pictLoading: false,
      sjLoading: false,
      pageInfo: {
        size: 50,
        current: 1,
        total: 0,
      },
      date: [],

      dialogVisible: false,
      type: false,
      list1: [],
      list2: [
        { label: '所有平台', value: 'all' },
        { label: 'IOS', value: 'ios' },
        { label: 'Android', value: 'android' }
      ],
      list3: [],
      version_data: [],
      list5: ['Organic', 'Fb', 'Tiktok', 'Unity', 'GoogleAd', 'Other'],
      list6: [],
      list7: [],
      list9: [],
      sjTempList: [],
      QQ: {
        date: [],
        gameName: [],
        phone: [],
        version: [],
        country: []
      },
      QQ2: {
        sj: [],
        mt: [],
        tf: [],
        jh: []
      },
      QQ3: {
        sta_dimension: 'count',
        is_on_day: 0,
        is_compared: 0
      },
      tableData: [],
      multipleSelection: [],
      delList: [],
      editVisible: false,
      pageTotal: 0,
      form: {},
      idx: -1,
      id: -1
    };
  },
  created() {
    this.getXM();
    this.getGZ();
  },
  methods: {
    //回车选中第一项方法
    enterXM() {
      if (this.QQ.gameName.includes(this.list1[0])) {
        console.log('已选中');
      } else this.QQ.gameName.push(this.list1[0]);
      this.getGZ();
      this.getJH();
    },
    enterGZ() {
      if (this.QQ.country.includes(this.list3[0])) {
        console.log('已选中');
      } else this.QQ.country.push(this.list3[0]);
      this.getJH();
    },
    enterMT() {
      if (this.QQ2.mt.includes(this.list5[0])) {
        console.log('已选中');
      } else this.QQ2.mt.push(this.list5[0]);
      /* this.getTF() */
      this.getJH();
    },
    enterTF() {
      if (this.QQ2.tf.includes(this.list6[0])) {
        console.log('已选中');
      } else this.QQ2.tf.push(this.list6[0]);
      this.getJH();
    },
    enterJH() {
      if (this.QQ2.jh.includes(this.list7[0])) {
        console.log('已选中');
      } else this.QQ2.jh.push(this.list7[0]);
    },
    handleClose(tag) {
      this.QQ.gameName.splice(this.QQ.gameName.indexOf(tag), 1);
    },
    handleCloseGZ(tag) {
      this.QQ.country.splice(this.QQ.country.indexOf(tag), 1);
    },
    handleCloseMT(tag) {
      this.QQ2.mt.splice(this.QQ2.mt.indexOf(tag), 1);
    },
    handleCloseTF(tag) {
      this.QQ2.tf.splice(this.QQ2.tf.indexOf(tag), 1);
    },
    handleCloseJH(tag) {
      this.QQ2.jh.splice(this.QQ2.jh.indexOf(tag), 1);
    },
    //checkbox-group回调
    demo(val) {
      this.checkList = val;
    },
    //返回按钮
    fanhuiss() {
      this.fanhui = false;
      this.tableData = [];
    },
    //添加全选功能
    selectAll1() {
      this.QQ.gameName = this.list1;
      this.getJH();
      this.getSJ();
    },
    qinchu1() {
      this.QQ.gameName = [];
      this.checked1 = false;
    },
    selectAll2() {
      this.QQ.phone = [];
      if (this.checked2) {
        this.QQ.phone = ['all', 'ios', 'android'];
      } else this.QQ.phone = [];
      this.getSJ();

      /* this.getMT() */
      /* this.getTF() */
      this.getJH();
    },
    selectAll3() {
      this.QQ.country = this.list3;

      /* this.getMT() */
      /* this.getTF() */
      this.getJH();
    },
    qinchu3() {
      this.QQ.country = [];
      this.checked3 = false;
    },
    selectAll5() {
      this.QQ2.mt = this.list5;

      /* this.getTF() */
      this.getJH();
    },
    qinchu5() {
      this.QQ2.mt = [];
      this.checked5 = false;
    },
    selectAll6() {
      this.QQ2.tf = this.list6;
      this.getJH();
    },
    qinchu6() {
      this.QQ2.tf = [];
      this.checked6 = false;
    },
    selectAll7() {
      this.QQ2.jh = this.list7;
      this.getJH();
    },
    qinchu7() {
      this.QQ2.jh = [];
      this.checked7 = false;
    },
    selectAll9() {
      this.renyuan = [];
      if (this.checked9) {
        this.renyuan = this.listr;
      } else this.renyuan = [];
    },
    qinchu9() {
      this.renyuan = [];
      this.checked9 = false;
    },
    sjRemoteMethod(query) {
      this.sjLoading = true
      if (query !== '') {
        this.sjTempList = this.list9.filter(item => {
          return item.includes(query)
        })
      } else {
        this.sjTempList = [];
      }
      this.sjLoading = false
    },
    getTable() {
      //发起get请求
      this.$http
        .get('mar_consume/init_data/', {
          headers: {
            Authorization: 'JWT ' + localStorage.getItem('jwt-token')
          }
        })
        .then(function (res) {
          if (res.data.code == 200) {
            this.tableData = res.data.list;
            this.pageInfo.total = res.data.list.length;
            this.tableColumns = [];
            for (var pro in res.data.list[0]) {
              this.tableColumns.push(pro);
            }
            this.tableColumns1 = this.tableColumns.splice(0, 1);
            this.tableColumns2 = this.tableColumns;
            this.pictLoading = false;
          }
        })
        .catch(function (err) {
          console.log(err);
        });

      /* this.$axios({
                method:'get',
                url:'http://192.168.1.29:8085/basicdata/test/'
            }).then((response) =>{          //这里使用了ES6的语法
                console.log(response)       //请求成功返回的数据
            }).catch((error) =>
                console.log(error)       //请求失败返回的数据
            ) */
    },
    // 触发搜索按钮
    handleSearch() {
      if (this.QQ.gameName == '') {
        this.$notify({ title: '提示', message: '请填写项目', type: 'warning' });
      }
      if (this.QQ.phone == '') {
        this.$notify({ title: '提示', message: '请填写平台', type: 'warning' });
      }
      if (this.QQ2.sj == '') {
        this.$notify({ title: '提示', message: '请填写事件', type: 'warning' });
      }
      if (this.QQ3.sta_dimension == '') {
        this.$notify({ title: '提示', message: '请填写统计维度', type: 'warning' });
      }
      if (this.QQ3.is_on_day === '') {
        this.$notify({ title: '提示', message: '请填写是否按天统计', type: 'warning' });
      }
      if (this.QQ3.is_compared === '') {
        this.$notify({ title: '提示', message: '请填写是否对比', type: 'warning' });
      }
      if (
        this.QQ.gameName != '' &&
        this.QQ.phone != '' &&
        this.QQ2.sj != '' &&
        this.QQ3.sta_dimension != '' &&
        this.QQ3.is_on_day !== '' &&
        this.QQ3.is_compared !== ''
      ) {
        this.fanhui = true;
        this.pictLoading = true;
        //post请求获取搜索内容
        this.$axios
          .post(
            'cohort/info/',
            {
              date_range: this.date,
              project: this.QQ.gameName,
              platform: this.QQ.phone,
              version: this.QQ.version,
              country: this.QQ.country,
              media: this.QQ2.mt,
              campaign_name: this.QQ2.jh,
              event_name: this.QQ2.sj,
              sta_dimension: this.QQ3.sta_dimension,
              is_on_day: this.QQ3.is_on_day,
              is_compared: this.QQ3.is_compared,
              show_dimension: this.checkList,
              version:this.QQ2.version
            },
            {
              headers: {
                Authorization: 'JWT ' + localStorage.getItem('jwt-token')
              }
            }
          )
          .then((res) => {
            this.tableData = res.data.list;
            this.pageInfo.total = res.data.list.length;
            this.tableColumns = [];
            for (var pro in res.data.list[0]) {
              this.tableColumns.push(pro);
            }
            this.tableColumns1 = this.tableColumns.splice(0, res.data.fix_columns);
            this.tableColumns2 = this.tableColumns;
            this.$nextTick(() => {
              this.$refs.multipleTable.doLayout();
              // el-table加ref="multipleTable"
            });
            this.pictLoading = false;
            this.$refs.searchInput.searchTable();
          })
          .catch((e) => {});
      }
    },
    handleSizeChange() {
      this.pageInfo.current = 1;
    },
    // 检索
    SearchInput(tableList){
      this.tableSearchList = tableList;
      this.pageInfo.total = tableList.length;
    },
    //导出csv文件
    download() {
      //datas：数据，fields：字段名
      try {
        const result = json2csv.parse(this.tableData);
        // 判断浏览器类型
        if (
          (navigator.userAgent.indexOf('compatible') > -1 && navigator.userAgent.indexOf('MSIE') > -1) ||
          navigator.userAgent.indexOf('Edge') > -1
        ) {
          //IE10或Edge浏览器
          var BOM = '\uFEFF';
          var csvData = new Blob([BOM + result], { type: 'text/csv' });
          navigator.msSaveBlob(csvData, `test.csv`);
        } else {
          //非IE浏览器
          var csvContent = 'data:text/csv;charset=utf-8,\uFEFF' + result;
          //使用a标签的download属性实现下载功能
          var link = document.createElement('a');
          link.href = encodeURI(csvContent);
          link.download = `test.csv`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      } catch (err) {
        alert(err);
      }
    },
    selectXM(val) {
      //如果项目选择框为空，赋予list1，相当于全选
      if (val.length === this.list1) {
        this.checked1 = true;
      } else this.checked1 = false;
      if (this.QQ.gameName != '') {
        this.$axios
          .post(
            'cohort/country_data/',
            {
              project: this.QQ.gameName,
              platform: ['all', 'ios', 'android']
            },
            {
              headers: {
                Authorization: 'JWT ' + localStorage.getItem('jwt-token')
              }
            }
          )
          .then((res) => {
            this.list3 = res.data.country_data;
          })
          .catch((e) => {});
      }
      if (this.QQ.gameName == '') {
        this.$axios
          .post(
            'cohort/country_data/',
            {
              project: this.list1,
              platform: ['all', 'ios', 'android']
            },
            {
              headers: {
                Authorization: 'JWT ' + localStorage.getItem('jwt-token')
              }
            }
          )
          .then((res) => {
            this.list3 = res.data.country_data;
          })
          .catch((e) => {});
      }
      this.getJH();
      this.getSJ();
      this.getVer();
    },
    selectPT(val) {
      this.getSJ();
      this.getVer();
      //如果项目选择框为空，赋予list1，相当于全选
      if (val.length === ['all', 'ios', 'android']) {
        this.checked2 = true;
      } else this.checked2 = false;
      if (this.QQ.phone != '' && this.QQ.phone != '') {
        this.$axios
          .post(
            'cohort/country_data/',
            {
              project: this.QQ.gameName,
              platform: this.QQ.phone
            },
            {
              headers: {
                Authorization: 'JWT ' + localStorage.getItem('jwt-token')
              }
            }
          )
          .then((res) => {
            this.list3 = res.data.country_data;
          })
          .catch((e) => {});
      }
      if (this.QQ.gameName == '' && this.QQ.phone != '') {
        this.$axios
          .post(
            'cohort/country_data/',
            {
              project: this.list1,
              platform: this.QQ.phone
            },
            {
              headers: {
                Authorization: 'JWT ' + localStorage.getItem('jwt-token')
              }
            }
          )
          .then((res) => {
            this.list3 = res.data.country_data;
          })
          .catch((e) => {});
      }
      if (this.QQ.gameName != '' && this.QQ.phone == '') {
        this.$axios
          .post(
            'cohort/country_data/',
            {
              project: this.QQ.gameName,
              platform: ['all', 'ios', 'android']
            },
            {
              headers: {
                Authorization: 'JWT ' + localStorage.getItem('jwt-token')
              }
            }
          )
          .then((res) => {
            this.list3 = res.data.country_data;
          })
          .catch((e) => {});
      }
      if (this.QQ.gameName == '' && this.QQ.phone == '') {
        this.$axios
          .post(
            'cohort/country_data/',
            {
              project: this.list1,
              platform: ['all', 'ios', 'android']
            },
            {
              headers: {
                Authorization: 'JWT ' + localStorage.getItem('jwt-token')
              }
            }
          )
          .then((res) => {
            this.list3 = res.data.country_data;
          })
          .catch((e) => {});
      }

      /* this.getMT() */
      /* this.getTF() */
      this.getJH();
    },
    selectGZ(val) {
      //如果项目选择框为空，赋予list1，相当于全选
      if (val.length === this.list3) {
        this.checked3 = true;
      } else this.checked3 = false;

      /* this.getMT() */
      /* this.getTF() */
      this.getJH();
    },
    selectMT() {
      /* this.getTF() */
      this.getJH();
    },
    selectTF() {
      this.getJH();
    },
    //获取游戏名
    getXM() {
      this.$http
        .get('cohort/project_data/', {
          headers: {
            Authorization: 'JWT ' + localStorage.getItem('jwt-token')
          }
        })
        .then(function (res) {
          if (res.data.code == 200) {
            this.list1 = res.data.project_data;
            this.list1.unshift('all');
          }
        })
        .catch(function (err) {
          console.log(err);
        });
    },
    //获取所有国家信息
    getGZ() {
      this.$http
        .get('cohort/project_data/', {
          headers: {
            Authorization: 'JWT ' + localStorage.getItem('jwt-token')
          }
        })
        .then(function (res) {
          if (res.data.code == 200) {
            this.list1 = res.data.project_data;
            this.list1.unshift('all');
            this.$axios
              .post(
                'cohort/country_data/',
                {
                  project: this.list1,
                  platform: ['all', 'ios', 'android']
                },
                {
                  headers: {
                    Authorization: 'JWT ' + localStorage.getItem('jwt-token')
                  }
                }
              )
              .then((res) => {
                this.list3 = res.data.country_data;
              })
              .catch((e) => {});
          }
        })
        .catch(function (err) {
          console.log(err);
        });
    },
    //获取版本信息
    getVer() {
      this.$axios
        .post(
          'cohort/version_data/',
          {
            project: this.QQ.gameName,
            platform: this.QQ.phone,
          },
          {
            headers: {
              Authorization: 'JWT ' + localStorage.getItem('jwt-token')
            }
          }
        )
        .then((res) => {
          this.version_data = res.data.version_data;
          this.QQ2.version = [];
        })
        .catch((e) => {});
    },

    getMT() {
      this.$axios
        .post(
          'mar_consume/media_data/',
          {
            project: this.QQ.gameName,
            platform: this.QQ.phone,
            country: this.QQ.country
          },
          {
            headers: {
              Authorization: 'JWT ' + localStorage.getItem('jwt-token')
            }
          }
        )
        .then((res) => {
          this.list5 = res.data.media_data;
        })
        .catch((e) => {});
    },
    getTF() {
      this.$axios
        .post(
          'mar_consume/account_data/',
          {
            project: this.QQ.gameName,
            platform: this.QQ.phone,
            country: this.QQ.country,
            media: this.QQ2.mt
          },
          {
            headers: {
              Authorization: 'JWT ' + localStorage.getItem('jwt-token')
            }
          }
        )
        .then((res) => {
          this.list6 = res.data.account_data;
        })
        .catch((e) => {});
    },
    getJH() {
      this.$axios
        .post(
          'cohort/plan_data/',
          {
            project: this.QQ.gameName,
            platform: this.QQ.phone,
            country: this.QQ.country,
            date_range: this.date
          },
          {
            headers: {
              Authorization: 'JWT ' + localStorage.getItem('jwt-token')
            }
          }
        )
        .then((res) => {
          this.list7 = res.data.campaign_data;
        })
        .catch((e) => {});
    },
    getSJ() {
      this.$axios
        .post(
          'cohort/event_data/',
          {
            project: this.QQ.gameName,
            platform: this.QQ.phone
          },
          {
            headers: {
              Authorization: 'JWT ' + localStorage.getItem('jwt-token')
            }
          }
        )
        .then((res) => {
          this.list9 = res.data.event_name;
        })
        .catch((e) => {});
    },
    // 获取之前的日期的函数
    getBeforeDate(n) {
          let _n = n;
          let _d = new Date();
          let _year = _d.getFullYear();
          let _mon = _d.getMonth() + 1;
          let _day = _d.getDate();
          if(_day <= _n) {
              if(_mon > 1) {
                  _mon = _mon - 1;
              } else {
                  _year = _year - 1;
                  _mon = 12;
              }
          }
          _d.setDate(_d.getDate() - n);
          _year = _d.getFullYear();
          _mon = _d.getMonth() + 1;
          _day = _d.getDate();
          var date_sub = _year + "-" + (_mon < 10 ? ('0' + _mon) : _mon) + "-" + (_day < 10 ? ('0' + _day) : _day);
          return date_sub;
      },
  },

  mounted() {
    let timeStamp = new Date();
    let year = new Date(timeStamp).getFullYear();
    let month = new Date(timeStamp).getMonth() + 1 < 10 ? '0' + (new Date(timeStamp).getMonth() + 1) : new Date(timeStamp).getMonth() + 1;
    let month1 = new Date(timeStamp).getMonth() < 10 ? '0' + new Date(timeStamp).getMonth() : new Date(timeStamp).getMonth();
    let date = new Date(timeStamp).getDate() < 10 ? '0' + new Date(timeStamp).getDate() : new Date(timeStamp).getDate();
    let date_sub = this.getBeforeDate(30);
    this.date = [date_sub, year + '-' + month + '-' + date];
  },

  watch: {
    inputXM() {
      var newlist1 = [];
      this.list1.filter((item) => {
        if (item.toUpperCase().includes(this.inputXM.toUpperCase())) {
          newlist1.push(item);
        }
      });
      this.list1 = newlist1;
      if (this.inputXM == '') {
        this.getXM();
      }
    },
    inputGZ() {
      var newlist3 = [];
      this.list3.filter((item) => {
        if (item.toUpperCase().includes(this.inputGZ.toUpperCase())) {
          newlist3.push(item);
        }
      });
      this.list3 = newlist3;
      if (this.inputGZ == '') {
        this.getGZ();
      }
    },
    inputMT() {
      var newlist5 = [];
      this.list5.filter((item) => {
        if (item.toUpperCase().includes(this.inputMT.toUpperCase())) {
          newlist5.push(item);
        }
      });
      this.list5 = newlist5;
      if (this.inputMT == '') {
        /* this.getMT() */
      }
    },
    inputTF() {
      var newlist6 = [];
      this.list6.filter((item) => {
        if (item.toUpperCase().includes(this.inputTF.toUpperCase())) {
          newlist6.push(item);
        }
      });
      this.list6 = newlist6;
      if (this.inputTF == '') {
        /* this.getTF() */
      }
    },
    inputJH() {
      var newlist7 = [];
      this.list7.filter((item) => {
        if (item.toUpperCase().includes(this.inputJH.toUpperCase())) {
          newlist7.push(item);
        }
      });
      this.list7 = newlist7;
      if (this.inputJH == '') {
        this.getJH();
      }
    }
  }
};
</script>
<style>
.el-tag .el-icon-close {
  float: right;
  margin-top: 4px;
}
.el-tag {
  background: white;
  width: 100%;
}
.el-table .cell,
.el-table th > .cell {
  white-space: nowrap;
  width: fit-content;
}
.el-select__tags {
  flex-wrap: nowrap;
  overflow: hidden;
}
.el-tag.el-tag--info .el-tag__close {
  display: none;
}
.el-tag.el-tag--info {
  border: none;
  background: white;
}
.el-select__tags-text {
  margin-left: 0px;
}
.el-date-editor--daterange.el-input__inner {
  width: 240px;
}
.el-picker-panel {
  margin-left: 240px;
}
</style>
<style scoped>
.table {
  width: 100%;
  font-size: 14px;
}
</style>