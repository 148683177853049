<!--评论模块-->
<template>
  <div class="container" style="min-height:500px">
    <el-checkbox-group @change="checkChange" v-model="checkList">
    <div class="comment" :key="item.id" v-for="item in comments">
      <div class="info">
        <!-- <img class="avatar" :src="item.fromAvatar" width="36" height="36"/> -->
        <el-checkbox v-if="!item.developer_comment" :label="item.review_id+'||'+item.app_id+'||'+item.app_status"><br></el-checkbox>
        <img class="avatar" src="../../assets/img/imgicon.png" width="36" height="36"/>
        <div class="right">
          <div class="name">{{item.user_name}}</div>
          <div class="date">
            <div :key="i" v-for="(u,i) in item.star_rating" style="display: inline-block;">
              <i v-if="u==='1'" class="el-icon-star-on" style="font-size:16px"></i>
              <i v-if="u==='0'" class="el-icon-star-off" style="font-size:14px"></i>
            </div>
            {{item.comment_date}}
          </div>
        </div>
      </div>
      <div class="content">{{item.user_comment}}</div>
      <div class="control">
        <span class="comment-reply" v-if="item.project_name">
          <i class="iconfont icon-comment"></i>
          <span>应用名 {{item.project_name}}</span>
        </span>
        <span class="comment-reply" v-if="item.app_version">
          <i class="iconfont icon-comment"></i>
          <span>应用版本 {{item.app_version}}</span>
        </span>
        <span class="comment-reply" v-if="item.app_status">
          <i class="iconfont icon-comment"></i>
          <span>应用状态 {{item.app_status}}</span>
        </span>
        <span class="comment-reply" v-if="item.language">
          <i class="iconfont icon-comment"></i>
          <span>语言 {{item.language}}</span>
        </span>
        <span class="comment-reply" @click="showCommentInput(item)" v-if="!item.developer_comment">
          <i class="iconfont icon-comment"></i>
          <span style="color:#409EFF">回复</span>
        </span>
      </div>
      <div class="reply">
        <div class="item" v-if="item.developer_comment!=''">
          <!-- <img class="avatar" src="../../assets/img/download.png" width="36" height="36"/> -->
          <div class="reply-content">
            <span class="from-name">{{item.developer_name}}</span><span>: </span>
            <span>{{item.developer_comment}}</span>
          </div>
          <div class="reply-bottom">
            <span>{{item.developer_date}}</span>
          </div>
        </div>

        <transition name="fade">
          <div class="input-wrapper" v-if="showItemId === item.review_id">
            <el-input class="gray-bg-input"
                      v-model="inputComment"
                      type="textarea"
                      :rows="3"
                      autofocus
                      placeholder="写下你的评论">
            </el-input>
            <div class="btn-control">
              <span class="cancel" @click="cancel">取消</span>
              <el-button class="btn" type="success" round @click="commitComment">确定</el-button>
            </div>
          </div>
        </transition>
      </div>
    </div>
    </el-checkbox-group>
  </div>
</template>

<script>

  import Vue from 'vue'

  export default {
    props: {
      comments: {
        type: Array,
        required: true
      },
    },
    components: {},
    data() {
      return {
        inputComment: '',
        showItemId: '',
        appStatus:'',
        appId:'',
        checkList:[],
      }
    },
    computed: {},
    methods: {
      checkChange(checkVal){
        this.checkList = checkVal;
        this.$emit('selectComment',this.checkList);
        // console.log(this.checkList);
        // console.log(checkVal);
      },
      /**
       * 点赞
       */
      likeClick(item) {
        if (item.isLike === null) {
          Vue.$set(item, "isLike", true);
          item.likeNum++
        } else {
          if (item.isLike) {
            item.likeNum--
          } else {
            item.likeNum++
          }
          item.isLike = !item.isLike;
        }
      },

      /**
       * 点击取消按钮
       */
      cancel() {
        this.showItemId = '';
        this.appStatus = '';
        this.appId = '';
      },

      /**
       * 提交评论
       */
      commitComment() {
        console.log(this.inputComment);
        this.$emit('commitComment',this.inputComment,this.showItemId,this.appStatus,this.appId);
      },

      /**
       * 点击评论按钮显示输入框
       * item: 当前大评论
       * reply: 当前回复的评论
       */
      showCommentInput(item) {
        this.showItemId = item.review_id;
        this.appStatus = item.app_status;
        this.appId = item.app_id;
        // console.log(this.showItemId);
      }
    },
  }
</script>

<style scoped lang="scss">

  @import "../../../public/index";

  .container {
    padding: 0 10px;
    box-sizing: border-box;
    .comment {
      display: flex;
      flex-direction: column;
      padding: 10px;
      border-bottom: 1px solid $border-fourth;
      .info {
        display: flex;
        align-items: center;
        .avatar {
          border-radius: 50%;
        }
        .right {
          display: flex;
          flex-direction: column;
          margin-left: 10px;
          .name {
            font-size: 16px;
            color: $text-main;
            margin-bottom: 5px;
            font-weight: 500;
          }
          .date {
            font-size: 14px;
            color: $text-minor;
          }
        }
      }
      .content {
        font-size: 16px;
        color: $text-main;
        line-height: 20px;
        padding: 10px 0;
      }
      .control {
        display: flex;
        align-items: center;
        font-size: 14px;
        color: $text-minor;
        .like {
          display: flex;
          align-items: center;
          margin-right: 20px;
          cursor: pointer;
          &.active, &:hover {
            color: $color-main;
          }
          .iconfont {
            font-size: 14px;
            margin-right: 5px;
          }
        }
        .comment-reply {
          display: flex;
          align-items: center;
          cursor: pointer;
          &:hover {
            color: $text-333;
          }
          .iconfont {
            font-size: 16px;
            margin-right: 5px;
          }
        }

      }
      .reply {
        margin: 10px 0;
        border-left: 2px solid $border-first;
        .item {
          margin: 0 10px;
          padding: 10px 0;
          border-bottom: 1px dashed $border-third;
          .reply-content {
            display: flex;
            align-items: center;
            font-size: 14px;
            color: $text-main;
            .from-name {
              color: $color-main;
            }
            .to-name {
              color: $color-main;
              margin-left: 5px;
              margin-right: 5px;
            }
          }
          .reply-bottom {
            display: flex;
            align-items: center;
            margin-top: 6px;
            font-size: 12px;
            color: $text-minor;
            .reply-text {
              display: flex;
              align-items: center;
              margin-left: 10px;
              cursor: pointer;
              &:hover {
                color: $text-333;
              }
              .icon-comment {
                margin-right: 5px;
              }
            }
          }
        }
        .write-reply {
          display: flex;
          align-items: center;
          font-size: 14px;
          color: $text-minor;
          padding: 10px;
          cursor: pointer;
          &:hover {
            color: $text-main;
          }
          .el-icon-edit {
            margin-right: 5px;
          }
        }
        .fade-enter-active, fade-leave-active {
          transition: opacity 0.5s;
        }
        .fade-enter, .fade-leave-to {
          opacity: 0;
        }
        .input-wrapper {
          padding: 10px;
          .gray-bg-input, .el-input__inner {
            /*background-color: #67C23A;*/
          }
          .btn-control {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding-top: 10px;
            .cancel {
              font-size: 16px;
              color: $text-normal;
              margin-right: 20px;
              cursor: pointer;
              &:hover {
                color: $text-333;
              }
            }
            .confirm {
              font-size: 16px;
            }
          }
        }
      }
    }
  }
</style>
