<template>
  <div>
    <div v-if="user_group.indexOf('产品组') == -1">
      <cw403></cw403>
    </div>
    <!-- 判断是否有页面限制，如果有，则必须包含该页 -->
    <div v-else-if="page_list.length > 0 && page_list.indexOf('用户行为路径') == -1">
      <cw403></cw403>
    </div>
    <div v-else>
      <div class="crumbs">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item style="font-size: 16px">
            用户行为路径
          </el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="container">
        <div class="options-bar">
          <span style="color:gray;margin-right:30px;">筛选维度</span>
          <date-picker
            style="margin-right: 10px"
            v-model="date"
          >
          </date-picker>
          <ElSelectProjectSingle style="margin-right: 10px; width: 180px" ref="childProject" @ProjectChange='ProjectChange($event)'></ElSelectProjectSingle>
            <el-select v-model="Platform" @change="PlatformChange($event)" filterable placeholder="平台" style="margin-right: 10px;width: 180px">
                <el-option
                v-for="item in PlatformList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
                </el-option>
            </el-select>

          <el-select
            v-model="DateType"
            placeholder="时间类型(默认event_time)"
            collapse-tags
            style="margin-right: 10px; width: 180px"
          >
            <el-option v-for="item in DateTypeList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
          </el-select>
          
          <el-button
            type="primary"
            plain
            icon="el-icon-search"
            @click="handleSearch(50,1)"
            style="margin-bottom: 10px; margin-top: 10px; margin-right: 10px"
            :disabled="kaiguan"
            >搜索</el-button
          >
          <el-button v-if="fanhui" plain type="primary" icon="el-icon-search" @click="fanhuiss" style="margin-left: 0px">返回</el-button>
        </div>
        <div class="options-bar" style="margin-left: 95px">
          <ElSelectCountry style="margin-right: 10px; width: 240px" ref="childCountry" @CountryChange='CountryChange($event)'></ElSelectCountry>
        </div>
        <div class="options-bar">
          <span style="16px;color:gray;margin-right:30px;float:left;">快速检索</span>
          <el-input
          v-model="searchQuery"
          placeholder="空格隔开筛选项"
          clearable
          @input="searchInput()"
          style="width:240px;margin-right:10px;"
          ></el-input>
          <el-button
            @click="downCsv"
            style="margin-left: auto"
            icon="el-icon-s-release"
            type="info"
            plain
          >导出csv</el-button>
        </div>
        <el-table
          v-loading="pictLoading"
          element-loading-text="数据正在加载中"
          :data="tableSearchList"
          @row-click="openDetails($event)"
          height="550"
          border
          class="table"
          ref="multipleTable"
          @sort-change="sortChange"
          header-cell-class-name="table-header"
          :header-cell-style="{ color: '#696969' }"
        >

          <ex-table-column
            :min-width="120"
            :autoFit="true"
            :fitHeader="true"
            sortable="custom"
            v-for="item in tableColumns1"
            :key="item"
            :prop="item"
            fixed
            :label="item"
          />

          <ex-table-column
            :autoFit="true"
            :fitHeader="true"
            sortable="custom"
            v-for="item in tableColumns2"
            :key="item"
            :prop="item"
            :label="item"
          />
        </el-table>
        <!-- <pagination
          @size-change="handleSizeChange"
          :current-page.sync="pageInfo.current"
          :page-size.sync="pageInfo.size"
          :total="pageInfo.total"
        ></pagination> -->

        <pagination @size-change="handleSizeChange" 
                    @current-change="handleCurrentChange" 
                    :current-page="currentPage" 
                    :page-sizes="pageSizes" 
                    :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" 
                    :total="totalCount">
        </pagination>
      </div>
      <div class="container" id="user_route" style="margin-top:50px">
        <div class="options-bar">
          <span style="color:gray;margin-right:30px;">筛选维度</span>
            <date-picker
                style="margin-right: 10px;width: 300px"
                v-model="date"
            >
            </date-picker>
            <el-select v-model="UserProject" filterable placeholder="项目" style="margin-right: 10px;">
                <el-option
                v-for="item in UserProjectList"
                :key="item"
                :label="item"
                :value="item">
                </el-option>
            </el-select>
            <el-select v-model="UserPlatform" filterable placeholder="平台" style="margin-right: 10px;">
                <el-option
                v-for="item in UserPlatformList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
                </el-option>
            </el-select>
        </div>
        <div class="options-bar" style="margin-left: 95px">
            <el-input
            v-model="UserIdQuery"
            placeholder="用户id"
            clearable
            style="width:300px;margin-right:10px;"
            ></el-input>
          <el-button
            type="primary"
            plain
            icon="el-icon-search"
            @click="UserRouteSearch()"
            style="margin-bottom: 10px; margin-top: 10px; margin-right: 10px"
            :disabled="kaiguan"
            >搜索</el-button>
        </div>
        <el-table
            v-loading="userLoading"
            element-loading-text="数据正在加载中"
            :data="UserRouteTableData.slice((pageInfo.current-1)*pageInfo.size,pageInfo.current*pageInfo.size)"
            :default-sort = "{prop: 'id', order: 'descending'}"
            class="table"
            style="width: 100%">
            <el-table-column type="expand">
            <template slot-scope="props">
                <el-form label-position="left" inline class="demo-table-expand">
                <el-form-item label="事件名称">
                    <span>{{ props.row.event_name }}</span>
                </el-form-item>
                <el-form-item label="事件时间">
                    <span>{{ props.row.event_time }}</span>
                </el-form-item>
                <el-form-item label="用户ID">
                    <span>{{ props.row.user_id }}</span>
                </el-form-item>
                <el-form-item label="用户国家">
                    <span>{{ props.row.country_code }}</span>
                </el-form-item>
                <el-form-item label="app版本">
                    <span>{{ props.row.app_version }}</span>
                </el-form-item>
                <el-form-item label="appId">
                    <span>{{ props.row.app_id }}</span>
                </el-form-item>
                <el-form-item label="事件描述">
                    <span>{{ props.row.description }}</span>
                </el-form-item>
                <el-form-item label="安装时间">
                    <span>{{ props.row.install_time }}</span>
                </el-form-item>
                <el-form-item label="事件值">
                    <span>{{ props.row.event_value }}</span>
                </el-form-item>
                <el-form-item label="来源">
                    <span>{{ props.row.campaign }}</span>
                </el-form-item>
                </el-form>
            </template>
            </el-table-column>
            <el-table-column
            label="事件时间"
            sortable
            prop="event_time">
            </el-table-column>
            <el-table-column
            label="事件名称"
            prop="event_name">
            </el-table-column>
            <el-table-column
            label="描述"
            prop="description">
            </el-table-column>
        </el-table>

        <pagination
          @size-change="UserhandleSizeChange"
          :current-page.sync="pageInfo.current"
          :page-sizes="pageSizes" 
          :page-size="pageInfo.size" layout="total, sizes, prev, pager, next, jumper" 
          :total="pageInfo.total"
        ></pagination>
      </div>

    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import echarts from 'echarts';
import { fetchData } from '../../api/index';
import json2csv from 'json2csv';
import cw403 from './403.vue';
import ElSelectPlatform from '../SelectComponent/select_platform.vue';
// import ElSelectProject from '../SelectComponent/select_project.vue';
import ElSelectProjectSingle from '../SelectComponent/select_project_single.vue';
import ElSelectCountry from '../SelectComponent/select_country.vue';
export default {
  name: 'product-table-user',
  components: {
    cw403,
    ElSelectPlatform,
    ElSelectProjectSingle,
    ElSelectCountry
  },
  data() {
    return {
      UserRouteTableData:[],  
      kaiguan: false,
      DateType:'event_time',
      fieldOrder:'',
      fieldValue:'',
      searchQuery:'',
      searchTimer:null,
      DateTypeList: [
        { label: '事件时间', value: 'event_time' },
        { label: '安装时间', value: 'install_time' }
      ],
      UserIdQuery:'',
      PlatformList : [{
          value: 'android',
          label: 'Android'
        }, {
          value: 'ios',
          label: 'iOS'
        }],
      Platform:'',
      UserPlatformList : [{
          value: 'android',
          label: 'Android'
        }, {
          value: 'ios',
          label: 'iOS'
        }],
      UserPlatform:'',
      UserProjectList:[],
      UserProject:'',
      user_group: localStorage.getItem('user-group'),
      page_list: localStorage.getItem('page_list'),
      checkList: ['日期'],
      tableColumns: [],
      tableColumns1: [],
      tableColumns2: [],
      //折线图参数
      time: [],
      tableSearchList: [],
      fanhui: false,
      pictLoading: false,
      userLoading: false,
      sjLoading: false,
      pageInfo: {
        size: 50,
        current: 1,
        total: 0,
      },
      // 默认显示第几页
      currentPage:1,
      // 总条数，根据接口获取数据长度(注意：这里不能为空)
      totalCount:1,
      // 个数选择器（可修改）
      pageSizes:[50,100,200,300,400],
      // 默认每页显示的条数（可修改）
      pageSize:50,
      date: [],
      type: false,
      tableData: [],
      lastquery: '',
      multipleSelection: [],
      delList: [],
      editVisible: false,
      pageTotal: 0,
      form: {},
      idx: -1,
      id: -1,
    };
  },
  created() {
    // 初始请求，请求项目
    this.getProject();
    this.PageCount();
    // this.getAdsource();
    // this.getCountry();
  },
  methods: {
    ProjectChange(e){
        this.UserProject = this.$refs.childProject.select_project;
        this.getCountry();
    //   this.getMedia();
    //   this.getCountry();
    },
    PlatformChange(e){
        this.UserPlatform = this.Platform;
      // 改变值，请求国家选择框的值
    //   this.getCountry();
    //   this.getMedia();
    },
    MediaChange(e){
      // 改变值，请求国家选择框的值
    //   this.getCampaign();
    },
    CountryChange(e){
      // 当改变值的时候，请求下个选项框的值，如果此选择框没有联动的下个选项框就为空就可以
      // console.log('接受');
      // console.log(e);
    },
    CampaignChange(e){
      // 当改变值的时候，请求下个选项框的值，如果此选择框没有联动的下个选项框就为空就可以
      // console.log('接受');
      // console.log(e);
    },
    AdFormatChange(e){
      // 
    },
    //checkbox-group回调
    demo(val) {
      this.checkList = val;
    },
    //返回按钮
    fanhuiss() {
      this.fanhui = false;
      this.tableData = [];
    },  
    // 表格排序
    sortChange (column, prop, order){
      // console.log(column.prop); //prop标签 => 字段
      // console.log(column.order);//descending降序、ascending升序
      this.fieldOrder = column.order;
      this.fieldValue = column.prop;
      if(column.order!=null && column.prop!='user_id' ){
        this.handleSearch(this.pageSize,this.currentPage);
      }
    },
    // 搜索请求
    searchInput(){
      // console.log('1');
      // console.log(this.query);
      if(this.searchQuery!=''){
        var that = this;
        clearTimeout(that.searchTimer);
        // 延时1s后检索
        that.searchTimer = setTimeout(function () { 
          // console.log("在这里停顿");
          that.handleSearch(that.pageSize,that.currentPage);
        },1000)
      }
    },
    // 检索
    // SearchInput(tableList){
    //   this.tableSearchList = tableList;
    //   this.pageInfo.total = tableList.length;
    // },
    // 表格行点击
    openDetails(e){
    //   console.log('点了');
      // console.log(e.user_id);
      document.getElementById("user_route").scrollIntoView();
      this.UserIdQuery = e.user_id;
      this.UserRouteSearch()
    },
    // 触发搜索按钮
    handleSearch(n1,n2) {
      // console.log(n1)  
      // console.log(n2)  
      if ([this.$refs.childProject.select_project] == '') {
        this.$notify({ title: '提示', message: '请填写项目', type: 'warning' });
      }
      if (
        [this.$refs.childProject.select_project] != ''
      ) {
        this.fanhui = true;
        this.pictLoading = true;
        //post请求获取搜索内容
        this.$axios
          .post(
            'UserRoute/info/',
            {
              date_range: this.date,
              project: [this.$refs.childProject.select_project],
              country: this.$refs.childCountry.select_country,
              platform: [this.Platform],
              // 排序
              field_order:this.fieldOrder,
              field_value:this.fieldValue,
              // 搜索
              searchQuery:this.searchQuery,
              // 时间类型
              date_type: this.DateType,   
              // 每页显示的条数
              pageSize:n1,
              // 显示第几页
              currentPage:n2,
            },
            {
              headers: {
                Authorization: 'JWT ' + localStorage.getItem('jwt-token')
              }
            }
          )
          .then((res) => {
              
            this.tableData = res.data.list;
            // this.pageInfo.total = res.data.list.length;
            // this.totalCount = res.data.list.length;
            this.totalCount = res.data.total;
            this.tableColumns = [];
            for (var pro in res.data.list[0]) {
              this.tableColumns.push(pro);
            }
            this.tableColumns1 = this.tableColumns.splice(0, res.data.fix_columns);
            this.tableColumns2 = this.tableColumns;
            this.$nextTick(() => {
              this.$refs.multipleTable.doLayout();
              // el-table加ref="multipleTable"
            });
            this.pictLoading = false;
            this.tableSearchList = res.data.list;
            // 搜索需要经过子组件里的检索函数
            // this.$refs.searchInput.searchTable();
          })
          .catch((e) => {});
      }
    },
    // 用户路径查询
    UserRouteSearch(){
      if (
        [this.UserProject] != ''
      ) {
        this.userLoading = true;
        //post请求获取搜索内容
        this.$axios
          .post(
            'UserRoute/event_info/',
            {
              date_range: this.date,
              project: [this.UserProject],
              platform: [this.UserPlatform],
              user_id: [this.UserIdQuery],
            },
            {
              headers: {
                Authorization: 'JWT ' + localStorage.getItem('jwt-token')
              }
            }
          )
          .then((res) => {
              
            this.UserRouteTableData = res.data.list;
            this.pageInfo.total = res.data.total;
            this.userLoading = false;
            // this.totalCount = res.data.list.length;
            // this.totalCount = res.data.total;

          })
          .catch((e) => {});
      }
    },
    UserhandleSizeChange(val) {
      this.pageInfo.current = 1;
      this.pageInfo.size = val;
    },
    // 每页显示的条数
    handleSizeChange(val) {
        // 改变每页显示的条数 
        this.pageSize=val
        // 点击每页显示的条数时，显示第一页
        this.handleSearch(val,1)
        // 注意：在改变每页显示的条数时，要将页码显示到第一页
        this.currentPage=1  
    },
    // 显示第几页
    handleCurrentChange(val) {
        // console.log('改变页数')
        // 改变默认的页数
        this.currentPage=val
        // 切换页码时，要获取每页显示的条数
        // this.handleSearch(this.PageSize,(val)*(this.PageSize))
        this.handleSearch(this.pageSize,val)
    },
    //导出csv文件
    downloadCsv(url, options = {}){
        return new Promise((resolve, reject) => {
        // console.log(`${url} 请求数据，参数=>`, JSON.stringify(options))
        // axios.defaults.headers['content-type'] = 'application/json;charset=UTF-8'
        this.$axios.post
        (
          'UserRoute/download/',
          {
              date_range: this.date,
              project: [this.$refs.childProject.select_project],
              country: this.$refs.childCountry.select_country,
              platform: [this.Platform],
              // 排序
              field_order:this.fieldOrder,
              field_value:this.fieldValue,
              // 搜索
              searchQuery:this.searchQuery,
              // 时间类型
              date_type: this.DateType,
          },
          {
            headers: {
              Authorization: 'JWT ' + localStorage.getItem('jwt-token')
            }
          }
        ).then(
        response => {
          // console.log("下载响应",response)
          resolve(response.data)
          let blob = new Blob([response.data], {
          type: 'application/vnd.ms-excel'
          })
          // console.log(blob)
          // let fileName = Date.parse(new Date()) + '.xlsx'
          // 切割出文件名
          let fileNameEncode = response.headers['content-disposition'].split("filename=")[1];
          // 解码
          let fileName = decodeURIComponent(fileNameEncode)
          // console.log("fileName",fileName)
          if (window.navigator.msSaveOrOpenBlob) {
          // console.log(2)
          navigator.msSaveBlob(blob, fileName)
          } else {
          // console.log(3)
          var link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = fileName
          link.click()
          //释放内存
          window.URL.revokeObjectURL(link.href)
          }
        },
        err => {
          reject(err)
        }
        )
        })
    },
    // 下载文件
    downCsv(){
        let postUrl= "UserRoute/download/"
        let params = {
        filename: "test.xlsx",
        }
        // console.log("下载参数",params)
        this.downloadCsv(postUrl,params)
    },
    //获取游戏名
    getProject() {
      this.$http
        .get('UserRoute/project_data/', {
          headers: {
            Authorization: 'JWT ' + localStorage.getItem('jwt-token')
          }
        })
        .then(function (res) {
          if (res.data.code == 200) {
            this.$refs.childProject.project_list = res.data.project_data;
            this.UserProjectList = res.data.project_data;
            // this.list1.unshift('all');
          }
        })
        .catch(function (err) {
          console.log(err);
        });
    },
    // 获取国家
    getCountry() {
      this.$axios
        .post(
          'UserRoute/country_data/',
          {
            project: [this.$refs.childProject.select_project],
            platform: [this.Platform],
          },
          {
            headers: {
              Authorization: 'JWT ' + localStorage.getItem('jwt-token')
            }
          }
        )
        .then((res) => {
          this.$refs.childCountry.country_list = res.data.country_data;
        })
        .catch((e) => {});
    },
    // 访问
    PageCount() {
      this.$http
        .post('index/count/', 
        {
          page_name:"用户行为路径"
        },
        {
          headers: {
            Authorization: 'JWT ' + localStorage.getItem('jwt-token')
          }
        })
        .then(function (res) {
          if (res.data.code == 200) {
            // this.$refs.childProject.project_list = res.data.project_data;
            // this.list1.unshift('all');
          }
        })
        .catch(function (err) {
          console.log('Auth Error');
        if (localStorage.getItem("jwt-token") != null){
          localStorage.clear();
          window.location.href="/#/login";
        } 
        });
    },
    // 获取之前的日期的函数
    getBeforeDate(n) {
          let _n = n;
          let _d = new Date();
          let _year = _d.getFullYear();
          let _mon = _d.getMonth() + 1;
          let _day = _d.getDate();
          if(_day <= _n) {
              if(_mon > 1) {
                  _mon = _mon - 1;
              } else {
                  _year = _year - 1;
                  _mon = 12;
              }
          }
          _d.setDate(_d.getDate() - n);
          _year = _d.getFullYear();
          _mon = _d.getMonth() + 1;
          _day = _d.getDate();
          var date_sub = _year + "-" + (_mon < 10 ? ('0' + _mon) : _mon) + "-" + (_day < 10 ? ('0' + _day) : _day);
          return date_sub;
      },
  },

  mounted() {
    let timeStamp = new Date();
    let year = new Date(timeStamp).getFullYear();
    let month = new Date(timeStamp).getMonth() + 1 < 10 ? '0' + (new Date(timeStamp).getMonth() + 1) : new Date(timeStamp).getMonth() + 1;
    let month1 = new Date(timeStamp).getMonth() < 10 ? '0' + new Date(timeStamp).getMonth() : new Date(timeStamp).getMonth();
    let date = new Date(timeStamp).getDate() < 10 ? '0' + new Date(timeStamp).getDate() : new Date(timeStamp).getDate();
    // 初始日期间隔
    let date_sub = this.getBeforeDate(1);
    this.date = [date_sub, year + '-' + month + '-' + date];
  },

};
</script>
<style>
.el-tag .el-icon-close {
  float: right;
  margin-top: 4px;
}
.el-tag {
  background: white;
  width: 100%;
}
.el-table .cell,
.el-table th > .cell {
  white-space: nowrap;
  width: fit-content;
}
.el-select__tags {
  flex-wrap: nowrap;
  overflow: hidden;
}
.el-tag.el-tag--info .el-tag__close {
  display: none;
}
.el-tag.el-tag--info {
  border: none;
  background: white;
}
.el-select__tags-text {
  margin-left: 0px;
}
.el-date-editor--daterange.el-input__inner {
  width: 240px;
}
.el-picker-panel {
  margin-left: 240px;
}
.demo-table-expand {
font-size: 0;
}
.demo-table-expand label {
width: 90px;
color: #99a9bf;
}
.demo-table-expand .el-form-item {
margin-right: 0;
margin-bottom: 0;
width: 50%;
}
</style>
<style scoped>
.table {
  width: 100%;
  font-size: 14px;
}
</style>