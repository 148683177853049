<template>
          <el-select
            v-model="select_value"
            @change="changeValueChild"
            multiple
            placeholder="计划"
            collapse-tags
            style="margin-right: 10px; width: 180px"
          >
            <div style="float: left; overflow: hidden; height: 200px; overflow-y: auto; width: 200px">
              <el-input
                style="width: 90%; margin-left: 5%; margin-bottom: 5px"
                v-model="inputValue"
                @keyup.enter.native="enterValue"
                clearable
              ></el-input>
              <button @click="selectValue" style="height: 24px; width: 70px; margin-left: 13px; margin-bottom: 10px">全选</button>
              <button style="height: 24px; width: 70px; margin-left: 20px" @click="clearValue">清空</button>
              <el-option v-for="item in value_list" :key="item" :label="item" :value="item"></el-option>
              <el-option v-if="value_list == ''" label="无相关内容" value="无相关内容" :disabled="true"></el-option>
            </div>
            <div
              style="
                float: left;
                overflow: hidden;
                height: 200px;
                overflow-y: auto;
                padding: 10px;
                width: 150px;
                border-left: 1px #dee1e7 solid;
              "
            >
              <div style="height: 30px" v-for="item in select_value" :key="item">
                <el-tag closable @close="handleCloseValue(item)">{{ item }}</el-tag>
              </div>
            </div>
          </el-select>
</template>

<script>
export default {
    watch:{
        inputValue() {
            var _value_list = [];
            this.value_list.filter((item) => {
                if (item.toUpperCase().includes(this.inputValue.toUpperCase())) {
                _value_list.push(item);
                }
            });
            this.value_list = _value_list;
            if (this.inputValue == '') {
                this.$parent.getValue();
            }
        },
    },
  methods: {
    changeValueChild(){
          this.$emit('changeValue',this.select_value);
    },
    enterValue() {
      if (this.select_value.includes(this.value_list[0])) {
        console.log('已选中');
      } else this.select_value.push(this.value_list[0]);
      // this.ProjectChangeChild();
    },
    selectValue() {
      this.select_value = this.value_list;
      this.changeValueChild();
    },
    clearValue(){
      this.select_value = [];
      this.checked_value = false;
      this.changeValueChild();
    },

    handleCloseValue(tag) {
      this.select_value.splice(this.select_value.indexOf(tag), 1);
      this.changeValueChild();
    },
  },
 data() {
    return {
      inputValue:'',
      value_list: [],
      checked_value: false,
      select_value:[]
      
    };
  },
}
</script>
