<template>
        <el-select
            v-model="select_platform"
            @change="PlatformChangeChild"
            multiple
            placeholder="平台/platform"
            collapse-tags
            style="margin-right: 10px; width: 180px"
          >
            <el-option v-for="item in platform_list" :key="item.value" :label="item.label" :value="item.value"> </el-option>
        </el-select>
</template>

<script>
export default {
  methods: {
    PlatformChangeChild(){
          this.$emit('PlatformChange',this.select_platform);
    },
  },
 data() {
    return {
        platform_list: [
            { label: '所有平台', value: 'all' },
            { label: 'iOS', value: 'ios' },
            { label: 'Android', value: 'android' }
        ],
        select_platform:[]
      
    };
  },
}
</script>
