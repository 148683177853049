<template>
  <div class="order-container">
    <el-row>
      <el-col :span="20">
        <h1>线下订单信息录入</h1>
        <el-form @submit.prevent="submitOrder">
          <el-row>
            <el-col :span="6">
              <el-form-item label="日期 *">
                <el-date-picker v-model="order.date" type="date" required></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="项目 *">
                <el-select v-model="order.project" required>
                  <el-option v-for="project in projectList" :key="project" :label="project" :value="project"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="端 *">
                <el-select v-model="order.platform" required>
                  <el-option label="Android" value="Android"></el-option>
                  <el-option label="iOS" value="iOS"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="地区 *">
                <el-select v-model="order.region" required filterable>
                  <el-option v-for="country in countryList" :key="country" :label="country" :value="country"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="订单号 *">
                <el-input v-model="order.orderNumber" required></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="金额（美元） *">
                <el-input-number v-model="order.amount" required></el-input-number>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="付款方式 *">
                <el-select v-model="order.paymentMethod" required>
                  <el-option label="Paypal" value="Paypal"></el-option>
                  <el-option label="信用卡" value="信用卡"></el-option>
                  <el-option label="支付宝" value="支付宝"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="16">
              <el-form-item label="备注">
                <el-input v-model="order.remarks"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="4">
              <el-button type="primary" @click="submitOrder">提交订单</el-button>
            </el-col>
            <el-col :span="4">
              <el-button type="default" @click="refreshOrders">刷新历史订单列表</el-button>
            </el-col>
          </el-row>
        </el-form>
      </el-col>
      <el-col :span="24">
        <h1 style="padding-top: 3%;">历史线下订单列表</h1>
        <el-row>

          <el-col :span="4">
            项目
            <el-select v-model="search.project" placeholder="项目" filterable>
              <el-option v-for="project in filteredProjectList" :key="project" :label="project" :value="project"></el-option>
            </el-select>
          </el-col>
          <el-col :span="4">
            <el-select v-model="search.platform" placeholder="端" filterable>
              <el-option label="Android" value="Android"></el-option>
              <el-option label="iOS" value="iOS"></el-option>
            </el-select>
          </el-col>
          <el-col :span="4">
            <el-select v-model="search.region" placeholder="地区" filterable>
              <el-option v-for="country in countryList" :key="country" :label="country" :value="country"></el-option>
            </el-select>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            订单号
            <el-input v-model="search.orderNumber" placeholder="订单号"></el-input> <!-- 修改为输入框 -->
          </el-col>
          <el-col :span="6">
            备注
            <el-input v-model="search.remarks" placeholder="备注"></el-input>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="4">
            <el-button type="primary" @click="filterOrders">搜索</el-button>
            <el-button type="default" @click="clearSearch">清除搜索</el-button>
          </el-col>
        </el-row>
        <el-table :data="orders" style="width: 100%">
          <el-table-column prop="orderNumber" label="订单号" width="180"></el-table-column>
          <el-table-column prop="date" label="日期" width="180"></el-table-column>
          <el-table-column prop="project" label="项目" width="180"></el-table-column>
          <el-table-column prop="platform" label="端" width="120"></el-table-column>
          <el-table-column prop="region" label="地区" width="180"></el-table-column>
          <el-table-column prop="amount" label="金额（美元）" width="180"></el-table-column>
          <el-table-column prop="paymentMethod" label="付款方式" width="180"></el-table-column>
          <el-table-column prop="remarks" label="备注"></el-table-column>
          <el-table-column prop="is_valid" label="订单有效性" width="120">
            <template slot-scope="scope">
              <span>{{ scope.row.is_valid ? '有效' : '已删除' }}</span>
              <el-button v-if="scope.row.is_valid" @click="deleteOrder(scope.row)" type="danger" size="mini">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>

  </div>
</template>

<script>
import { f } from 'af-table-column';

export default {
  data() {
    return {
      order: {
        orderNumber: '', // 新增订单号字段
        date: new Date().toISOString().split('T')[0], // 设置默认日期为今天
        project: '',
        platform: '',
        region: '',
        amount: null,
        paymentMethod: 'Paypal', // 设置默认付款方式为Paypal
        remarks: '' // 新增备注字段
      },
      orders: [], // 存储历史订单
      countryList: [], // 存储国家列表
      projectList: [], // 新增项目列表
      filteredProjectList: [], // 新增过滤后的项目列表
      search: {
        orderNumber: '',
        project: '',
        platform: '',
        region: '',
        remarks: ''
      }
    };
  },
  created() {
    this.fetchCountryList(); // 页面初始化时获取国家列表
    this.getProjects(); // 页面初始化时获取项目列表
    this.refreshOrders(); // 页面初始化时获取订单信息
  },
  methods: {
    fetchCountryList() {
      // 从指定接口获取国家列表的逻辑
      this.countryList = ['AF', 'AX', 'AL', 'DZ', 'AS', 'AD', 'AO', 'AI', 'AQ', 'AG', 'AR', 'AM', 'AW', 'AU', 'AT', 'AZ', 'BS', 'BH', 'BD', 'BB', 'BY', 'BE', 'BZ', 'BJ', 'BM', 'BT', 'BO', 'BQ', 'BA', 'BW', 'BV', 'BR', 'IO', 'BN', 'BG', 'BF', 'BI', 'CV', 'KH', 'CM', 'CA', 'KY', 'CF', 'TD', 'CL', 'CN', 'CX', 'CC', 'CO', 'KM', 'CG', 'CD', 'CK', 'CR', 'CI', 'HR', 'CU', 'CW', 'CY', 'CZ', 'DK', 'DJ', 'DM', 'DO', 'EC', 'EG', 'SV', 'GQ', 'ER', 'EE', 'SZ', 'ET', 'FK', 'FO', 'FJ', 'FI', 'FR', 'GF', 'PF', 'TF', 'GA', 'GM', 'GE', 'DE', 'GH', 'GI', 'GR', 'GL', 'GD', 'GP', 'GU', 'GT', 'GG', 'GN', 'GW', 'GY', 'HT', 'HM', 'VA', 'HN', 'HK', 'HU', 'IS', 'IN', 'ID', 'IR', 'IQ', 'IE', 'IM', 'IL', 'IT', 'JM', 'JP', 'JE', 'JO', 'KZ', 'KE', 'KI', 'KP', 'KR', 'KW', 'KG', 'LA', 'LV', 'LB', 'LS', 'LR', 'LY', 'LI', 'LT', 'LU', 'MO', 'MG', 'MW', 'MY', 'MV', 'ML', 'MT', 'MH', 'MQ', 'MR', 'MU', 'YT', 'MX', 'FM', 'MD', 'MC', 'MN', 'ME', 'MS', 'MA', 'MZ', 'MM', 'NA', 'NR', 'NP', 'NL', 'NC', 'NZ', 'NI', 'NE', 'NG', 'NU', 'NF', 'MK', 'MP', 'NO', 'OM', 'PK', 'PW', 'PS', 'PA', 'PG', 'PY', 'PE', 'PH', 'PN', 'PL', 'PT', 'PR', 'QA', 'RE', 'RO', 'RU', 'RW', 'BL', 'SH', 'KN', 'LC', 'MF', 'PM', 'VC', 'WS', 'SM', 'ST', 'SA', 'SN', 'RS', 'SC', 'SL', 'SG', 'SX', 'SK', 'SI', 'SB', 'SO', 'ZA', 'GS', 'SS', 'ES', 'LK', 'SD', 'SR', 'SJ', 'SE', 'CH', 'SY', 'TW', 'TJ', 'TZ', 'TH', 'TL', 'TG', 'TK', 'TO', 'TT', 'TN', 'TR', 'TM', 'TC', 'TV', 'UG', 'UA', 'AE', 'GB', 'UK', 'US', 'UM', 'UY', 'UZ', 'VU', 'VE', 'VN', 'VG', 'VI', 'WF', 'EH', 'YE', 'ZM', 'ZW']; // 示例数据
    },
    submitOrder() {
      // 校验表单数据
      if (!this.order.date || !this.order.project || !this.order.platform || !this.order.orderNumber || !this.order.amount || !this.order.paymentMethod) {
        this.$message.error('请确保所有必填字段都已填写有效数据。');
        return; // 如果有未填写的字段，终���提交
      }

      // 格式化日期为所需格式
      if (this.order.date instanceof Date) {
        const localDate = new Date(this.order.date.getTime() + 8 * 60 * 60 * 1000); // 转换为东八时区
        this.order.date = localDate.toISOString().split('T')[0]; // 格式化为 yyyy-MM-dd
      }
      const orderInfo = `订单号: ${this.order.orderNumber}<br>日期: ${this.order.date}<br>项目: ${this.order.project}<br>端: ${this.order.platform}<br>地区: ${this.order.region}<br>金额: ${this.order.amount}<br>付款方式: ${this.order.paymentMethod}<br>备注: ${this.order.remarks}`;
      
      this.$confirm(`您确定要提交订单吗？<br><br>${orderInfo}`, '确认提交', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        dangerouslyUseHTMLString: true
      }).then(() => {
        // 用户确认后发送请求
        this.$axios.post('offline_order/insert/', this.order,
            {
              headers: {
                Authorization: 'JWT ' + localStorage.getItem('jwt-token')
              }
            })
          .then((response) => {
            if (response.data.code == 200) {
              // this.order = { orderNumber: '', date: '', project: '', platform: '', region: '', amount: null, paymentMethod: '', remarks: '' };
              this.refreshOrders(); // 提交后自动获取订单信息
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }).catch(() => {
        // 用户取消操作
        console.log('用户取消了提交');
      });
    },
    refreshOrders() {
      // 刷新历史订单列表的逻辑
      this.$axios.get('offline_order/info/', {
          headers: {
            Authorization: 'JWT ' + localStorage.getItem('jwt-token')
          }
        })
        .then((response) => {
          if (response.data.code == 200) {
            this.orders = response.data.data.map(order => ({
              orderNumber: order.order_number,
              date: order.date,
              project: order.project,
              platform: order.platform,
              region: order.region,
              amount: order.amount,
              paymentMethod: order.payment_method,
              remarks: order.remarks,
              is_valid: order.is_valid // 新增映射is_valid属性
            }));
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getProjects() {
      this.$axios
        .get('daily/project_data/', {
          headers: {
            Authorization: 'JWT ' + localStorage.getItem('jwt-token')
          }
        })
        .then((res) => {
          if (res.data.code == 200) {
            this.projectList = res.data.project_data;
            this.filteredProjectList = this.projectList; // 始化过滤后的项目列表
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    deleteOrder(order) {
      // 删除订单的逻辑
      this.order = { ...order, is_valid: false }; // 设置is_valid为false
      // this.submitOrder(); // 调用submitOrder方法
      const orderInfo = `订单号: ${this.order.orderNumber}<br>日期: ${this.order.date}<br>项目: ${this.order.project}<br>端: ${this.order.platform}<br>地区: ${this.order.region}<br>金额: ${this.order.amount}<br>付款方式: ${this.order.paymentMethod}<br>备注: ${this.order.remarks}`;
      
      this.$confirm(`您确定要删除订单吗？<br><br>${orderInfo}`, '确认删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        dangerouslyUseHTMLString: true
      }).then(() => {
        // 用户确认后发送请求
        this.$axios.post('offline_order/insert/', this.order,
            {
              headers: {
                Authorization: 'JWT ' + localStorage.getItem('jwt-token')
              }
            })
          .then((response) => {
            if (response.data.code == 200) {
              // this.order = { orderNumber: '', date: '', project: '', platform: '', region: '', amount: null, paymentMethod: '', remarks: '' };
              this.refreshOrders(); // 提交后自动获取订单信息
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }).catch(() => {
        // 用户取消操作
        console.log('用户取消了删除');
      });
      this.refreshOrders(); // 删除��自动获取订单信息
    },
    filterOrders() {
      const params = {};
      if (this.search.orderNumber) params.orderNumber = this.search.orderNumber;
      if (this.search.project) params.project = this.search.project;
      if (this.search.platform) params.platform = this.search.platform;
      if (this.search.region) params.region = this.search.region;
      if (this.search.remarks) params.remarks = this.search.remarks;

      this.$axios.get('offline_order/info/', {
        headers: {
          Authorization: 'JWT ' + localStorage.getItem('jwt-token')
        },
        params: params // 传递筛选参数
      })
      .then((response) => {
        if (response.data.code == 200) {
          this.orders = response.data.data.map(order => ({
            orderNumber: order.order_number,
            date: order.date,
            project: order.project,
            platform: order.platform,
            region: order.region,
            amount: order.amount,
            paymentMethod: order.payment_method,
            remarks: order.remarks,
            is_valid: order.is_valid
          }));
        }
      })
      .catch((error) => {
        console.error(error);
      });
    },
    clearSearch() {
      this.search = { // 清空搜索表单
        orderNumber: '',
        project: '',
        platform: '',
        region: '',
        remarks: ''
      };
      this.refreshOrders(); // 返回默认的历史订单数据
    },
  }
};
</script>

<style scoped>
.order-container {
  display: flex;
}
</style>

