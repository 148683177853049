<template>
  <div>
    <div v-if="user_group.indexOf('测试组') == -1 && user_group.indexOf('产品组') == -1">
      <cw403></cw403>
    </div>
    <!-- 判断是否有页面限制，如果有，则必须包含该页 -->
    <div v-else-if="page_list.length > 0 && page_list.indexOf('Admob数据模块') == -1">
      <cw403></cw403>
    </div>
    <div v-else>
      <div class="crumbs">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item style="font-size: 16px">
            Admob数据模块
            <el-button type="text" style="font-size: 16px" @click="dialogVisible = true">(页面说明)</el-button>
            <font style="margin-left: 20px; color: #409eff">展示顺序:&nbsp;&nbsp;&nbsp;{{ checkList }}</font>
          </el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="container">
        <div class="options-bar">
          <font style="16px;color:gray;margin-right:30px;">分组维度</font>
          <el-checkbox-group @change="demo" v-model="checkList" style="display: inline">
            <el-checkbox label="date" disabled></el-checkbox>
            <el-checkbox label="app_name"></el-checkbox>
            <el-checkbox label="platform"></el-checkbox>
            <el-checkbox label="format"></el-checkbox>
            <el-checkbox label="ad_source_name"></el-checkbox>
            <el-checkbox label="country"></el-checkbox>
            <el-checkbox label="ad_placement_name"></el-checkbox>
          </el-checkbox-group>
        </div>
        <div class="options-bar">
          <span style="color:gray;margin-right:30px;">筛选维度</span>
          <date-picker
            style="margin-right: 10px"
            v-model="date"
          >
          </date-picker>
          <el-select
            v-model="QQ.gameName"
            @change="selectXM"
            multiple
            placeholder="项目"
            collapse-tags
            style="margin-right: 10px; width: 180px"
          >
            <div style="float: left; overflow: hidden; height: 200px; overflow-y: auto; width: 200px">
              <el-input
                style="width: 90%; margin-left: 5%; margin-bottom: 5px"
                v-model="inputXM"
                @keyup.enter.native="enterXM"
                clearable
              ></el-input>
              <button @click="selectAll1" style="height: 24px; width: 70px; margin-left: 13px; margin-bottom: 10px">全选</button>
              <button style="height: 24px; width: 70px; margin-left: 20px" @click="qinchu1">清空</button>
              <el-option v-for="item in list1" :key="item" :label="item" :value="item"></el-option>
              <el-option v-if="list1 == ''" label="无相关内容" value="无相关内容" :disabled="true"></el-option>
            </div>
            <div
              style="
                float: left;
                overflow: hidden;
                height: 200px;
                overflow-y: auto;
                padding: 10px;
                width: 150px;
                border-left: 1px #dee1e7 solid;
              "
            >
              <div style="height: 30px" v-for="item in QQ.gameName" :key="item">
                <el-tag closable @close="handleClose(item)">{{ item }}</el-tag>
              </div>
            </div>
          </el-select>
          
          <el-select
            v-model="QQ.pl"
            @change="selectPT"
            multiple
            placeholder="应用端"
            collapse-tags
            style="margin-right: 10px; width: 120px"
          >
            <el-option v-for="item in list222" :key="item.value" :label="item.label" :value="item.value"> </el-option>
          </el-select>

          <el-select
            v-model="QQ.phone"
            @change="selectPT"
            multiple
            placeholder="广告样式/format"
            collapse-tags
            style="margin-right: 10px; width: 180px"
          >
            <el-option v-for="item in list2" :key="item.value" :label="item.label" :value="item.value"> </el-option>
          </el-select>


        </div>
        <div class="options-bar" style="margin-left: 95px">
          
          <el-select v-model="QQ2.sj" 
            placeholder="国家/country" 
            multiple
            remote
            clearable
            :remote-method="sjRemoteMethod"
            :loading="sjLoading"
            style="margin-right: 10px; width: 240px">
            <div style="float: left; overflow: hidden; height: 200px; overflow-y: auto; width: 200px">
              <el-input
                style="width: 90%; margin-left: 5%; margin-bottom: 5px"
                v-model="inputCountry"
                @keyup.enter.native="enterCountry"
                clearable
              ></el-input>
              <button @click="selectAll4" style="height: 24px; width: 70px; margin-left: 13px; margin-bottom: 10px">全选</button>
              <button style="height: 24px; width: 70px; margin-left: 20px" @click="qinchu4">清空</button>
              <el-option v-for="item in sjTempList" :key="item" :label="item" :value="item"></el-option>
              <el-option v-if="sjTempList == ''" label="无相关内容" value="无相关内容" :disabled="true"></el-option>
            </div>
            <div
              style="
                float: left;
                overflow: hidden;
                height: 200px;
                overflow-y: auto;
                padding: 10px;
                width: 160px;
                border-left: 1px #dee1e7 solid;
              "
            >
              <div style="height: 30px" v-for="item in QQ2.sj" :key="item">
                <el-tag closable @close="handleCloseCountry(item)">{{ item }}</el-tag>
              </div>
            </div>
          </el-select>
          <el-select
            v-model="QQ.country"
            @change="selectGZ"
            multiple
            placeholder="广告平台/adSource"
            collapse-tags
            style="margin-right: 10px; width: 180px"
          >
            <div style="float: left; overflow: hidden; height: 200px; overflow-y: auto; width: 200px">
              <el-input
                style="width: 90%; margin-left: 5%; margin-bottom: 5px"
                v-model="inputGZ"
                @keyup.enter.native="enterGZ"
                clearable
              ></el-input>
              <button @click="selectAll3" style="height: 24px; width: 70px; margin-left: 13px; margin-bottom: 10px">全选</button>
              <button style="height: 24px; width: 70px; margin-left: 20px" @click="qinchu3">清空</button>
              <el-option v-for="item in list3" :key="item" :label="item" :value="item"> </el-option>
              <el-option v-if="list3 == ''" label="无相关内容" value="无相关内容" :disabled="true"></el-option>
            </div>
            <div
              style="
                float: left;
                overflow: hidden;
                height: 200px;
                overflow-y: auto;
                padding: 10px;
                width: 160px;
                border-left: 1px #dee1e7 solid;
              "
            >
              <div style="height: 30px" v-for="item in QQ.country" :key="item">
                <el-tag closable @close="handleCloseGZ(item)">{{ item }}</el-tag>
              </div>
            </div>
          </el-select>

          <el-select
            v-model="QQ.phone2"
            @change="selectPT"
            multiple
            placeholder="日期是否汇总"
            collapse-tags
            style="margin-right: 10px; width: 180px"
          >
            <el-option v-for="item in list22" :key="item.value" :label="item.label" :value="item.value"> </el-option>
          </el-select>

        </div>
        <div class="options-bar">
          <el-button
            type="primary"
            plain
            icon="el-icon-search"
            @click="handleSearch"
            style="margin-bottom: 10px; margin-top: 10px; margin-right: 10px"
            :disabled="kaiguan"
            >搜索</el-button
          >
          <el-button v-if="fanhui" plain type="primary" icon="el-icon-search" @click="fanhuiss" style="margin-left: 0px">返回</el-button>
        </div>
        <div class="options-bar">
          <span style="16px;color:gray;margin-right:30px;float:left;">快速检索</span>
          <ElSearchInput ref="searchInput" @SearchInput='SearchInput($event)'></ElSearchInput>
          <el-button
            @click="download"
            style="margin-left: auto"
            icon="el-icon-s-release"
            type="info"
            plain
          >导出csv</el-button>
        </div>
        <el-table
          v-loading="pictLoading"
          element-loading-text="数据正在加载中"
          :data="tableSearchList.slice((pageInfo.current-1)*pageInfo.size,pageInfo.current*pageInfo.size)"
          height="550"
          border
          class="table"
          ref="multipleTable"
          header-cell-class-name="table-header"
          :header-cell-style="{ color: '#696969' }"
        >
          <ex-table-column
            :min-width="120"
            :autoFit="true"
            :fitHeader="true"
            sortable
            v-for="item in tableColumns1"
            :key="item"
            :prop="item"
            fixed
            :label="item"
          />

          <ex-table-column
            :autoFit="true"
            :fitHeader="true"
            sortable
            v-for="item in tableColumns2"
            :key="item"
            :prop="item"
            :label="item"
          />
        </el-table>
        <pagination
          @size-change="handleSizeChange"
          :current-page.sync="pageInfo.current"
          :page-size.sync="pageInfo.size"
          :total="pageInfo.total"
        ></pagination>
      </div>

      <el-dialog title="市场消耗模块" :visible.sync="dialogVisible" width="30%">
        <div style="margin-top: -20px">
          <h2>分组维度/筛选条件</h2>
          <ul style="margin-left: 40px; margin-top: 10px">
            <li>项目</li>
            <li>渠道</li>
            <li>媒体渠道</li>
            <li>日期范围</li>
            <li>国家</li>
            <li>账号</li>
            <li>Campaign名称</li>
          </ul>
          <h2 style="margin-top: 20px">数据指标</h2>
          <ul style="margin-left: 40px; margin-top: 10px">
            <li>消耗</li>
            <li>安装</li>
            <li>CPI</li>
            <li>CPM</li>
            <li>展示数</li>
            <li>点击</li>
            <li>点击率</li>
            <li>展示</li>
          </ul>
          <h2 style="margin-top: 20px">说明</h2>
          <ul style="margin-left: 40px; margin-top: 10px">
            <li>搜索条件对大小写不敏感，us US Us 均可搜索到US地区的数据</li>
            <li>快速检索：输入字段进行检索，支持多字段检索（以空格分隔输入，例：2020-10-01 火柴人神射手 ios）</li>
          </ul>
        </div>

        <!-- <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
            </span> -->
      </el-dialog>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import echarts from 'echarts';
import { fetchData } from '../../api/index';
import json2csv from 'json2csv';
import cw403 from './403.vue';
import ElSearchInput from '../SelectComponent/searchInput.vue';
export default {
  name: 'market-table1',
  components: {
    cw403,
    ElSearchInput,
  },
  data() {
    return {
      kaiguan: false,
      inputXM: '',
      inputGZ: '',
      inputCountry:'',
      inputMT: '',
      inputTF: '',
      inputJH: '',
      list31: [
        { label: '总次数', value: 'count' },
        { label: '人均次数', value: 'per_user_count' },
        { label: '唯一用户数', value: 'unique_user' }
      ],
      list32: [
        { label: '是', value: 1 },
        { label: '否', value: 0 }
      ],
      list33: [
        { label: '是', value: 1 },
        { label: '否', value: 0 }
      ],
      listr: ['tt', 'vv', 'cc', 'Crystal', 'LM', 'DD'],
      renyuan: [],
      user_group: localStorage.getItem('user-group'),
      page_list: localStorage.getItem('page_list'),
      checkList: ['date'],
      tableSearchList: [],
      tableColumns: [],
      tableColumns1: [],
      tableColumns2: [],
      //折线图参数
      time: [],
      active1: [],
      active2: [],
      addmoney1: [],
      addmoney2: [],
      fanhui: false,
      checked1: false,
      checked2: false,
      checked3: false,
      checked5: false,
      checked6: false,
      checked7: false,
      checked9: false,
      pictLoading: false,
      sjLoading: false,
      pageInfo: {
        size: 50,
        current: 1,
        total: 0,
      },
      date: [],

      dialogVisible: false,
      type: false,
      list1: [],
      list2: [
        { label: '所有样式', value: 'all' },
        { label: '激励视频', value: 'rewarded' },
        { label: '插屏', value: 'interstitial' },
        { label: '横幅', value: 'banner' }
      ],
      list222: [
        { label: '所有端', value: 'all'},
        { label: '安卓', value: 'android'},
        { label: 'iOS', value:'ios'},
      ],
      list22: [
        { label: '日期汇总', value: 'True' },
        { label: '不需要日期汇总', value: 'False' }
      ],
      list3: [],
      list5: ['Organic', 'Fb', 'Tiktok', 'Unity', 'GoogleAd', 'Other'],
      list6: [],
      list7: [],
      list9: [],
      sjTempList: [],
      QQ: {
        date: [],
        gameName: [],
        phone: [],
        version: [],
        country: []
      },
      QQ2: {
        sj: [],
        mt: [],
        tf: [],
        jh: []
      },
      QQ3: {
        sta_dimension: 'count',
        is_on_day: 0,
        is_compared: 0
      },
      tableData: [],
      lastquery: '',
      multipleSelection: [],
      delList: [],
      editVisible: false,
      pageTotal: 0,
      form: {},
      idx: -1,
      id: -1
    };
  },
  created() {
    this.getXM();
    this.PageCount();
    // this.getGZ();
    // this.getCountry();
  },
  methods: {
    //回车选中第一项方法
    enterCountry() {
      if (this.QQ2.sj.includes(this.sjTempList[0])) {
        console.log('已选中');
      } else this.QQ2.sj.push(this.sjTempList[0]);
      this.getCountry();
    },
    enterXM() {
      if (this.QQ.gameName.includes(this.list1[0])) {
        console.log('已选中');
      } else this.QQ.gameName.push(this.list1[0]);
      this.getGZ();
      // this.getJH();
    },
    enterGZ() {
      if (this.QQ.country.includes(this.list3[0])) {
        console.log('已选中');
      } else this.QQ.country.push(this.list3[0]);
      // this.getJH();
    },
    enterMT() {
      if (this.QQ2.mt.includes(this.list5[0])) {
        console.log('已选中');
      } else this.QQ2.mt.push(this.list5[0]);
      /* this.getTF() */
      // this.getJH();
    },
    enterTF() {
      if (this.QQ2.tf.includes(this.list6[0])) {
        console.log('已选中');
      } else this.QQ2.tf.push(this.list6[0]);
      // this.getJH();
    },
    enterJH() {
      if (this.QQ2.jh.includes(this.list7[0])) {
        console.log('已选中');
      } else this.QQ2.jh.push(this.list7[0]);
    },
    handleClose(tag) {
      this.QQ.gameName.splice(this.QQ.gameName.indexOf(tag), 1);
    },
    handleCloseGZ(tag) {
      this.QQ.country.splice(this.QQ.country.indexOf(tag), 1);
    },
    handleCloseCountry(tag) {
      this.QQ2.sj.splice(this.QQ2.sj.indexOf(tag), 1);
    },
    handleCloseMT(tag) {
      this.QQ2.mt.splice(this.QQ2.mt.indexOf(tag), 1);
    },
    handleCloseTF(tag) {
      this.QQ2.tf.splice(this.QQ2.tf.indexOf(tag), 1);
    },
    handleCloseJH(tag) {
      this.QQ2.jh.splice(this.QQ2.jh.indexOf(tag), 1);
    },
    //checkbox-group回调
    demo(val) {
      this.checkList = val;
    },
    //返回按钮
    fanhuiss() {
      this.fanhui = false;
      this.tableData = [];
    },
    //添加全选功能
    selectAll1() {
      this.QQ.gameName = this.list1;
      // this.getJH();
      this.getGZ();
    },
    qinchu1() {
      this.QQ.gameName = [];
      this.checked1 = false;
    },
    // selectAll2() {
    //   this.QQ.phone = [];
    //   if (this.checked2) {
    //     this.QQ.phone = ['all', 'ios', 'android'];
    //   } else this.QQ.phone = [];
    //   this.getGZ();

    //   /* this.getMT() */
    //   /* this.getTF() */
    //   // this.getJH();
    // },
    selectAll3() {
      this.QQ.country = this.list3;

      /* this.getMT() */
      /* this.getTF() */
      // this.getJH();
    },
    qinchu3() {
      this.QQ.country = [];
      this.checked3 = false;
    },
    // 国家的全选
    selectAll4() {
      this.QQ2.sj = this.sjTempList;

      /* this.getMT() */
      /* this.getTF() */
      // this.getJH();
    },
    qinchu4() {
      this.QQ2.sj = [];
      this.checked4 = false;
    },
    selectAll5() {
      this.QQ2.mt = this.list5;

      /* this.getTF() */
      // this.getJH();
    },
    qinchu5() {
      this.QQ2.mt = [];
      this.checked5 = false;
    },
    selectAll6() {
      this.QQ2.tf = this.list6;
      // this.getJH();
    },
    qinchu6() {
      this.QQ2.tf = [];
      this.checked6 = false;
    },
    selectAll7() {
      this.QQ2.jh = this.list7;
      // this.getJH();
    },
    qinchu7() {
      this.QQ2.jh = [];
      this.checked7 = false;
    },
    selectAll9() {
      this.renyuan = [];
      if (this.checked9) {
        this.renyuan = this.listr;
      } else this.renyuan = [];
    },
    qinchu9() {
      this.renyuan = [];
      this.checked9 = false;
    },
    sjRemoteMethod(query) {
      this.sjLoading = true
      if (query !== '') {
        this.sjTempList = this.sjTempList.filter(item => {
          return item.includes(query)
        })
      } else {
        // this.sjTempList = [];
      }
      this.sjLoading = false
    },
    getTable() {
      //发起get请求
      this.$http
        .get('mar_consume/init_data/', {
          headers: {
            Authorization: 'JWT ' + localStorage.getItem('jwt-token')
          }
        })
        .then(function (res) {
          if (res.data.code == 200) {
            this.tableData = res.data.list;
            this.pageInfo.total = res.data.list.length;
            this.tableColumns = [];
            for (var pro in res.data.list[0]) {
              this.tableColumns.push(pro);
            }
            this.tableColumns1 = this.tableColumns.splice(0, 1);
            this.tableColumns2 = this.tableColumns;
            this.pictLoading = false;
          }
        })
        .catch(function (err) {
          console.log(err);
        });

      /* this.$axios({
                method:'get',
                url:'http://192.168.1.29:8085/basicdata/test/'
            }).then((response) =>{          //这里使用了ES6的语法
                console.log(response)       //请求成功返回的数据
            }).catch((error) =>
                console.log(error)       //请求失败返回的数据
            ) */
    },
    // 触发搜索按钮
    handleSearch() {
      if (this.QQ.gameName == '') {
        this.$notify({ title: '提示', message: '请填写项目', type: 'warning' });
      }
      // if (this.QQ.phone == '') {
      //   this.$notify({ title: '提示', message: '请填写平台', type: 'warning' });
      // }
      // if (this.QQ2.sj == '') {
      //   this.$notify({ title: '提示', message: '请填写事件', type: 'warning' });
      // }
      // if (this.QQ3.sta_dimension == '') {
      //   this.$notify({ title: '提示', message: '请填写统计维度', type: 'warning' });
      // }
      // if (this.QQ3.is_on_day === '') {
      //   this.$notify({ title: '提示', message: '请填写是否按天统计', type: 'warning' });
      // }
      // if (this.QQ3.is_compared === '') {
      //   this.$notify({ title: '提示', message: '请填写是否对比', type: 'warning' });
      // }
      if (
        this.QQ.gameName != ''
      ) {
        this.fanhui = true;
        this.pictLoading = true;
        //post请求获取搜索内容
        this.$axios
          .post(
            'admob/info/',
            {
              date_range: this.date,
              project: this.QQ.gameName,
              port: this.QQ.pl,
              platform: this.QQ.country,
              country: this.QQ2.sj,
              format: this.QQ.phone,
              dimension: this.checkList,
              date_all: this.QQ.phone2
            },
            {
              headers: {
                Authorization: 'JWT ' + localStorage.getItem('jwt-token')
              }
            }
          )
          .then((res) => {
            this.tableData = res.data.list;
            this.pageInfo.total = res.data.list.length;
            this.tableColumns = [];
            for (var pro in res.data.list[0]) {
              this.tableColumns.push(pro);
            }
            this.tableColumns1 = this.tableColumns.splice(0, res.data.fix_columns);
            this.tableColumns2 = this.tableColumns;
            this.$nextTick(() => {
              this.$refs.multipleTable.doLayout();
              // el-table加ref="multipleTable"
            });
            this.pictLoading = false;
            this.$refs.searchInput.searchTable();
          })
          .catch((e) => {});
      }
    },
    handleSizeChange() {
      this.pageInfo.current = 1;
    },
    // 表格检索
    SearchInput(tableList){
      this.tableSearchList = tableList;
      this.pageInfo.total = tableList.length;
    },
    // //搜索
    // search(query) {
    //   if (query == "")
    //   {
    //     console.log("233");
    //     return this.tableData;
    //   }
    //   else
    //   {
    //     let tableData = this.tableData.filter((item) => {
    //     var array = query.split(' ');
    //     if (array.length== 1 && array[0] == '')
    //     {
    //       return item;
    //     }
    //     else
    //     {
    //       for (let l in array){if (Object.values(item).join().toLowerCase().includes(array[l].toLowerCase())){}else{return;};};
    //       return item;
    //     }
    //     });
    //     this.pageInfo.total = tableData.length;
    //     return tableData
    //   }


    //   // this.lastquery = query;
    //   // setTimeout(() => {
    //   //   if(this.lastTimer === query)
    //   //   //如果this.lastTimer === val（也就是你停止输入0.5s之内都没有其它的值发生变化）则做你想要做的事
    //   //   {
    //   //     let tableData = this.tableData.filter((item) => {
    //   //     var array = query.split(' ');
    //   //     if (array.length== 1 && array[0] == '')
    //   //     {
    //   //       return item;
    //   //     }
    //   //     else
    //   //     {
    //   //       for (let l in array){if (Object.values(item).join().includes(array[l])){}else{return;};};
    //   //       return item;
    //   //     }
    //   //     });
    //   //     this.pageInfo.total = tableData.length;
    //   //     return tableData
    //   //   }
    //   // },500);
    // },
    //导出csv文件
    download() {
      //datas：数据，fields：字段名
      try {
        const result = json2csv.parse(this.tableData);
        // 判断浏览器类型
        if (
          (navigator.userAgent.indexOf('compatible') > -1 && navigator.userAgent.indexOf('MSIE') > -1) ||
          navigator.userAgent.indexOf('Edge') > -1
        ) {
          //IE10或Edge浏览器
          var BOM = '\uFEFF';
          var csvData = new Blob([BOM + result], { type: 'text/csv' });
          navigator.msSaveBlob(csvData, `test.csv`);
        } else {
          //非IE浏览器
          var csvContent = 'data:text/csv;charset=utf-8,\uFEFF' + result;
          //使用a标签的download属性实现下载功能
          var link = document.createElement('a');
          link.href = encodeURI(csvContent);
          link.download = `test.csv`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      } catch (err) {
        alert(err);
      }
    },
    selectXM(val) {
      //如果项目选择框为空，赋予list1，相当于全选
      if (val.length === this.list1) {
        this.checked1 = true;
      } else this.checked1 = false;
      if (this.QQ.gameName != '') {
        this.$axios
          .post(
            'admob/platform_data/',
            {
              date_range: this.date,
              project: this.QQ.gameName
            },
            {
              headers: {
                Authorization: 'JWT ' + localStorage.getItem('jwt-token')
              }
            }
          )
          .then((res) => {
            this.list3 = res.data.platform_data;
          })
          .catch((e) => {});
      }
      if (this.QQ.gameName == '') {
        this.$axios
          .post(
            'admob/platform_data/',
            {
              date_range: this.date,
              project: this.list1
            },
            {
              headers: {
                Authorization: 'JWT ' + localStorage.getItem('jwt-token')
              }
            }
          )
          .then((res) => {
            this.list3 = res.data.platform_data;
          })
          .catch((e) => {});
      }
      // this.getJH();
      // this.getSJ();
      this.getPF();
      this.getGZ();
    },
    selectPT(val) {
      this.getGZ();
      //如果项目选择框为空，赋予list1，相当于全选
      if (val.length === ['all','rewarded','interstitial','banner']) {
        this.checked2 = true;
      } else this.checked2 = false;
    },
    selectGZ(val) {
      //如果项目选择框为空，赋予list1，相当于全选
      if (val.length === this.list3) {
        this.checked3 = true;
      } else this.checked3 = false;

      /* this.getMT() */
      /* this.getTF() */
      this.getGZ();
    },
    //获取游戏名
    getXM() {
      this.$http
        .get('admob/project_data/', {
          headers: {
            Authorization: 'JWT ' + localStorage.getItem('jwt-token')
          }
        })
        .then(function (res) {
          if (res.data.code == 200) {
            this.list1 = res.data.project_data;
            // this.list1.unshift('all');
          }
        })
        .catch(function (err) {
          console.log(err);
        });
    },
    //获取所有广告平台信息
    getPF() {
      this.$axios
        .post(
          'admob/platform_data/',
          {
            project: this.QQ.gameName,
            date_range: this.date
          },
          {
            headers: {
              Authorization: 'JWT ' + localStorage.getItem('jwt-token')
            }
          }
        )
        .then((res) => {
          this.list3 = res.data.platform_data;
        })
        .catch((e) => {});
    },
    //获取所有国家信息
    getGZ() {
      this.$axios
        .post(
          'admob/country_data/',
          {
            project: this.QQ.gameName,
            platform: this.QQ.country,
            date_range: this.date
          },
          {
            headers: {
              Authorization: 'JWT ' + localStorage.getItem('jwt-token')
            }
          }
        )
        .then((res) => {
          this.sjTempList = res.data.country_data;
        })
        .catch((e) => {});
    },
    // 获取国家2
    getCountry() {
      this.$axios
        .post(
          'admob/country_data/',
          {
            project: this.QQ.gameName,
            platform: this.QQ.country,
            date_range: this.date
          },
          {
            headers: {
              Authorization: 'JWT ' + localStorage.getItem('jwt-token')
            }
          }
        )
        .then((res) => {
          this.sjTempList = res.data.country_data;
        })
        .catch((e) => {});
    },
    // 访问
    PageCount() {
      this.$http
        .post('index/count/', 
        {
          page_name:"Admob数据报表"
        },
        {
          headers: {
            Authorization: 'JWT ' + localStorage.getItem('jwt-token')
          }
        })
        .then(function (res) {
          if (res.data.code == 200) {
            // this.$refs.childProject.project_list = res.data.project_data;
            // this.list1.unshift('all');
          }
        })
        .catch(function (err) {
          console.log('Auth Error');
        if (localStorage.getItem("jwt-token") != null){
          localStorage.clear();
          window.location.href="/#/login";
        } 
        });
    },
    // 获取之前的日期的函数
    getBeforeDate(n) {
          let _n = n;
          let _d = new Date();
          let _year = _d.getFullYear();
          let _mon = _d.getMonth() + 1;
          let _day = _d.getDate();
          if(_day <= _n) {
              if(_mon > 1) {
                  _mon = _mon - 1;
              } else {
                  _year = _year - 1;
                  _mon = 12;
              }
          }
          _d.setDate(_d.getDate() - n);
          _year = _d.getFullYear();
          _mon = _d.getMonth() + 1;
          _day = _d.getDate();
          var date_sub = _year + "-" + (_mon < 10 ? ('0' + _mon) : _mon) + "-" + (_day < 10 ? ('0' + _day) : _day);
          return date_sub;
      },
  },

  mounted() {
    let timeStamp = new Date();
    let year = new Date(timeStamp).getFullYear();
    let month = new Date(timeStamp).getMonth() + 1 < 10 ? '0' + (new Date(timeStamp).getMonth() + 1) : new Date(timeStamp).getMonth() + 1;
    let month1 = new Date(timeStamp).getMonth() < 10 ? '0' + new Date(timeStamp).getMonth() : new Date(timeStamp).getMonth();
    let date = new Date(timeStamp).getDate() < 10 ? '0' + new Date(timeStamp).getDate() : new Date(timeStamp).getDate();
    let date_sub = this.getBeforeDate(30);
    this.date = [date_sub, year + '-' + month + '-' + date];
  },

  watch: {
    // 监听检索的input inputCountry为国家 newlist4为中间变量没啥用 sjTempList为具体国家的列表值
    inputCountry() {
        var newlist4 = [];
        this.sjTempList.filter((item) => {
          if (item.toUpperCase().includes(this.inputCountry.toUpperCase())) {
            newlist4.push(item);
          }
        });
        this.sjTempList = newlist4;
        if (this.inputCountry == '') {
          this.getCountry();
        }
    },
    inputXM() {
      var newlist1 = [];
      this.list1.filter((item) => {
        if (item.toUpperCase().includes(this.inputXM.toUpperCase())) {
          newlist1.push(item);
        }
      });
      this.list1 = newlist1;
      if (this.inputXM == '') {
        this.getXM();
      }
    },
    inputGZ() {
      var newlist3 = [];
      this.list3.filter((item) => {
        if (item.toUpperCase().includes(this.inputGZ.toUpperCase())) {
          newlist3.push(item);
        }
      });
      this.list3 = newlist3;
      if (this.inputGZ == '') {
        this.getGZ();
      }
    },
    inputMT() {
      var newlist5 = [];
      this.list5.filter((item) => {
        if (item.toUpperCase().includes(this.inputMT.toUpperCase())) {
          newlist5.push(item);
        }
      });
      this.list5 = newlist5;
      if (this.inputMT == '') {
        /* this.getMT() */
      }
    },
    inputTF() {
      var newlist6 = [];
      this.list6.filter((item) => {
        if (item.toUpperCase().includes(this.inputTF.toUpperCase())) {
          newlist6.push(item);
        }
      });
      this.list6 = newlist6;
      if (this.inputTF == '') {
        /* this.getTF() */
      }
    },
    inputJH() {
      var newlist7 = [];
      this.list7.filter((item) => {
        if (item.toUpperCase().includes(this.inputJH.toUpperCase())) {
          newlist7.push(item);
        }
      });
      this.list7 = newlist7;
      if (this.inputJH == '') {
        // this.getJH();
      }
    }
  }
};
</script>
<style>
.el-tag .el-icon-close {
  float: right;
  margin-top: 4px;
}
.el-tag {
  background: white;
  width: 100%;
}
.el-table .cell,
.el-table th > .cell {
  white-space: nowrap;
  width: fit-content;
}
.el-select__tags {
  flex-wrap: nowrap;
  overflow: hidden;
}
.el-tag.el-tag--info .el-tag__close {
  display: none;
}
.el-tag.el-tag--info {
  border: none;
  background: white;
}
.el-select__tags-text {
  margin-left: 0px;
}
.el-date-editor--daterange.el-input__inner {
  width: 240px;
}
.el-picker-panel {
  margin-left: 240px;
}
</style>
<style scoped>
.table {
  width: 100%;
  font-size: 14px;
}
</style>