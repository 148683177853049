<template>
          <el-select
            v-model="select_adformat"
            @change="AdFormatChangeChild"
            multiple
            placeholder="广告样式/format"
            collapse-tags
            style="margin-right: 10px; width: 180px"
          >
            <el-option v-for="item in adformat_list" :key="item.value" :label="item.label" :value="item.value"> </el-option>
          </el-select>
</template>

<script>
export default {
  methods: {
    AdFormatChangeChild(){
          this.$emit('AdFormatChange',this.select_adformat);
    },
  },
 data() {
    return {
        adformat_list: [
            { label: '所有样式', value: 'all' },
            { label: '激励视频', value: 'Rewarded Video' },
            { label: '插屏', value: 'Interstitial' },
        ],
        select_adformat:[]
      
    };
  },
}
</script>
