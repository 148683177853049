<template>
  <div>
    <div v-if="user_group.indexOf('测试组') == -1 && user_group.indexOf('产品组') == -1">
      <cw403></cw403>
    </div>
    <div v-if="user_group.indexOf('测试组') > -1 || user_group.indexOf('产品组') > -1">
      <div class="crumbs">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item style="font-size: 16px">
            各广告平台数据报表
            <el-button type="text" style="font-size: 16px" @click="dialogVisible = true">(页面说明)</el-button>
            <font style="margin-left: 20px; color: #409eff">展示顺序:&nbsp;&nbsp;&nbsp;{{ checkList }}</font>
          </el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="container">
        <div class="options-bar">
          <font style="16px;color:gray;margin-right:30px;">分组维度</font>
          <el-checkbox-group @change="demo" v-model="checkList" style="display: inline">
            <el-checkbox label="日期" disabled></el-checkbox>
            <el-checkbox label="项目"></el-checkbox>
            <el-checkbox label="平台"></el-checkbox>
            <el-checkbox label="广告平台"></el-checkbox>
            <el-checkbox label="国家"></el-checkbox>
            <el-checkbox label="广告样式"></el-checkbox>
            <el-checkbox label="广告位"></el-checkbox>
          </el-checkbox-group>
          <el-checkbox-group @change="date_all_change" v-model="date_all_checked" style="display: inline;margin-left:20px;">
            <el-checkbox label="日期是否汇总"></el-checkbox>
          </el-checkbox-group>
        </div>
        <div class="options-bar">
          <span style="color:gray;margin-right:30px;">筛选维度</span>
          <date-picker
            style="margin-right: 10px"
            v-model="date"
          >
          </date-picker>
          <ElSelectProject style="margin-right: 10px; width: 180px" ref="childProject" @ProjectChange='ProjectChange($event)'></ElSelectProject>
          <ElSelectPlatform style="margin-right: 10px; width: 180px" ref="childPlatform" @PlatformChange='PlatformChange($event)'></ElSelectPlatform>
          <ElSelectAdFormat style="margin-right: 10px; width: 180px" ref="childAdformat" @AdFormatChange='AdFormatChange($event)'></ElSelectAdFormat>
        </div>
        <div class="options-bar" style="margin-left: 95px">
          <ElSelectAdSource style="margin-right: 10px; width: 240px" ref="childAdSource" @AdsourceChange='AdsourceChange($event)'></ElSelectAdSource>
          <ElSelectCountry style="margin-right: 10px; width: 180px" ref="childCountry" @CountryChange='CountryChange($event)'></ElSelectCountry>
          <el-button
            type="primary"
            plain
            icon="el-icon-search"
            @click="handleSearch"
            style="margin-bottom: 10px; margin-top: 10px; margin-right: 10px"
            :disabled="kaiguan"
            >搜索</el-button
          >
          <el-button v-if="fanhui" plain type="primary" icon="el-icon-search" @click="fanhuiss" style="margin-left: 0px">返回</el-button>
        </div>
        <div class="options-bar">
          <span style="16px;color:gray;margin-right:30px;float:left;">快速检索</span>
          <ElSearchInput ref="searchInput" @SearchInput='SearchInput($event)'></ElSearchInput>
        </div>
          <el-button
            @click="download"
            style="float: right;"
            icon="el-icon-s-release"
            type="info"
            plain
            >导出csv</el-button>
        <el-dropdown style="float: right; margin-right: 5px; padding-bottom:5px">
          <el-button type="primary" plain>
            列筛选<i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown" style="max-height: 400px;overflow: scroll;">
              <el-checkbox-group style="padding:10px 0px" @change="changeTableColumns" v-model="tableColumnsCheckedShow">
                <el-checkbox style="display:block;padding:5px 0px 5px 10px" :key="item" :label="item" v-for="item in tableColumnsCheckedList"></el-checkbox>
              </el-checkbox-group>
          </el-dropdown-menu>
        </el-dropdown>

        <el-table
          v-loading="pictLoading"
          element-loading-text="数据正在加载中"
          :data="tableSearchList"
          height="550"
          border
          class="table"
          ref="multipleTable"
          header-cell-class-name="table-header"
          :header-cell-style="{ color: '#696969' }"
        >
          <ex-table-column
            :min-width="120"
            :autoFit="true"
            :fitHeader="true"
            sortable
            v-for="item in tableColumns1"
            :key="item"
            :prop="item"
            fixed
            :label="item"
          />

          <ex-table-column
            :autoFit="true"
            :fitHeader="true"
            sortable
            v-for="item in tableColumns2"
            :key="item"
            :prop="item"
            :label="item"
          />
        </el-table>
        <pagination
          @size-change="handleSizeChange"
          :current-page.sync="pageInfo.current"
          :page-size.sync="pageInfo.size"
          :total="pageInfo.total"
        ></pagination>
      </div>

      <el-dialog title="市场消耗模块" :visible.sync="dialogVisible" width="30%">
        <div style="margin-top: -20px">
          <h2>分组维度/筛选条件</h2>
          <ul style="margin-left: 40px; margin-top: 10px">
            <li>项目</li>
            <li>渠道</li>
            <li>媒体渠道</li>
            <li>日期范围</li>
            <li>国家</li>
            <li>账号</li>
            <li>Campaign名称</li>
          </ul>
          <h2 style="margin-top: 20px">数据指标</h2>
          <ul style="margin-left: 40px; margin-top: 10px">
            <li>消耗</li>
            <li>安装</li>
            <li>CPI</li>
            <li>CPM</li>
            <li>展示数</li>
            <li>点击</li>
            <li>点击率</li>
            <li>展示</li>
          </ul>
          <h2 style="margin-top: 20px">说明</h2>
          <ul style="margin-left: 40px; margin-top: 10px">
            <li>搜索条件对大小写不敏感，us US Us 均可搜索到US地区的数据</li>
            <li>快速检索：输入字段进行检索，支持多字段检索（以空格分隔输入，例：2020-10-01 火柴人神射手 ios）</li>
          </ul>
        </div>

        <!-- <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
            </span> -->
      </el-dialog>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import echarts from 'echarts';
import { fetchData } from '../../api/index';
import json2csv from 'json2csv';
import cw403 from './403.vue';
import ElSelectCountry from '../SelectComponent/select_country.vue';
import ElSelectAdSource from '../SelectComponent/select_adsource.vue';
import ElSelectPlatform from '../SelectComponent/select_platform.vue';
import ElSelectProject from '../SelectComponent/select_project.vue';
import ElSelectAdFormat from '../SelectComponent/select_adformat.vue';
import ElSearchInput from '../SelectComponent/searchInput.vue';
export default {
  name: 'AdCash-AllReport',
  components: {
    cw403,
    ElSelectCountry,
    ElSelectAdSource,
    ElSelectPlatform,
    ElSelectProject,
    ElSelectAdFormat,
    ElSearchInput
  },
  data() {
    return {
      tableColumnsCheckedShow: [],
      tableColumnsCheckedList: [],
      kaiguan: false,
      user_group: localStorage.getItem('user-group'),
      checkList: ['日期'],
      date_all: 'false',
      date_all_checked: false,
      tableColumns: [],
      tableColumns1: [],
      tableColumns2: [],
      //折线图参数
      time: [],
      active1: [],
      active2: [],
      addmoney1: [],
      addmoney2: [],
      tableSearchList: [],
      fanhui: false,
      checked1: false,
      checked2: false,
      checked3: false,
      checked5: false,
      checked6: false,
      checked7: false,
      checked9: false,
      pictLoading: false,
      sjLoading: false,
      pageInfo: {
        size: 50,
        current: 1,
        total: 0,
      },
      date: [],
      dialogVisible: false,
      type: false,
      tableData: [],
      lastquery: '',
      multipleSelection: [],
      delList: [],
      editVisible: false,
      pageTotal: 0,
      form: {},
      idx: -1,
      id: -1,
    };
  },
  created() {
    // 初始请求，请求项目
    this.getProject();
    this.PageCount();
    // this.getAdsource();
    // this.getCountry();
  },
  methods: {
    ProjectChange(e){
      this.getAdsource();
      this.getCountry();
    },
    PlatformChange(e){
      // 改变值，请求国家选择框的值
      this.getCountry();
      this.getAdsource();
    },
    AdsourceChange(e){
      // 改变值，请求国家选择框的值
      this.getCountry();
    },
    CountryChange(e){
      // 当改变值的时候，请求下个选项框的值，如果此选择框没有联动的下个选项框就为空就可以
      // console.log('接受');
      // console.log(e);
    },
    AdFormatChange(e){
      // 
    },
    //checkbox-group回调
    demo(val) {
      this.checkList = val;
    },
    changeTableColumns(val) {
      this.tableColumnsCheckedShow = val;
      this.tableColumns2 = val;
    },
    // 日期是否汇总
    date_all_change(val){
      console.log(val);
      if(val===true){
        this.date_all = 'true'
      }
      else{
        this.date_all = 'false'
      }
    },
    //返回按钮
    fanhuiss() {
      this.fanhui = false;
      this.tableData = [];
    },  
    // 触发搜索按钮
    handleSearch() {
      if (this.$refs.childProject.select_project == '') {
        this.$notify({ title: '提示', message: '请填写项目', type: 'warning' });
      }
      if (
        this.$refs.childProject.select_project != ''
      ) {
        this.fanhui = true;
        this.pictLoading = true;
        //post请求获取搜索内容
        this.$axios
          .post(
            'all_report/info/',
            {
              date_range: this.date,
              project: this.$refs.childProject.select_project,
              platform: this.$refs.childPlatform.select_platform,
              country: this.$refs.childCountry.select_country,
              ad_source: this.$refs.childAdSource.select_adSource,
              adformat: this.$refs.childAdformat.select_adformat,
              dimension: this.checkList,
              date_all: this.date_all,
            },
            {
              headers: {
                Authorization: 'JWT ' + localStorage.getItem('jwt-token')
              }
            }
          )
          .then((res) => {
            this.tableData = res.data.list;
            this.pageInfo.total = res.data.list.length;
            this.tableColumns = [];
            for (var pro in res.data.list[0]) {
              this.tableColumns.push(pro);
            }
            this.tableColumns1 = this.tableColumns.splice(0, res.data.fix_columns);
            this.tableColumns2 = this.tableColumns;
            // 筛选的列
            this.tableColumnsCheckedList = this.tableColumns;
            this.tableColumnsCheckedShow = []; // 再次搜索时，将已选的全部重置
            this.$nextTick(() => {
              this.$refs.multipleTable.doLayout();
              // el-table加ref="multipleTable"
            });
            this.pictLoading = false;
            // 搜索需要经过子组件里的检索函数
            this.$refs.searchInput.searchTable();
          })
          .catch((e) => {});
      }
    },
    handleSizeChange() {
      this.pageInfo.current = 1;
    },
    // 检索
    SearchInput(tableList){
      this.tableSearchList = tableList;
      this.pageInfo.total = tableList.length;
    },
    //导出csv文件
    download() {
      //datas：数据，fields：字段名
      try {
        const result = json2csv.parse(this.tableData);
        // 判断浏览器类型
        if (
          (navigator.userAgent.indexOf('compatible') > -1 && navigator.userAgent.indexOf('MSIE') > -1) ||
          navigator.userAgent.indexOf('Edge') > -1
        ) {
          //IE10或Edge浏览器
          var BOM = '\uFEFF';
          var csvData = new Blob([BOM + result], { type: 'text/csv' });
          navigator.msSaveBlob(csvData, `test.csv`);
        } else {
          //非IE浏览器
          var csvContent = 'data:text/csv;charset=utf-8,\uFEFF' + result;
          //使用a标签的download属性实现下载功能
          var link = document.createElement('a');
          link.href = encodeURI(csvContent);
          link.download = `test.csv`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      } catch (err) {
        alert(err);
      }
    },
    //获取游戏名
    getProject() {
      this.$http
        .get('all_report/project_data/', {
          headers: {
            Authorization: 'JWT ' + localStorage.getItem('jwt-token')
          }
        })
        .then(function (res) {
          if (res.data.code == 200) {
            this.$refs.childProject.project_list = res.data.project_data;
            // this.list1.unshift('all');
          }
        })
        .catch(function (err) {
          console.log(err);
        });
    },
    // 获取广告平台的信息（fb,applovin）
    getAdsource(){
        this.$axios
          .post(
            'all_report/adsource_data/',
            {
              project: this.$refs.childProject.select_project,
              platform: this.$refs.childPlatform.select_platform,
              date_range: this.date,
            },
            {
              headers: {
                Authorization: 'JWT ' + localStorage.getItem('jwt-token')
              }
            }
          )
          .then((res) => {
            this.$refs.childAdSource.ad_source_list = res.data.adsource_data;
          })
        .catch((e) => {});
    },
    // 获取国家
    getCountry() {
      this.$axios
        .post(
          'all_report/country_data/',
          {
            project: this.$refs.childProject.select_project,
            platform: this.$refs.childPlatform.select_platform,
            ad_source: this.$refs.childAdSource.select_adSource,
            date_range: this.date,
          },
          {
            headers: {
              Authorization: 'JWT ' + localStorage.getItem('jwt-token')
            }
          }
        )
        .then((res) => {
          this.$refs.childCountry.country_list = res.data.country_data;
        })
        .catch((e) => {});
    },
    // 访问
    PageCount() {
      this.$http
        .post('index/count/', 
        {
          page_name: "各广告平台数据报表"
        },
        {
          headers: {
            Authorization: 'JWT ' + localStorage.getItem('jwt-token')
          }
        })
        .then(function (res) {
          if (res.data.code == 200) {
            // this.$refs.childProject.project_list = res.data.project_data;
            // this.list1.unshift('all');
          }
          else if (response.status === 401) {
            console.log('DO WHAT YOU WANT2222');
            if (localStorage.getItem("jwt-token") != null){
              localStorage.clear();
              window.location.href="/#/login";
            } 
          }
        })
        .catch(function (err) {
          console.log('Auth Error');
        if (localStorage.getItem("jwt-token") != null){
          localStorage.clear();
          window.location.href="/#/login";
        } 
        });
    },
    // 获取之前的日期的函数
    getBeforeDate(n) {
          let _n = n;
          let _d = new Date();
          let _year = _d.getFullYear();
          let _mon = _d.getMonth() + 1;
          let _day = _d.getDate();
          if(_day <= _n) {
              if(_mon > 1) {
                  _mon = _mon - 1;
              } else {
                  _year = _year - 1;
                  _mon = 12;
              }
          }
          _d.setDate(_d.getDate() - n);
          _year = _d.getFullYear();
          _mon = _d.getMonth() + 1;
          _day = _d.getDate();
          var date_sub = _year + "-" + (_mon < 10 ? ('0' + _mon) : _mon) + "-" + (_day < 10 ? ('0' + _day) : _day);
          return date_sub;
      },
  },

  mounted() {
    let timeStamp = new Date();
    let year = new Date(timeStamp).getFullYear();
    let month = new Date(timeStamp).getMonth() + 1 < 10 ? '0' + (new Date(timeStamp).getMonth() + 1) : new Date(timeStamp).getMonth() + 1;
    let month1 = new Date(timeStamp).getMonth() < 10 ? '0' + new Date(timeStamp).getMonth() : new Date(timeStamp).getMonth();
    let date = new Date(timeStamp).getDate() < 10 ? '0' + new Date(timeStamp).getDate() : new Date(timeStamp).getDate();
    let date_sub = this.getBeforeDate(30);
    this.date = [date_sub, year + '-' + month + '-' + date];
  },

};
</script>
<style>
.el-tag .el-icon-close {
  float: right;
  margin-top: 4px;
}
.el-tag {
  background: white;
  width: 100%;
}
.el-table .cell,
.el-table th > .cell {
  white-space: nowrap;
  width: fit-content;
}
.el-select__tags {
  flex-wrap: nowrap;
  overflow: hidden;
}
.el-tag.el-tag--info .el-tag__close {
  display: none;
}
.el-tag.el-tag--info {
  border: none;
  background: white;
}
.el-select__tags-text {
  margin-left: 0px;
}
.el-date-editor--daterange.el-input__inner {
  width: 240px;
}
.el-picker-panel {
  margin-left: 240px;
}
</style>
<style scoped>
.table {
  width: 100%;
  font-size: 14px;
}
</style>