<template>
  <div>
    <div v-if="user_group.indexOf('产品组') == -1">
      <cw403></cw403>
    </div>
    <!-- 判断是否有页面限制，如果有，则必须包含该页 -->
    <div v-else-if="page_list.length > 0 && page_list.indexOf('谷歌评价') == -1">
      <cw403></cw403>
    </div>
    <div v-else>
      <div class="crumbs">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item style="font-size: 16px"> 评论 </el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="container">
        <div v-if="visLoad" style="position: absolute; top: 50%; left: 48%; color: #409eff">
          <i class="el-icon-loading"></i>
          <span style="color: #409eff; font-size: 14px">努力请求中</span>
        </div>
        <div class="options-bar">
          <span style="color: gray; margin-right: 30px">筛选维度</span>
          <date-picker style="margin-right: 10px" v-model="date"> </date-picker>
          <el-select v-model="Project" @change="ProjectChange($event)" filterable placeholder="项目" style="margin-right: 10px">
            <el-option v-for="item in projectList" :key="item" :label="item" :value="item"> </el-option>
          </el-select>
          <el-select v-model="isReply" filterable clearable placeholder="是否回复" style="margin-right: 10px">
            <el-option v-for="item in isReplyList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
          </el-select>
          <el-select v-model="starRating" filterable multiple clearable placeholder="评分" style="margin-right: 10px">
            <el-option v-for="item in starRatingList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
          </el-select>
        </div>
        <div class="options-bar" style="margin-left: 95px">
          <el-select v-model="isComment" filterable clearable placeholder="用户是否评论" style="margin-right: 10px; width: 240px">
            <el-option v-for="item in isCommentList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
          </el-select>
          <el-select v-model="Language" filterable clearable placeholder="语种" style="margin-right: 10px">
            <el-option v-for="item in languageList" :key="item" :label="item" :value="item"> </el-option>
          </el-select>
          <el-button
            type="primary"
            plain
            icon="el-icon-search"
            @click="handleSearch(50, 1)"
            style="margin-bottom: 10px; margin-top: 10px; margin-right: 10px"
            :disabled="kaiguan"
            >搜索</el-button
          >
          <el-button v-if="fanhui" plain type="primary" icon="el-icon-search" @click="fanhuiss" style="margin-left: 0px">返回</el-button>
        </div>
        <div class="options-bar">
          <span style="color: gray; margin-right: 30px">批量回复</span>
          <el-button
            type="primary"
            plain
            icon="el-icon-chat-round"
            @click="dialogVisible = true"
            style="margin-bottom: 10px; margin-top: 10px; margin-right: 10px"
            >回复以下全部</el-button
          >
          <el-button
            type="primary"
            plain
            icon="el-icon-chat-round"
            @click="dialogSelectVisible = true"
            style="margin-bottom: 10px; margin-top: 10px; margin-right: 10px"
            >回复勾选部分
          </el-button>
        </div>
        <comment
          :comments="commentData"
          ref="childCommentList"
          @selectComment="selectComment($event)"
          @commitComment="commitComment(arguments)"
        ></comment>

        <pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="pageSizes"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalCount"
        >
        </pagination>
      </div>

      <el-dialog title="批量回复(回复多条时会比较慢，会跳过已经回复的)" :visible.sync="dialogVisible" width="40%">
        <span style="display: block; margin: 10px 0px">覆盖人数:{{ this.commentData.length }}</span>
        <el-input type="textarea" placeholder="请输入内容" :rows="4" v-model="developerComment"> </el-input>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="submitCommentList">确 定</el-button>
        </span>
      </el-dialog>

      <el-dialog title="选择回复(回复多条时会比较慢)" :visible.sync="dialogSelectVisible" width="40%">
        <span style="display: block; margin: 10px 0px">覆盖人数:{{ this.checkSelectList.length }}</span>
        <el-input type="textarea" placeholder="请输入内容" :rows="4" v-model="developerComment"> </el-input>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogSelectVisible = false">取 消</el-button>
          <el-button type="primary" @click="submitSelectCommentList">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import comment from '../somecompoment/Comment';
import cw403 from './403.vue';

export default {
  components: {
    comment
  },
  data() {
    return {
      fanhui: false,
      kaiguan: false,
      dialogVisible: false,
      dialogSelectVisible: false,
      visLoad: false,
      checkSelectList: [],
      developerComment: '',
      commentData: [],
      date: [],
      projectList: [],
      Project: '',
      user_group: localStorage.getItem('user-group'),
      page_list: localStorage.getItem('page_list'),
      isReply: '',
      isReplyList: [
        { label: '是', value: 'reply' },
        { label: '否', value: 'no_reply' }
      ],
      Language: '',
      languageList: [],
      isComment: '',
      isCommentList: [
        { label: '是', value: 'comment' },
        { label: '否', value: 'no_comment' }
      ],
      starRating: [],
      starRatingList: [
        { label: '1星', value: '1' },
        { label: '2星', value: '2' },
        { label: '3星', value: '3' },
        { label: '4星', value: '4' },
        { label: '5星', value: '5' }
      ],
      // 默认显示第几页
      currentPage: 1,
      // 总条数，根据接口获取数据长度(注意：这里不能为空)
      totalCount: 1,
      // 个数选择器（可修改）
      pageSizes: [50, 100, 200, 300, 400],
      // pageSizes:[5,10,20,30],
      // 默认每页显示的条数（可修改）
      pageSize: 50
    };
  },
  created() {
    // this.commentData = CommentData.comment.data;
    this.getProject();
    this.PageCount();
  },
  methods: {
    ProjectChange(e) {
      // this.getMedia();
      this.getLanguage();
    },
    selectComment(e) {
      this.checkSelectList = e;
    },
    commitComment(e) {
      // console.log(1);
      // console.log(e[0]);
      // console.log(e[1]);
      this.visLoad = true;
      this.$axios
        .post(
          'reviews/comment/',
          {
            comment: e[0],
            review_id: e[1],
            app_status: e[2],
            app_id: e[3]
          },
          {
            headers: {
              Authorization: 'JWT ' + localStorage.getItem('jwt-token')
            }
          }
        )
        .then((res) => {
          if (res.data.code === 200) {
            this.visLoad = false;
            // this.$message(res.data.msg);
            this.$alert(res.data.msg, res.data.title, {
              confirmButtonText: '确定',
              callback: (action) => {
                this.$message({
                  type: 'info',
                  message: `action: ${action}`
                });
              }
            });
            // 重新请求
            this.handleSearch(this.pageSize, this.currentPage);
          } else {
            this.visLoad = false;
            this.$alert(res.data.msg, '回复失败', {
              confirmButtonText: '确定',
              callback: (action) => {
                this.$message({
                  type: 'info',
                  message: `action: ${action}`
                });
              }
            });
          }
        })
        .catch((e) => {});
    },
    // 批量回复
    submitCommentList() {
      this.dialogVisible = false;
      this.visLoad = true;
      this.$axios
        .post(
          'reviews/comment_list/',
          {
            developer_comment: this.developerComment,
            comment_data: this.commentData,
            is_split: ''
          },
          {
            headers: {
              Authorization: 'JWT ' + localStorage.getItem('jwt-token')
            }
          }
        )
        .then((res) => {
          if (res.data.code === 200) {
            this.visLoad = false;
            this.dialogVisible = false;
            this.$alert(res.data.msg, res.data.title, {
              confirmButtonText: '确定',
              callback: (action) => {
                this.$message({
                  type: 'info',
                  message: `action: ${action}`
                });
              }
            });
            // 重新请求
            this.handleSearch(this.pageSize, this.currentPage);
          } else {
            this.visLoad = false;
            this.dialogVisible = false;
            // this.$message(res.data.msg);
            this.$alert(res.data.msg, '回复失败', {
              confirmButtonText: '确定',
              callback: (action) => {
                this.$message({
                  type: 'info',
                  message: `action: ${action}`
                });
              }
            });
            // 重新请求
            this.handleSearch(this.pageSize, this.currentPage);
          }
          // console.log('2')
        })
        .catch((e) => {});
    },
    // 选择回复
    submitSelectCommentList() {
      this.dialogSelectVisible = false;
      this.visLoad = true;
      this.$axios
        .post(
          'reviews/comment_list/',
          {
            developer_comment: this.developerComment,
            comment_data: this.checkSelectList,
            is_split: 'split'
          },
          {
            headers: {
              Authorization: 'JWT ' + localStorage.getItem('jwt-token')
            }
          }
        )
        .then((res) => {
          if (res.data.code === 200) {
            this.visLoad = false;
            this.dialogSelectVisible = false;
            this.$alert(res.data.msg, res.data.title, {
              confirmButtonText: '确定',
              callback: (action) => {
                this.$message({
                  type: 'info',
                  message: `action: ${action}`
                });
              }
            });
            // 重新请求
            this.handleSearch(this.pageSize, this.currentPage);
          } else {
            this.visLoad = false;
            this.dialogSelectVisible = false;
            // this.$message(res.data.msg);
            this.$alert(res.data.msg, '回复失败', {
              confirmButtonText: '确定',
              callback: (action) => {
                this.$message({
                  type: 'info',
                  message: `action: ${action}`
                });
              }
            });
            // 重新请求
            this.handleSearch(this.pageSize, this.currentPage);
          }
          // console.log('2')
        })
        .catch((e) => {});
    },
    // 每页显示的条数
    handleSizeChange(val) {
      // 改变每页显示的条数
      this.pageSize = val;
      // 点击每页显示的条数时，显示第一页
      this.handleSearch(val, 1);
      // 注意：在改变每页显示的条数时，要将页码显示到第一页
      this.currentPage = 1;
    },
    // 显示第几页
    handleCurrentChange(val) {
      // console.log('改变页数')
      // 改变默认的页数
      this.currentPage = val;
      // 切换页码时，要获取每页显示的条数
      // this.handleSearch(this.PageSize,(val)*(this.PageSize))
      this.handleSearch(this.pageSize, val);
    },
    //返回按钮
    fanhuiss() {
      this.fanhui = false;
      this.tableData = [];
    },
    // 触发搜索按钮
    handleSearch(n1, n2) {
      // console.log(n1)
      // console.log(n2)
      if ([this.Project] == '') {
        this.$notify({ title: '提示', message: '请填写项目', type: 'warning' });
      }
      if ([this.Project] != '') {
        this.fanhui = true;
        this.pictLoading = true;
        //post请求获取搜索内容
        this.$axios
          .post(
            'reviews/info/',
            {
              date_range: this.date,
              project: [this.Project],
              is_reply: this.isReply,
              language_data: this.Language,
              star_rating: this.starRating,
              is_comment: this.isComment,
              // 每页显示的条数
              page_size: n1,
              // 显示第几页
              current_page: n2,
              field_order: '',
              field_value: ''
            },
            {
              headers: {
                Authorization: 'JWT ' + localStorage.getItem('jwt-token')
              }
            }
          )
          .then((res) => {
            // this.tableData = res.data.list;
            // this.pageInfo.total = res.data.list.length;
            // this.totalCount = res.data.list.length;
            this.totalCount = res.data.total;
            this.pictLoading = false;
            // this.tableSearchList = res.data.list;
            // this.commentData = CommentData.comment.data;
            this.commentData = res.data.list;
            // 清空勾选的状态
            this.checkSelectList = [];
            this.$refs.childCommentList.checkList = [];
            // console.log(this.commentData)
            // 搜索需要经过子组件里的检索函数
            // this.$refs.searchInput.searchTable();
          })
          .catch((e) => {});
      }
    },
    //获取游戏名
    getProject() {
      console.log(this.page_list.length);
      console.log(this.page_list.includes("谷歌评价"));
      console.log(this.page_list);
      this.$http
        .get('reviews/project_data/', {
          headers: {
            Authorization: 'JWT ' + localStorage.getItem('jwt-token')
          }
        })
        .then(function (res) {
          if (res.data.code == 200) {
            this.projectList = res.data.project_data;
            // this.list1.unshift('all');
          }
        })
        .catch(function (err) {
          console.log(err);
        });
    },
    // 获取语种
    getLanguage() {
      this.$axios
        .post(
          'reviews/language_data/',
          {
            project: [this.Project]
          },
          {
            headers: {
              Authorization: 'JWT ' + localStorage.getItem('jwt-token')
            }
          }
        )
        .then((res) => {
          this.languageList = res.data.language_data;
        })
        .catch((e) => {});
    },
    // 访问
    PageCount() {
      this.$http
        .post(
          'index/count/',
          {
            page_name: '谷歌评论'
          },
          {
            headers: {
              Authorization: 'JWT ' + localStorage.getItem('jwt-token')
            }
          }
        )
        .then(function (res) {
          if (res.data.code == 200) {
            // this.$refs.childProject.project_list = res.data.project_data;
            // this.list1.unshift('all');
          }
        })
        .catch(function (err) {
          console.log(err);
        });
    },
    // 获取之前的日期的函数
    getBeforeDate(n) {
      let _n = n;
      let _d = new Date();
      let _year = _d.getFullYear();
      let _mon = _d.getMonth() + 1;
      let _day = _d.getDate();
      if (_day <= _n) {
        if (_mon > 1) {
          _mon = _mon - 1;
        } else {
          _year = _year - 1;
          _mon = 12;
        }
      }
      _d.setDate(_d.getDate() - n);
      _year = _d.getFullYear();
      _mon = _d.getMonth() + 1;
      _day = _d.getDate();
      var date_sub = _year + '-' + (_mon < 10 ? '0' + _mon : _mon) + '-' + (_day < 10 ? '0' + _day : _day);
      return date_sub;
    }
  },
  mounted() {
    let timeStamp = new Date();
    let year = new Date(timeStamp).getFullYear();
    let month = new Date(timeStamp).getMonth() + 1 < 10 ? '0' + (new Date(timeStamp).getMonth() + 1) : new Date(timeStamp).getMonth() + 1;
    let month1 = new Date(timeStamp).getMonth() < 10 ? '0' + new Date(timeStamp).getMonth() : new Date(timeStamp).getMonth();
    let date = new Date(timeStamp).getDate() < 10 ? '0' + new Date(timeStamp).getDate() : new Date(timeStamp).getDate();
    // 初始日期间隔
    let date_sub = this.getBeforeDate(7);
    this.date = [date_sub, year + '-' + month + '-' + date];
  }
};
</script>
<style>
.el-date-editor--daterange.el-input__inner {
  width: 240px;
}
</style>