<template>
  <div>
    <div v-if="user_group.indexOf('产品组') == -1">
      <cw403></cw403>
    </div>
    <!-- 判断是否有页面限制，如果有，则必须包含该页 -->
    <div v-else-if="page_list.length > 0 && page_list.indexOf('用户定制') == -1">
      <cw403></cw403>
    </div>
    <div v-else>
      <div class="crumbs">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item style="font-size: 16px">
            用户定制
            <font style="margin-left: 20px; color: #409eff">分组:&nbsp;&nbsp;&nbsp;{{ groupByList }}</font>
          </el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="container">
        <div class="options-bar">
          <font style="16px;color:gray;margin-right:30px;">指标</font>
          <!--此为change方法动态禁用 -->
          <el-radio-group v-model="quota" @change="quotaChange()">
            <el-radio label="留存"></el-radio>
            <el-radio label="玩家游戏时长分布"></el-radio>
            <el-radio label="终身人均广告触发"></el-radio>
            <el-tooltip effect="dark" content="玩家进入游戏前(X)日的数据" placement="bottom" :disabled="quotaInputDisabled">
              <el-input
                v-model="quotaInput"
                style="width: 75px; margin-left: -25px; margin-right: 25px"
                placeholder="请输入"
                :disabled="quotaInputDisabled"
              ></el-input>
            </el-tooltip>
            <el-radio label="活跃付费率"></el-radio>
            <el-radio label="终身付费率"></el-radio>
            <el-radio label="关卡漏斗"></el-radio>
            <el-radio label="指定事件漏斗"></el-radio>
          </el-radio-group>
          <!--？问号弹出文本提示界面 -->
          <el-tooltip class="item" effect="dark" content="点击查看产品相关文本" placement="left-end">
            <i class="el-icon-question" circle style="font-size: 22px; cursor: pointer; margin-left: 40%" @click="dialogVisible = true"></i>
          </el-tooltip>
        </div>
        <div class="options-bar">
          <font style="16px;color:gray;margin-right:30px;">报表种类</font>
          <el-radio-group v-model="reportType">
            <el-radio label="按批次" :disabled="apcDisabled"></el-radio>
            <el-radio label="按活跃" :disabled="ahyDisabled"></el-radio>
          </el-radio-group>
        </div>
        <div class="options-bar">
          <font style="16px;color:gray;margin-right:30px;">分组维度</font>
          <el-checkbox-group @change="dimDemo" v-model="groupByList" style="display: inline">
            <el-checkbox label="日期" :disabled="rqDisabled"></el-checkbox>
            <el-checkbox label="国家" :disabled="gjDisabled"></el-checkbox>
            <el-checkbox label="媒体渠道" :disabled="mtqdDisabled"></el-checkbox>
            <el-checkbox label="版本" :disabled="bbDisabled"></el-checkbox>
            <el-checkbox label="事件名" :disabled="sjmDisabled"></el-checkbox>
            <el-checkbox label="打点的自定义参数" :disabled="zdycsDisabled"></el-checkbox>
            <el-checkbox label="事件人数" :disabled="sjrsDisabled"></el-checkbox>
            <el-checkbox label="事件次数" :disabled="sjcsDisabled"></el-checkbox>
            <el-checkbox label="事件时间" :disabled="sjsjDisabled"></el-checkbox>
            <el-checkbox label="GAID" :disabled="gaidDisabled"></el-checkbox>
          </el-checkbox-group>
        </div>
        <div class="options-bar">
          <span style="color: gray; margin-right: 30px">筛选条件</span>
          <el-checkbox label="长期" v-model="forever" @change="isForever"></el-checkbox>
          <!-- 测试使用时间选择器 -->
          <!-- <div class="block"> -->
          <el-date-picker
            v-model="value2"
            type="datetimerange"
            :picker-options="pickerOptions"
            :value-format="'yyyy-MM-dd HH:mm:ss'"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            align="right"    
            style="margin-left: 10px; margin-right: 10px; width: 340px"
            :disabled="datePickerDis"
            @change="datePickTest"
          >
          </el-date-picker>
          <!-- </div> -->
          <!-- <date-picker ref="datePicker" style="margin-left: 10px; margin-right: 10px" v-model="date"> </date-picker> -->
          <el-select
            v-model="Project"
            @change="ProjectChange($event)"
            filterable
            placeholder="点击选择【项目】"
            style="margin-right: 10px; width: 240px"
          >
            <el-option v-for="item in projectList" :key="item.app_id" :label="item.label" :value="item.app_id">
              <div>
                <dl v-html="item.icon">
                  {{ item.icon }}
                </dl>
                <span style="padding-left: 25px">{{ item.app_name }}</span>
              </div>
            </el-option>
          </el-select>
          <ElSelectCountry
            style="margin-right: 10px; width: 240px"
            ref="childCountry"
            @CountryChange="CountryChange($event)"
          ></ElSelectCountry>
          <el-radio-group v-model="countryType" style="display: flex; flex-direction: column">
            <el-radio label="选择"></el-radio>
            <el-radio label="排除"></el-radio>
          </el-radio-group>
        </div>
        <div class="options-bar" style="margin-left: 140px">
          <ElSelectMedia style="margin-right: 10px; width: 240px" ref="childMedia" @MediachangeValue="MediaChange($event)"></ElSelectMedia>
          <el-select
            v-model="appVersion"
            multiple
            @change="appVersionChange($event)"
            filterable
            placeholder="点击选择【版本】"
            style="margin-right: 10px; width: 240px"
          >
            <el-option v-for="item in appVersionList" :key="item" :label="item" :value="item"> </el-option>
          </el-select>
        </div>
        <div class="af-point-class">
          <table border="8">
            <tr style="margin-bomttom: 20px">
              <td>
                <font style="margin: 5px">AF打点 </font>
              </td>
              <td>
                <el-radio-group v-model="searchRule" style="display: flex; flex-direction: column; margin: 5px">
                  <el-radio label="and"></el-radio>
                  <el-radio label="or" style="margin-top: 23px"></el-radio>
                </el-radio-group>
              </td>
              <td>
                <el-radio-group
                  v-model="searchType"
                  style="display: flex; flex-direction: column; margin: 5px"
                  @change="searchTypeChange()"
                >
                  <el-radio label="具体搜索" value="equal"></el-radio>
                  <el-radio label="模糊搜索" value="like" style="margin-top: 23px"></el-radio>
                </el-radio-group>
              </td>
              <td style="display: flex; flex-direction: column">
                <!-- <el-select
             v-model="detailSelect"
             style="margin:5px"
             placeholder="【具体搜索】请选择打点项">11</el-select> -->
                <el-input
                  v-model="detailInput"
                  type="text"
                  name="detailInput"
                  id="detailInput"
                  style="margin: 5px; width: 96%"
                  placeholder="【具体搜索】请输入内容"
                  @change="detailChange()"
                  @keydown.enter.native="detailEnter()"
                  :disabled="detailDisabled"
                ></el-input>
                <el-input
                  v-model="fuzzyInput"
                  type="text"
                  name="fuzzySearch"
                  id="fuzzySearch"
                  style="margin: 5px; width: 96%"
                  placeholder="【模糊搜索】请输入内容"
                  @change="fuzzyChange()"
                  @keydown.enter.native="fuzzyEnter()"
                  :disabled="fuzzyDisabled"
                ></el-input>
              </td>
              <td style="width: 300px">
                <el-tag :key="tag" v-for="tag in saveAfDetailList" closable :disable-transitions="false" @close="handleClose(tag)">
                  = {{ tag }}
                </el-tag>
                <el-tag :key="tag" v-for="tag in saveAfFuzzyList" closable :disable-transitions="false" @close="handleClose2(tag)">
                  Like {{ tag }}
                </el-tag>
                <!-- <el-input v-model="showAfCode" type="textarea" style="margin: 5px; width: 95%" readOnly="true"></el-input> -->
              </td>
            </tr>
          </table>
        </div>
        <!-- <div class="options-bar">
            <span style="color:gray;margin-right:30px;">用户筛选</span>
          <ElSelectEvent style="margin-right: 10px; width: 240px" ref="childEvent" @changeValue="EventChange($event)"></ElSelectEvent>
        </div>
        <div class="options-bar" style="margin-left: 95px">            
            <el-form>
                <el-form-item v-for="(it, index) in list" :key="index">
                    <el-select style="margin-right: 10px; width: 240px" v-model="eventId[index]" clearable placeholder="请选择" @change="saveList($event, index)">
                        <el-option v-for="item in array" :key="item.value" :label="item.name" :value="item.value" >
                        </el-option>
                    </el-select>
                    
                    <el-select style="margin-right: 10px; width: 80px" v-model="relationId[index]" clearable placeholder="请选择" @change="saveRelationList($event, index)">
                        <el-option v-for="item in relationArray" :key="item.value" :label="item.name" :value="item.value" >
                        </el-option>
                    </el-select>
                    
                    <el-input
                    v-model="valueId[index]"
                    @change="saveValueList($event, index)"
                    placeholder="值"
                    clearable
                    style="width:180px;margin-right:10px;"
                    ></el-input>

                    <el-button style="margin-left:10px" @click="addItem" size="mini" round>增加</el-button>
                    <el-button style="margin-left:10px"   @click="removeItem(it, index)" size="mini" round>删除</el-button>
        
                </el-form-item>
            
            </el-form> 
        </div> -->
        <div class="options-bar">
          <el-button
            type="primary"
            plain
            icon="el-icon-search"
            @click="handleSearch"
            style="margin-left: 87%; width: 100px"
            :disabled="kaiguan"
            >搜索</el-button
          >
          <!-- <el-button v-if="fanhui" plain type="primary" icon="el-icon-search" @click="fanhuiss" style="margin-left: 0px">返回</el-button> -->
          <!-- <span style="16px;color:gray;margin-right:30px;float:left;">快速检索</span>
          <ElSearchInput ref="searchInput" @SearchInput='SearchInput($event)'></ElSearchInput> -->
          <!-- <el-button @click="download" style="margin-left: auto" icon="el-icon-s-release" type="info" plain>导出EXCEL</el-button> -->
          <el-button @click="goExport" style="margin-left: auto" icon="el-icon-s-release" type="info" plain>导出EXCEL</el-button>
        </div>
        <el-table
          v-loading="pictLoading"
          element-loading-text="数据正在加载中"
          :data="tableSearchList"
          height="550"
          border
          class="table"
          ref="multipleTable"
          header-cell-class-name="table-header"
          :header-cell-style="{ color: '#696969' }"
        >
          <ex-table-column
            :min-width="120"
            :autoFit="true"
            :fitHeader="true"
            sortable
            v-for="item in tableColumns1"
            :key="item"
            :prop="item"
            fixed
            :label="item"
          />

          <ex-table-column
            :autoFit="true"
            :fitHeader="true"
            sortable
            v-for="item in tableColumns2"
            :key="item"
            :prop="item"
            :label="item"
          />
        </el-table>
        <pagination
          @size-change="handleSizeChange"
          :current-page.sync="pageInfo.current"
          :page-sizes="pageSizes"
          :page-size="pageInfo.size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageInfo.total"
        ></pagination>
      </div>
    </div>

    <el-dialog title="用户定制模块" :visible.sync="dialogVisible" width="30%">
      <div style="margin-top: -26px">
        <h2>【指标】</h2>
        <ul style="margin-left: 40px; margin-top: 10px">
          <li>1、指标为单选，每次只能选择1个指标</li>
          <li style="margin-top: 10px">2、选择终身人均广告触发之后，要填筛选玩家进入游戏后X天内的广告数据</li>
          <li style="margin-top: 10px">
            3、指标选择关卡漏斗的话，只支持关卡打点为act_stage_X_X_enter这样格式的项目，其他项目需要看关卡漏斗的话，请选择指定漏斗事件，并在最下方的AF打点处使用模糊搜索。模糊搜索用法见下文
          </li>

          <li style="margin-top: 10px">4、可以使用指定时间漏斗来查看指定打点的情况</li>
        </ul>
        <h2 style="margin-top: 20px">【报表种类】</h2>
        <ul style="margin-left: 40px; margin-top: 10px">
          <li>1、选择了指标之后，一般会默认选择报表种类，不需要调整</li>
        </ul>
        <h2 style="margin-top: 20px">【分组维度】</h2>
        <ul style="margin-left: 40px; margin-top: 10px">
          <li>1、选择指定的分组维度之后，会在报表中把该维度显示出来</li>
          <li style="margin-top: 10px">2、选择指标之后会有一些分组维度无法选择的情况</li>
        </ul>
        <h2 style="margin-top: 20px">【筛选条件】</h2>
        <ul style="margin-left: 40px; margin-top: 10px">
          <li>1、勾选日期选择器旁边的长期之后，会勾选所有时间</li>
          <li style="margin-top: 10px">2、如果不选择国家的话，会默认排除CN</li>
          <li style="margin-top: 10px">3、媒体渠道不选择的话默认全选</li>
          <li style="margin-top: 10px">4、版本不选择的话默认全选</li>
          <li style="margin-top: 10px">3、媒体渠道不选择的话默认全选</li>
          <li style="margin-top: 10px">
            5、模糊搜索中，省略的部分需要用%表示，例如想搜索act_stage_X_X_enter系列的打点，则需要输入act_stage%enter
          </li>
        </ul>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Vue from 'vue';
import echarts from 'echarts';
import { fetchData } from '../../api/index';
import json2csv from 'json2csv';
import cw403 from './403.vue';
import ElSelectPlatform from '../SelectComponent/select_platform.vue';
import ElSelectCountry from '../SelectComponent/new_select_country.vue';
// import ElSelectProject from '../SelectComponent/select_project.vue';
import ElSelectProjectSingle from '../SelectComponent/select_project_single.vue';
import ElSearchInput from '../SelectComponent/searchInput.vue';
import ElSelectMedia from '../SelectComponent/new_select_media.vue';
import ElSelectEvent from '../SelectComponent/select_event.vue';
import DatePickerVue from '../common2/DatePicker.vue';
import { export2Excel } from '../../assets/excelexport';
export default {
  name: 'product-table-UserCustom',
  components: {
    cw403,
    ElSelectPlatform,
    ElSelectProjectSingle,
    ElSearchInput,
    ElSelectCountry,
    ElSelectMedia,
    ElSelectEvent
  },
  data() {
    return {
      dialogVisible: false,

      //指标
      quota: '留存',
      quotaInput: '',

      //终身人均广告触发禁用
      quotaInputDisabled: true,

      //报表种类
      reportType: [
        { label: '按批次', value: 'install_time' },
        { label: '按活跃', value: 'event_time' }
      ],

      //分组维度
      groupByList: ['日期'],

      //筛选条件
      // lastDateTime: [],
      lastDateTime2: [],
      forever: '',
      date: [],
      Project: '',
      Country: [],
      appVersion: [],
      countryType: '选择',

      //报表种类禁用设置
      apcDisabled: false,
      ahyDisabled: true,

      //分组维度禁用设置
      rqDisabled: false,
      gjDisabled: false,
      mtqdDisabled: false,
      bbDisabled: false,
      sjmDisabled: false,
      zdycsDisabled: false,
      sjrsDisabled: false,
      sjcsDisabled: false,
      sjsjDisabled: false,
      gaidDisabled: false,

      //展示AF打点代码区域
      showAfCode: '',

      //具体搜索-List
      saveAfDetailList: [],
      //模糊搜索-List
      saveAfFuzzyList: [],

      //具体搜索、模糊搜索输入框
      detailInput: '',
      detailDisabled: false,
      fuzzyInput: '',
      fuzzyDisabled: false,

      // //具体搜索选择框
      // detailSelect:'',

      //搜索规则：and/or
      searchRule: 'and',

      //选择搜索类型
      searchType: '模糊搜索',

      kaiguan: false,

      // DateType:'install_time',

      // DateTypeList: [
      //   { label: '按活跃', value: 'event_time' },
      //   { label: '按批次', value: 'install_time' }
      // ],
      UserIdQuery: '',
      reDisabled: false,

      projectList: [],
      countryList: [],
      appVersionList: [],

      user_group: localStorage.getItem('user-group'),
      page_list: localStorage.getItem('page_list'),

      dataList: ['基础数据'],
      tableColumns: [],
      tableColumns1: [],
      tableColumns2: [],
      //折线图参数
      time: [],
      tableSearchList: [],
      fanhui: false,
      pictLoading: false,
      userLoading: false,
      sjLoading: false,
      pageInfo: {
        size: 50,
        current: 1,
        total: 0
      },
      // 默认显示第几页
      currentPage: 1,
      // 总条数，根据接口获取数据长度(注意：这里不能为空)
      totalCount: 1,
      // 个数选择器（可修改）
      pageSizes: [50, 100, 200, 300, 400],
      // 默认每页显示的条数（可修改）
      PageSize: 50,

      type: false,
      tableData: [],
      lastquery: '',
      multipleSelection: [],
      delList: [],
      editVisible: false,
      pageTotal: 0,
      userNum: 0,
      form: {},
      idx: -1,
      id: -1,
      eventId: [],
      relationId: [],
      valueId: [],
      array: [
        { name: '总广告观看次数', value: 'ad_watch' },
        { name: '总付费金额', value: 'iap' },
        { name: '付费次数', value: 'iap_num' },
        { name: '游戏时间(min)', value: 'game_time' }
      ],
      relationArray: [
        { name: '大于', value: 'gt' },
        { name: '等于', value: 'eq' },
        { name: '小于', value: 'lt' }
      ],
      list: [{ eventId: '', relationId: '', valueId: '' }],
      selectedList: [], // 存储每次 option 选中的 集合

      nowDateInfo: {
        year: new Date().getFullYear(),
        // 月
        month: new Date().getMonth(),
        // 日
        date: new Date().getDate(),
        // 星期
        day: new Date().getDay()
      },
      oneDay: 8.64e7,
      pickerOptions: {
        shortcuts: [
          {
            text: '今日',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setHours('00');
              start.setMinutes('00');
              start.setSeconds('00');
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: '昨日',
            onClick(picker) {
              const end = new Date();
              end.setTime(end.getTime() - 3600 * 1000 * 24);
              end.setHours('23');
              end.setMinutes('59');
              end.setSeconds('59');

              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24);
              start.setHours('00');
              start.setMinutes('00');
              start.setSeconds('00');
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: '最近7天',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              start.setHours('00');
              start.setMinutes('00');
              start.setSeconds('00');

              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: '最近14天',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 14);
              start.setHours('00');
              start.setMinutes('00');
              start.setSeconds('00');

              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: '最近30天',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              start.setHours('00');
              start.setMinutes('00');
              start.setSeconds('00');

              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: '上个星期',
            onClick: (picker) => {
              const t = this.nowDateInfo.day;
              const end = new Date(Date.now() - this.oneDay * t);
              end.setHours('23');
              end.setMinutes('59');
              end.setSeconds('59');
              const start = new Date(Date.now() - this.oneDay * (t + 6));
              start.setHours('00');
              start.setMinutes('00');
              start.setSeconds('00');
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: '这个月份',
            onClick: (picker) => {
              const end = new Date();
              end.setHours('23');
              end.setMinutes('59');
              end.setSeconds('59');
              const start = new Date(new Date().setDate(1));
              start.setHours('00');
              start.setMinutes('00');
              start.setSeconds('00');
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: '上个月份',
            onClick: (picker) => {
              const { year, month } = this.nowDateInfo;
              const lastDate = new Date(year, month, 0).getDate();
              const start = new Date(year, month - 1, 1);
              start.setHours('00');
              start.setMinutes('00');
              start.setSeconds('00');
              const end = new Date(year, month - 1, lastDate);
              end.setHours('23');
              end.setMinutes('59');
              end.setSeconds('59');
              picker.$emit('pick', [start, end]);
            }
          }
        ]
      },
      value2: '',
      datePickerDis: false
    };
  },
  created() {
    // 初始请求，请求项目
    this.getProject();
    this.PageCount();

    this.quotaChange();
    this.searchTypeChange();
    // this.getAdsource();
    // this.getCountry();
  },
  methods: {
    // 时间
    datePickTest() {
      //  this.getListByDay();
      // this.value2 =this.value2
      // console.log('value2:', this.value2);
      // console.log('date:', this.date);
    },
    //测试excel导出  封装数据即可--
    goExport() {
      try {
        const result = json2csv.parse(this.tableData);

        var titleName = ['得到结果：', '已选条件：'];

        //Table列表名
        var objName = [];
        for (var k = 0; k < this.tableColumns1.length; k++) {
          objName.push(this.tableColumns1[k]);
        }
        for (var z = 0; z < this.tableColumns2.length; z++) {
          objName.push(this.tableColumns2[z]);
        }

        //第一个sheet
        var firstSheet = [];

        //列表名放入
        firstSheet.push(objName);

        //构造数组结构
        for (let j in this.tableSearchList) {
          //新循环重新初始化
          var everyCol = [];
          for (let i in objName) {
            var aa = this.tableSearchList[j][objName[i]];
            everyCol.push(aa);
            //每次结尾放入sheet中
            if (i == objName.length - 1) {
              firstSheet.push(everyCol);
            }
          }
        }

        //af打点代码处理展示
        var afCode = '';
        if (this.searchType == '具体搜索') {
          for (var nums = 0; nums < this.saveAfDetailList.length; nums++) {
            afCode += ' = ' + this.saveAfDetailList[nums] + ' ' + this.searchRule + '\n';
          }
        } else {
          if (this.saveAfFuzzyList != []) {
            for (var nums = 0; nums < this.saveAfFuzzyList.length; nums++) {
              afCode += ' Like ' + this.saveAfFuzzyList[nums] + ' ' + this.searchRule + '\n';
            }
            // console.log('this.afCode,', afCode);
          }
        }

        //指标数据处理
        var quotaExcel = this.quota;
        if (this.quotaInput != '') {
          quotaExcel += ' (' + this.quotaInput + '天) ';
        }
        //excel数据
        var sheetsList = [
          firstSheet,
          [
            ['指标', '报表种类', '分组维度', '日期:', '项目:', '国家:', '媒体渠道', '版本:', 'AF打点'],
            [
              quotaExcel,
              this.reportType,
              [this.groupByList],
              [this.date],
              this.Project,
              this.countryType + [this.$refs.childCountry.select_country],
              [this.$refs.childMedia.select_value],
              [this.appVersion],
              afCode
            ]
          ]
        ];
        var execlTitleName = '用户定制';
        //以上
        export2Excel(titleName, sheetsList, execlTitleName);
      } catch (err) {
        alert(err);
      }
    },
    enterCountry() {
      if (this.select_country.includes(this.country_list[0])) {
        // console.log('已选中');
      } else this.select_country.push(this.country_list[0]);
    },
    handleClose(tag) {
      this.saveAfDetailList.splice(this.saveAfDetailList.indexOf(tag), 1);
      // console.log('delete:', this.saveAfDetailList);
    },
    handleClose2(tag) {
      this.saveAfFuzzyList.splice(this.saveAfFuzzyList.indexOf(tag), 1);
      // console.log('delete:', this.saveAfFuzzyList);
    },
    //指标选择时的默认勾选以及禁用--方法
    quotaChange(e) {
      if (this.quota == '留存' || this.quota == '终身付费率') {
        //报表种类
        this.ahyDisabled = true;
        this.apcDisabled = false;
        this.reportType = '按批次';

        //分组维度
        this.setGroupDisabled();
      } else if (this.quota == '玩家游戏时长分布') {
        //报表种类
        this.ahyDisabled = false;
        this.apcDisabled = false;

        //分组维度
        this.setGroupDisabled();
      } else if (this.quota == '活跃付费率') {
        //报表种类
        this.apcDisabled = true;
        this.ahyDisabled = false;
        this.reportType = '按活跃';

        //分组维度
        this.setGroupDisabled();
        this.sjcsDisabled = false;

        //活跃付费率时默认选择‘事件人数’
        this.groupByList.push('事件人数');
      } else if (this.quota == '关卡漏斗') {
        //报表种类
        this.ahyDisabled = true;
        this.apcDisabled = false;
        this.reportType = '按批次';

        //分组维度
        this.setGroupDisabled();

        //活跃付费率时默认选择‘事件人数’，‘事件次数’
        this.groupByList.push('事件人数');
        this.groupByList.push('事件次数');
      } else if (this.quota == '终身人均广告触发') {
        //报表种类
        this.ahyDisabled = true;
        this.apcDisabled = false;
        this.reportType = '按批次';

        //分组维度
        this.setGroupDisabled();
        this.quotaInputDisabled = false;
      } else {
        //报表种类
        this.ahyDisabled = false;
        this.apcDisabled = false;

        //分组维度
        this.ahyDisabled = false;
        this.sjmDisabled = false;
        this.zdycsDisabled = false;
        this.sjrsDisabled = false;
        this.sjcsDisabled = false;
        this.sjsjDisabled = false;
        this.quotaInputDisabled = true;
        this.groupByList = ['日期'];
      }
    },
    //重复使用项--设置【分组维度】中的部分禁用 及 默认值
    setGroupDisabled(e) {
      this.sjmDisabled = true;
      this.zdycsDisabled = true;
      this.sjrsDisabled = true;
      this.sjcsDisabled = true;
      this.sjsjDisabled = true;
      this.quotaInputDisabled = true;
      this.quotaInput = '';
      this.groupByList = ['日期'];
    },

    //AF打点切换时禁用【具体/模糊】的输入框
    searchTypeChange() {
      if (this.searchType == '具体搜索') {
        this.detailDisabled = false;
        this.fuzzyDisabled = true;

        this.fuzzyInput = '';
        this.showAfCode = '';
        this.saveAfFuzzyList = [];
      } else {
        this.detailDisabled = true;
        this.fuzzyDisabled = false;

        this.detailInput = '';
        this.showAfCode = '';
        this.saveAfDetailList = [];
      }
    },

    detailEnter() {
      if (this.detailInput != '') {
        this.saveAfDetailList.push(this.detailInput).then;
        this.detailInput = '';
        // console.log('add:', this.saveAfDetailList);
        // this.afCodeEqualShow();
      }
    },
    // afCodeEqualShow() {
    //   for (var i in this.saveAfDetailList) {
    //     if (this.showAfCode.indexOf(this.saveAfDetailList[i]) != -1) {
    //     } else {
    //       this.showAfCode += '=' + '  ' + this.saveAfDetailList[i] + '\n';
    //       console.log('this.showAfCode1:', this.showAfCode);
    //     }
    //   }
    // },

    fuzzyEnter() {
      if (this.fuzzyInput != '') {
        this.saveAfFuzzyList.push(this.fuzzyInput).then;
        this.fuzzyInput = '';
        // console.log('add:', this.saveAfFuzzyList);
        // this.afCodeLikeShow();
      }
    },
    // afCodeLikeShow() {
    //   for (var i in this.saveAfFuzzyList) {
    //     if (this.showAfCode.indexOf(this.saveAfFuzzyList[i]) != -1) {
    //     } else {
    //       this.showAfCode += 'Like' + '  ' + this.saveAfFuzzyList[i] + '\n';
    //       console.log('this.showAfCode2:', this.showAfCode);
    //     }
    //   }
    // },
    isForever() {
      if (this.forever == true) {
        // this.lastDateTime = this.date;
        this.lastDateTime2 = this.value2;
        this.datePickerDis = true;
        this.changeTime();
      } else {
        // this.mounted();
        // this.date = this.lastDateTime;
        this.datePickerDis = false;
        this.value2 = this.lastDateTime2;
        // this.$refs.datePicker.longtime(this.date);
      }
    },
    changeTime() {
      // const inputTime = ['1999-1-1', '2099-1-1'];
      const inputTime2 = ['1999-01-01 00:00:00', '2099-01-01 23:59:59'];
      // this.date = inputTime;
      this.value2 = inputTime2;
      // this.$refs.datePicker.longtime(inputTime);
    },

    detailChange() {},
    fuzzyChange() {},

    ProjectChange(e) {
      console.log('Project:', this.Project);
      //   this.getMedia();
      //切换项目时清空【媒体渠道-版本-国家】选择
      this.appVersion = [];
      this.$refs.childMedia.select_value = [];
      this.$refs.childCountry.select_country = [];

      this.getCountry();
      this.getMedia();
      this.getAppVersion();
      this.getEvent();
    },
    PlatformChange(e) {
      // 改变值，请求国家选择框的值
      //   this.getCountry();
      //   this.getMedia();
      //   this.getMedia();
      //   this.getCountry();
    },
    MediaChange(e) {
      // 改变值，请求国家选择框的值
      //   this.getCampaign();
    },
    appVersionChange(e) {
      //   this.getCampaign();
    },
    CountryChange(e) {
      // 当改变值的时候，请求下个选项框的值，如果此选择框没有联动的下个选项框就为空就可以
      // console.log('接受');
      // console.log(e);
      this.getMedia();
    },
    EventChange(e) {
      // console.log('接受');
      // console.log(e);
    },
    CampaignChange(e) {
      // 当改变值的时候，请求下个选项框的值，如果此选择框没有联动的下个选项框就为空就可以
      // console.log('接受');
      // console.log(e);
    },
    AdFormatChange(e) {
      //
    },
    dateTypeChange(e) {
      // console.log(e);
      if (e === 'event_time') {
        this.reDisabled = true;
        let temp = this.dataList;
        for (let i = 0; i < temp.length; i++) {
          if (temp[i] === '留存') {
            temp.splice(i, 1);
            break;
          }
        }
        this.dataList = temp;
      } else {
        this.reDisabled = false;
      }
    },
    //checkbox-group回调
    dimDemo(val) {
      this.groupByList = val;
    },
    dimData(val) {
      this.dataList = val;
    },
    addItem() {
      // 1。这里为什么改变list的大小就能实现动态增加呢？因为 el-form-item 遍历的是 list,list 中的每一项都是一个 el-form-item
      // 也就是说因为刚开始 list:[{"oneId":''}] 中,只有一个对象,所以才会只出现一个 el-form-item
      // 不信可以自己在初始化时 list 中多加入几个对象进行尝试(一定要理解，这里 list 集合的大小与 el-form-item 之间的关系)
      // 2、第二个问题:el-form-item 是动态增加了,但是如果 el-select 那里写的是 v-model="oneId" 呢？会发生什么？结果你会发现,只要增加一项 el-form-item ,每一项绑定的值都是你所选中的那一个值.为什么呢？因为每一项的 el-option的 :value 值都绑定在 el-select 的 v-model 上,但这是一个全局唯一值,当下一个 el-form-item 产生后,它里面的 el-select 中绑定的 v-model 还是那个 oneId 的值,因此才会出现这样的问题.好了,我们既然找到了原因,那就要来解决一下了,怎么解决呢？很简单:因为我前面说了,每一个 list 的遍历对象,都是一项 el-form-item,即 el-form-item 项数是和 list 的下标(里面存的对象的索引下标)相关联的,而这个下标,在每一个 el-form-item 中肯定是不一样的,因此我们只需要将 oneId 与这个 下标(即此处的 index) 发生关系即可,因此我们这里将 oneId 声明为了一个数组,当你每选中一个 option 时,都将这个 option 的value放入 oneId[当前el-form-item项数下标] 数组中

      this.list.push({ eventId: '', relationId: '', valueId: '' });
    },

    removeItem(it, index) {
      // 删除时,我们带两个参数,这个 it 可用可不用,因为我当时只是想看到删除的这个对象的信息,故而带上了; index 是 list 中该对象对应的下标,也是 el-form-item 的项数
      // 根据这个 index 下标删除 list 中 的该对象
      if (index != 0) {
        this.list.splice(index, 1);
      }
    },
    saveRelationList(event, index) {
      // 当每选一个 option 时,我们需要将这个 选中的 oneId 放入 对应的 list 中即可,最后都选中完后,我们只要获取这个 list,即可拿到所有的数据
      this.list[index].relationId = event;
    },
    saveList(event, index) {
      // 当每选一个 option 时,我们需要将这个 选中的 oneId 放入 对应的 list 中即可,最后都选中完后,我们只要获取这个 list,即可拿到所有的数据
      this.list[index].eventId = event;
    },
    saveValueList(event, index) {
      this.list[index].valueId = event;
    },
    submit() {
      // 这里我们打印一下 最后的 list,确保我们都拿到数据了
      alert(`最终的数据: ${JSON.stringify(this.list)}`);
    },
    //返回按钮
    fanhuiss() {
      this.fanhui = false;
      this.tableData = [];
    },
    // 触发搜索按钮
    userSearch() {
      //post请求获取搜索内容
      this.$axios
        .post(
          'UserCustom/user_data/',
          {
            date_range: this.date,
            project: [this.Project],
            country: this.$refs.childCountry.select_country,
            app_version: this.appVersion,
            media_source: this.$refs.childMedia.select_value,
            campaign: [],
            event_list: this.list
          },
          {
            headers: {
              Authorization: 'JWT ' + localStorage.getItem('jwt-token')
            }
          }
        )
        .then((res) => {
          this.userNum = res.data.user_data;
        })
        .catch((e) => {});
    },

    // 触发搜索按钮
    handleSearch() {
      //项目必选
      if ([this.Project] == '') {
        this.$notify({ title: '提示', message: '请选择项目', type: 'warning' });
      }
      //关卡漏斗必须选3项
      if (this.quota == '关卡漏斗') {
        if (this.groupByList.length < 3) {
          this.$notify({ title: '提示', message: '指标为【关卡漏斗】时，【分组维度】请至少选择三项', type: 'warning' });
        }
      }
      if ([this.Project] != '') {
        //选择/排除国家--默认无选择时排除'CN'
        var countryInList = [];
        var countryOutList = [];

        if (this.countryType == '选择') {
          if (this.$refs.childCountry.select_country != '') {
            countryInList = this.$refs.childCountry.select_country;
          }
        } else {
          if (this.$refs.childCountry.select_country != '') {
            countryOutList = this.$refs.childCountry.select_country;
          }
        }

        //报表类别--【按批次：life  按活跃：active】
        var reportTypeString = '';
        if (this.reportType == '按批次') {
          reportTypeString = 'life';
        } else {
          reportTypeString = 'active';
        }

        if (this.searchType == '具体搜索') {
          this.saveAfDetailList.push(this.detailInput);
        } else {
          this.saveAfFuzzyList.push(this.fuzzyInput);
        }

        this.saveAfDetailList = this.saveAfDetailList.filter((word) => word != '');
        this.saveAfFuzzyList = this.saveAfFuzzyList.filter((word) => word != '');

        // console.log("saveAfCodeList:",this.saveAfCodeList);
        // return;
        // console.log('quota', this.quota);
        // console.log('reportTypeString',reportTypeString);
        // console.log('reportType', this.reportType);
        // console.log('groupByList', this.groupByList);
        // console.log('date', this.date);
        // console.log('Project', this.Project);
        // console.log('countryInList', countryInList);
        // console.log('countryOutList', countryOutList);
        // console.log('Media', this.$refs.childMedia.select_value);
        // console.log('appVersion', this.appVersion);
        // console.log('searchRule', this.searchRule);
        // console.log('detailInput', this.detailInput);
        // console.log('fuzzyInput', this.fuzzyInput);
        // return;

        this.$axios
          .post(
            'UserCustom/info/',
            {
              pixel: this.quota,
              iaa_day: this.quotaInput,
              // data_type: this.reportType,
              date_type: reportTypeString,
              dimension: this.groupByList,
              condition: {
                date_range: this.value2,
                project: this.Project,
                country: {
                  in: countryInList,
                  out: countryOutList
                },
                media: this.$refs.childMedia.select_value,
                app_version: this.appVersion,
                event_name: {
                  type: this.searchRule,
                  equal: this.saveAfDetailList,
                  // like: this.fuzzyInput
                  like: this.saveAfFuzzyList
                }
              }
            },
            {
              headers: {
                Authorization: 'JWT ' + localStorage.getItem('jwt-token')
              }
            }
          )
          .then((res) => {
            this.tableData = res.data.list;
            //table[List]数据
            this.tableSearchList = res.data.list;
            this.pageInfo.total = res.data.list.length;
            // this.totalCount = res.data.list.length;
            this.totalCount = res.data.total;
            this.tableColumns = [];
            for (var pro in res.data.list[0]) {
              this.tableColumns.push(pro);
            }
            this.tableColumns1 = this.tableColumns.splice(0, res.data.fix_columns);
            this.tableColumns2 = this.tableColumns;
            this.$nextTick(() => {
              this.$refs.multipleTable.doLayout();
              // el-table加ref="multipleTable"
            });
            this.pictLoading = false;
            // 搜索需要经过子组件里的检索函数
            // this.$refs.searchInput.searchTable();
            this.detailInput = '';
            this.fuzzyInput = '';
          })
          .catch((e) => {});
      }
    },
    // handleSearch() {
    //   if ([this.Project] == '') {
    //     this.$notify({ title: '提示', message: '请填写项目', type: 'warning' });
    //   }
    //   if (
    //     [this.Project] != ''
    //   ) {
    //     this.fanhui = true;
    //     this.pictLoading = true;
    //     //post请求获取搜索内容
    //     this.$axios
    //       .post(
    //         'UserCustom/info/',
    //         {
    //           date_range: this.date,
    //           project: [this.Project],
    //           country: this.$refs.childCountry.select_country,
    //           app_version: this.appVersion,
    //           media_source: this.$refs.childMedia.select_value,
    //           groupByList:this.groupByList,
    //           event_list:this.list,
    //           event_name: this.$refs.childEvent.select_value,
    //           date_type:this.DateType,
    //           data_list:this.dataList,
    //         },
    //         {
    //           headers: {
    //             Authorization: 'JWT ' + localStorage.getItem('jwt-token')
    //           }
    //         }
    //       )
    //       .then((res) => {

    //         this.tableData = res.data.list;
    //         this.pageInfo.total = res.data.list.length;
    //         // this.totalCount = res.data.list.length;
    //         this.totalCount = res.data.total;
    //         this.tableColumns = [];
    //         for (var pro in res.data.list[0]) {
    //           this.tableColumns.push(pro);
    //         }
    //         this.tableColumns1 = this.tableColumns.splice(0, res.data.fix_columns);
    //         this.tableColumns2 = this.tableColumns;
    //         this.$nextTick(() => {
    //           this.$refs.multipleTable.doLayout();
    //           // el-table加ref="multipleTable"
    //         });
    //         this.pictLoading = false;
    //         // 搜索需要经过子组件里的检索函数
    //         this.$refs.searchInput.searchTable();
    //       })
    //       .catch((e) => {});
    //   }
    // },
    handleSizeChange(val) {
      this.pageInfo.current = 1;
      this.pageInfo.size = val;
    },
    // 检索
    SearchInput(tableList) {
      this.tableSearchList = tableList;
      this.pageInfo.total = tableList.length;
    },
    //导出excel文件
    // handleDownload() {
    //   // this.downloadLoading = true;
    //   // 懒加载该用法
    //   import('@/vendor/Export2Excel').then((excel) => {
    //     // 设置导出表格的头部
    //     const tHeader = ['序号', '订单号', '菜品', '收银员', '金额', '时间'];
    //     // 设置要导出的属性
    //     const filterVal = ['id', 'title', 'foods', 'author', 'pageviews', 'display_time'];
    //     // 获取当前展示的表格数据
    //     const list = this.list;
    //     // 将要导出的数据进行一个过滤
    //     const data = this.formatJson(filterVal, list);
    //     // 调用我们封装好的方法进行导出Excel
    //     excel.export_json_to_excel({
    //       // 导出的头部
    //       header: tHeader,
    //       // 导出的内容
    //       data,
    //       // 导出的文件名称
    //       filename: this.filename,
    //       // 导出的表格宽度是否自动
    //       autoWidth: this.autoWidth,
    //       // 导出文件的后缀类型
    //       bookType: this.bookType
    //     });
    //     this.downloadLoading = false;
    //   });
    // },
    //导出csv文件
    download() {
      //datas：数据，fields：字段名
      try {
        const result = json2csv.parse(this.tableData);
        // console.log(result)
        // return
        // 判断浏览器类型
        if (
          (navigator.userAgent.indexOf('compatible') > -1 && navigator.userAgent.indexOf('MSIE') > -1) ||
          navigator.userAgent.indexOf('Edge') > -1
        ) {
          //IE10或Edge浏览器
          var BOM = '\uFEFF';
          var csvData = new Blob([BOM + result], { type: 'text/csv' });
          navigator.msSaveBlob(csvData, `test.csv`);
        } else {
          //非IE浏览器
          var csvContent = 'data:text/csv;charset=utf-8,\uFEFF' + result;
          //使用a标签的download属性实现下载功能
          var link = document.createElement('a');
          link.href = encodeURI(csvContent);
          link.download = `test.csv`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      } catch (err) {
        alert(err);
      }
    },
    //获取游戏名
    getProject() {
      this.$http
        .get('UserCustom/project_data/', {
          headers: {
            Authorization: 'JWT ' + localStorage.getItem('jwt-token')
          }
        })
        .then(function (res) {
          if (res.data.code == 200) {
            this.projectList = res.data.project_data;
            // this.list1.unshift('all');
          }
        })
        .catch(function (err) {
          console.log(err);
        });
    },
    // 获取国家
    getCountry() {
      this.$axios
        .post(
          'UserCustom/country_data/',
          {
            date_range: this.date,
            project: [this.Project]
          },
          {
            headers: {
              Authorization: 'JWT ' + localStorage.getItem('jwt-token')
            }
          }
        )
        .then((res) => {
          this.$refs.childCountry.country_list = res.data.country_data;
          this.$refs.childCountry.originalList = res.data.country_data;
          // this.countryList = res.data.country_data;
        })
        .catch((e) => {});
    },
    // // 获取版本
    getAppVersion() {
      this.$axios
        .post(
          'UserCustom/app_version_data/',
          {
            date_range: this.date,
            project: [this.Project]
          },
          {
            headers: {
              Authorization: 'JWT ' + localStorage.getItem('jwt-token')
            }
          }
        )
        .then((res) => {
          this.appVersionList = res.data.app_version_data;
        })
        .catch((e) => {});
    },
    // 获取媒体渠道
    getMedia() {
      this.$axios
        .post(
          'UserCustom/media_data/',
          {
            project: [this.Project],
            country: this.$refs.childCountry.select_country
          },
          {
            headers: {
              Authorization: 'JWT ' + localStorage.getItem('jwt-token')
            }
          }
        )
        .then((res) => {
          this.$refs.childMedia.value_list = res.data.media_data;
          this.$refs.childMedia.originalList = res.data.media_data;
        })
        .catch((e) => {});
    },
    // 获取事件
    getEvent() {
      this.$axios
        .post(
          'UserCustom/event_data/',
          {
            date_range: this.date,
            project: [this.Project]
          },
          {
            headers: {
              Authorization: 'JWT ' + localStorage.getItem('jwt-token')
            }
          }
        )
        .then((res) => {
          this.$refs.childEvent.value_list = res.data.event_data;
          this.$refs.childEvent.originalList = res.data.event_data;
        })
        .catch((e) => {});
    },
    // 访问
    PageCount() {
      this.$http
        .post(
          'index/count/',
          {
            page_name: '用户定制'
          },
          {
            headers: {
              Authorization: 'JWT ' + localStorage.getItem('jwt-token')
            }
          }
        )
        .then(function (res) {
          if (res.data.code == 200) {
            // this.$refs.childProject.project_list = res.data.project_data;
            // this.list1.unshift('all');
          }
        })
        .catch(function (err) {
          console.log(err);
        });
    },
    // 获取之前的日期的函数
    getBeforeDate(n) {
      let _n = n;
      let _d = new Date();
      let _year = _d.getFullYear();
      let _mon = _d.getMonth() + 1;
      let _day = _d.getDate();
      if (_day <= _n) {
        if (_mon > 1) {
          _mon = _mon - 1;
        } else {
          _year = _year - 1;
          _mon = 12;
        }
      }
      _d.setDate(_d.getDate() - n);
      _year = _d.getFullYear();
      _mon = _d.getMonth() + 1;
      _day = _d.getDate();
      var date_sub = _year + '-' + (_mon < 10 ? '0' + _mon : _mon) + '-' + (_day < 10 ? '0' + _day : _day);
      return date_sub;
    }
  },

  mounted() {
    let timeStamp = new Date();
    let year = new Date(timeStamp).getFullYear();
    let month = new Date(timeStamp).getMonth() + 1 < 10 ? '0' + (new Date(timeStamp).getMonth() + 1) : new Date(timeStamp).getMonth() + 1;
    let month1 = new Date(timeStamp).getMonth() < 10 ? '0' + new Date(timeStamp).getMonth() : new Date(timeStamp).getMonth();
    let date = new Date(timeStamp).getDate() < 10 ? '0' + new Date(timeStamp).getDate() : new Date(timeStamp).getDate();
    // 初始日期间隔
    let date_sub = this.getBeforeDate(7);
    this.date = [date_sub, year + '-' + month + '-' + date];
  }
};
</script>
<style>
.el-tag .el-icon-close {
  float: right;
  margin-top: 4px;
}
.el-tag {
  background: white;
  width: 100%;
}
.el-table .cell,
.el-table th > .cell {
  white-space: nowrap;
  width: fit-content;
}
.el-select__tags {
  flex-wrap: nowrap;
  overflow: hidden;
}
.el-tag.el-tag--info .el-tag__close {
  display: none;
}
.el-tag.el-tag--info {
  border: none;
  background: white;
}
.el-select__tags-text {
  margin-left: 0px;
}
.el-date-editor--daterange.el-input__inner {
  width: 240px;
}
.el-picker-panel {
  margin-left: 240px;
}
.af-point-class {
  margin-left: 78px;
  /* border-style: solid; */
  /* border-width: 0.5px; */
  margin-top: -10px;
}
</style>
<style scoped>
.table {
  width: 100%;
  font-size: 14px;
}
</style>